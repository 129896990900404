import React from 'react';
import Typography from "@material-ui/core/Typography";
import HeaderLinks from "./Menu";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { Link } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white!important"
    //backgroundColor: "#a5e3ff!important"
  },
  footRoot: {
    width: "100%",
    top: "auto",
    bottom: "-1px",
    backgroundColor: "#580b18!important"
  },
  title: {
    flexGrow: 1
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 10,
  },
  fabIcon: {
    color: "whitesmoke",
    backgroundColor: "crimson",
    "&:hover": {
      backgroundColor: "#00f2ff99",
      color: "grey",
    }
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      height: 100
    },
    [theme.breakpoints.up("md")]: {
      height: 125
    },
    paddingTop: 10
  },
  offset: {
    border: "0px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 95,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 120,
    },
  },
}));

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function ScrollTop(props) {
  const { children } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 200
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.fab}>
        {children}
      </div>
    </Zoom>
  );
}

export default function ButtonAppBar(props) {
  const classes = useStyles();

  return (
    <div>
      <HideOnScroll {...props}>
        <AppBar position="fixed" className={classes.root} id="appHeader">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              <Link to={`${process.env.PUBLIC_URL}/`}>
              <img src={`${process.env.PUBLIC_URL}/assets/home-finder-logo.png`} alt="" className={classes.logo} />
              </Link>
            </Typography>
            <HeaderLinks hasLove={props.love} />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <hr className={classes.offset} />
      <ScrollTop {...props}>
        <Fab size="small" aria-label="Scroll to top!" className={classes.fabIcon}>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  );
}
