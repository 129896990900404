import React, { useState, useEffect, useCallback } from "react";
import Postman from "axios";
import { Listings } from "../UI/Listings";
import { ip, devMode } from "../bin/dev";
import InfiniteScroll from "react-infinite-scroller";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  loader: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  nasa: {
    //because I need my space.
    paddingTop: 16,
  },
  loveless: {
    padding: 15,
  },
}));

//export default (props) => {
function _ListingsByRef(props) {
  const classes = useStyles();

  const defaultState = {
    criteria: {},
    data: [],
    updating: true,
    offset: 0,
    dataCount: 0,
    hasMore: true,
    lastSearchString: "",
  };

  const [state, setState] = useState(defaultState);

  const loadListings = useCallback(
    (
      criteria = props.criteria,
      searchString = props?.searchString,
      incrementBy = 10
    ) => {
      let notSearching =
        typeof searchString === "undefined" || searchString === "";
      // eslint-disable-next-line
      let isSameSearch = searchString == state.lastSearchString; //Deliberate type juggling
      //console.log(isSameSearch);

      //Return listings by listing agent
      let authToken = sessionStorage["authToken"]
        ? sessionStorage["authToken"]
        : null;

      let userCode = !devMode.isDevMode
        ? criteria?.userCode
        : devMode.devUserCode;
      let url = `${ip}/listings?q=`;
      let action = criteria.action ? criteria.action : "search";
      let auth = authToken ? `&auth=${authToken}` : authToken;
      let user = !!userCode ? `&user=${userCode}` : "";
      let postURL = `${url}${action}${user}${auth}`;
      let request = {
        criteria,
        offset: isSameSearch ? state.offset + incrementBy : 0,
      };

      if (criteria?.action === "hearts" || props?.display === "agent" || props?.display === "agency") {
        postURL = `${url}${action}`;
      }
      Postman.post(`${postURL}`, request).then((response) => {
        let dataCount = response.data.length > 0 ? response.data[0].COUNT : 0;
        setState({
          ...state,
          data:
            isSameSearch
              ? [...state.data, ...response.data]
              : [...response.data],
          hasMore: request.offset + incrementBy <= dataCount,
          updating: false,
          dataCount,
          lastSearchString: !notSearching ? searchString : null,
        });
        props.reportCount(dataCount);
      });
    },
    // eslint-disable-next-line
    [state.offset, props?.searchString]
  );

  const loadMoreListings = () => {
    if (!state.updating && state.hasMore) {
      setState({
        ...state,
        offset: state.offset + 10,
        updating: true,
      });
    }
  };

  useEffect(() => {
    loadListings();
  }, [loadListings]);

  // useEffect(() => {
  //   if (state.dataCount > 0) {
  //     if (props.reportCount) props.reportCount(state.dataCount);
  //   }
  //   // eslint-disable-next-line
  // }, [state.dataCount]);

  if (state.data) {
    if (state.count) {
      //Busy counting, no data yet.
      return null;
    }
    if (state.data.length > 0) {
      return (
        <div className={classes.nasa}>
          <InfiniteScroll
            threshold={250}
            initialLoad={false}
            pageStart={0}
            loadMore={loadMoreListings}
            hasMore={state.hasMore}
            loader={
              <div className={classes.loader} key={0}>
                <div className="stage">
                  <div className="dot-floating"></div>
                </div>
              </div>
            }
          >
            <Listings
              display={props.display}
              showSimilar={false}
              listings={state.data}
              makeLove={props.makeLove}
              canClick={true}
              removeOnClick={props?.removeOnClick ? props.removeOnClick : false}
              setSelected={props.setSelected}
              selected={props.selected}
            />
          </InfiniteScroll>
          <Typography
            id="loveless"
            className={classes.loveless}
            variant="body1"
            color="initial"
          ></Typography>
        </div>
      );
    }
  }
  return null;
}
export const ListingsByRef = React.memo(_ListingsByRef);