import React, { useState, useEffect } from "react";
import Postman from "axios";
import { ip } from "../bin/dev";
import "../bin/common";
import "react-infinite-calendar/styles.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  addDays,
  isFriday,
  format,
  isSameDay,
  isPast,
  isFuture,
  differenceInDays,
  //isToday,
} from "date-fns";
import { cyan, blue, grey, red, green, orange } from "@material-ui/core/colors";
//import InputLabel from "@material-ui/core/InputLabel";
//import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
//import Select from "@material-ui/core/Select";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    textAlign: "center",
    margin: "0 auto",
    alignItems: "center",
  },
  formControl: {
    width: "100%",
    textAlign: "left",
  },
  textControl: {
    width: 300,
    margin: "0 auto",
  },
  gridHeading: {
    fontWeight: "bold",
  },
  calendar: {
    margin: "0 auto",
  },
  luckyMessageHeading: {
    fontSize: "small",
    color: "crimson",
  },
  attentionHeading: {
    fontSize: "large",
    color: "crimson",
    marginTop: 15,
    marginBottom: 15,
  },
  luckyMessage: {
    fontSize: "smaller",
    color: "crimson",
  },
  removeTop: {
    paddingTop: 0,
    marginTop: 0,
  },
  removeBottom: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  addPaddingTop: {
    paddingTop: "10px",
  },
  textLarger: {
    fontSize: "1.3rem",
  },
}));

function _Booking(props) {
  const classes = useStyles();
  const products = [
    {
      name: "Super Hero",
      code: "super",
      description: "Select a SUPER HERO to feature on home page.",
      price: 450,
      discounted: 225,
      color: cyan,
      location: "EVERYWHERE",
    },
    {
      name: "Hero",
      code: "hero",
      description: "Select a HERO to feature in the town where listed.",
      price: 250,
      discounted: 125,
      color: blue,
      location: props.location,
    },
  ];
  const duration = [
    {
      item: "7 days",
      weeks: 1,
      factor: 1,
      description:
        "Ads rotate on the first Friday after being displayed for 7 days",
    },
    {
      item: "Premium 14 day slot",
      weeks: 2,
      factor: 2.25,
      description:
        "Ads rotate on the second Friday after being displayed for 14 days.",
    },
  ];
  const [booking, setBooking] = useState({
    selectedSlot: {
      start: new Date(format(new Date(), "yyyy-MM-dd")),
      end: new Date(format(new Date(), "yyyy-MM-dd")),
    },
    product: -1,
    duration: 1,
    bookedSlot: [],
    occupiedSlots: [],
    location: "EVERYWHERE",
    open: false,
    confirm: false,
  });

  const getFirstAvailable = (
    start,
    occupiedSlots = [],
    location = booking?.location,
    selected = new Date(format(start, "yyyy-MM-dd"))
  ) => {
    if (location !== booking?.location) {
      start = new Date(format(new Date(), "yyyy-MM-dd"));
      selected = start;
    }

    if (isSameDay(selected, new Date()) && isFriday(selected)) {
      start = addDays(selected, 7);
      selected = start;
    } else {
      start = new Date(format(start, "yyyy-MM-dd"));
    }

    if (isFriday(selected)) {
      start = selected;
    } else {
      while (!isFriday(start)) {
        start = addDays(start, 1);
      }
    }

    if (occupiedSlots.length > 0) {
      let slots = occupiedSlots;
      slots.forEach((slot) => {
        if (isSameDay(slot.start, start) && slot.location === location) {
          start = addDays(slot.start, 7);
        }
      });
    }
    return start;
  };
  const isApprovable = (occupiedSlots, pendingSlot) => {
    for (const slot of occupiedSlots) {
      if (isSameDay(slot.start, pendingSlot.start)) {
        return false;
      }
    }
    return true;
  };

  const openUI = () => {
    OccupiedSlots().then((slots) => {
      let start = getFirstAvailable(booking?.selectedSlot.start, slots);
      let end = addDays(start, 7 * booking?.duration);
      let selectedSlot = {
        start,
        end,
      };
      hasEmptySlots(products[booking?.product]?.code).then((hasEmpties) => {
        //let approvable = isApprovable(slots, { start: new Date(2021, 4, 14) });
        let approvable = isApprovable(slots, booking?.bookedSlot);
        setBooking({
          ...booking,
          //product: hasEmpties ? 1 : 0,
          selectedSlot: isSameDay(
            booking?.selectedSlot.start,
            booking?.selectedSlot.end
          )
            ? selectedSlot
            : { ...booking?.selectedSlot },
          bookedSlot: [...booking?.bookedSlot],
          occupiedSlots: [...slots],
          open: !booking.open,
          hasEmpties,
          approvable,
        });
      });
    });
  };

  const durationBooked = (bookedSlot) => {
    let slotsBooked =
      differenceInDays(bookedSlot[0]?.end, bookedSlot[0]?.start) / 7;
    if (slotsBooked) return slotsBooked;
    return 1;
  };

  async function OccupiedSlots() {
    let url = `${ip}/dash?action=occupied`;
    let post = {
      action: "occupied",
    };
    return Postman.post(url, post).then((res) => {
      let occupiedSlots = [];
      res.data.forEach((slot) => {
        occupiedSlots.push({
          start: new Date(slot.FEATURE_START),
          location: slot.FEATURE_LOCATION,
          color: grey[500],
        });
      });
      return occupiedSlots;
    });
  }

  async function hasEmptySlots(hero_type) {
    return Postman.post(
      `${ip}/listings?q=hero&p=count`,
      hero_type === "super" ? { town: [] } : { town: [props.location] }
    ).then((res) => {
      if (res.data[0].COUNT > 2) {
        return false;
      }
      return true;
    });
  }

  async function fetchBookedSlots() {
    let url = `${ip}/dash?action=booked`;
    let post = { listing_id: props.listingID, action: "booked" };
    let bookedSlot = [];
    let output = {};
    return Postman.post(url, post)
      .then((res) => {
        res.data.forEach((slot) => {
          bookedSlot.push({
            start: new Date(slot.FEATURE_START),
            end: new Date(slot.FEATURE_STOP),
            code: slot.FEATURE_TYPE,
            status: slot.AUTH_STATUS,
            row_id: slot.ROWID,
          });
        });
        output.bookedSlot = bookedSlot;
        return output;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const refreshBooking = (slots) => {
    let product = products.findIndex(
      (el) => el.code === slots.bookedSlot[0]?.code
    );
    if (product === -1) {
      product = booking.product;
    }
    if (isSameDay(booking?.selectedSlot.start, booking?.selectedSlot.end)) {
      getFirstAvailable(booking?.selectedSlot.start, booking?.occupiedSlots);
    }
    setBooking({
      ...booking,
      selectedSlot:
        slots.bookedSlot.length > 0
          ? { ...slots.bookedSlot[0] }
          : isSameDay(booking?.selectedSlot.start, booking?.selectedSlot.end)
          ? {
              ...booking?.selectedSlot,
              start: getFirstAvailable(
                booking?.selectedSlot.start,
                booking?.occupiedSlots
              ),
              end: getFirstAvailable(
                booking?.selectedSlot.end,
                booking?.occupiedSlots
              ),
            }
          : { ...booking?.selectedSlot },
      bookedSlot: [...slots.bookedSlot],
      occupiedSlots: [...booking?.occupiedSlots],
      product,
      duration: durationBooked(slots.bookedSlot),
      confirm: false,
      auth_status: slots.bookedSlot[0]?.status,
    });
  };

  const fetchRefresh = () => {
    fetchBookedSlots().then((slots) => {
      refreshBooking(slots);
    });
  };

  useEffect(() => {
    let mounted = true;
    fetchBookedSlots().then((slots) => {
      if (mounted) {
        refreshBooking(slots);
      }
    });
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [props.listingID]);

  const isOccupied = () => {
    let isOccupied = false;
    if (booking?.occupiedSlots.length > 0) {
      let slots = booking?.occupiedSlots;
      slots.forEach((slot) => {
        slot.start === booking?.selectedSlot.start &&
        slot.location === booking?.location
          ? (isOccupied = true)
          : (isOccupied = false);
      });
    }
    return isOccupied;
  };

  const factor = () => {
    if (booking?.duration > 1) return duration[booking?.duration - 1]?.factor;
    return 1;
  };

  const handleHeroSelect = (e) => {
    let product = parseInt(e.target.value);
    let changingTo = products[product].location;
    let start = getFirstAvailable(
      new Date(),
      booking?.occupiedSlots,
      changingTo
    );
    let end = addDays(start, 7 * booking?.duration);
    let selectedSlot = { ...booking?.selectedSlot, start, end };

    hasEmptySlots(products[product].code).then((hasEmpties) => {
      setBooking({
        ...booking,
        selectedSlot: isOccupied
          ? { ...selectedSlot }
          : { ...booking?.selectedSlot },
        product,
        bookedSlot: [...booking?.bookedSlot],
        occupiedSlots: [...booking?.occupiedSlots],
        hasEmpties,
      });
    });
  };

  // const handleDurationSelect = (e) => {
  //   let days = 7 * e.target.value;
  //   let start = isToday(booking?.selectedSlot.start)
  //     ? getFirstAvailable(new Date(), booking?.occupiedSlots)
  //     : booking?.selectedSlot.start;
  //   let end = addDays(start, days);
  //   setBooking({
  //     ...booking,
  //     selectedSlot: [{ start, end }],
  //     duration: e.target.value,
  //     bookedSlot: [...booking?.bookedSlot],
  //     occupiedSlots: [...booking?.occupiedSlots],
  //   });
  // };

  const handleConfirmClose = () => {
    setBooking({
      ...booking,
      selectedSlot: { ...booking?.selectedSlot },
      bookedSlot: [...booking?.bookedSlot],
      occupiedSlots: [...booking?.occupiedSlots],
      confirm: false,
    });
  };

  const handleConfirmOpen = () => {
    setBooking({
      ...booking,
      selectedSlot: { ...booking?.selectedSlot },
      bookedSlot: [...booking?.bookedSlot],
      occupiedSlots: [...booking?.occupiedSlots],
      confirm: true,
    });
  };

  const saveBooking = () => {
    let url = `${ip}/dash?action=book`;
    let post = {
      listing_id: props.listingID,
      action: "book",
      feature_type: products[booking?.product].code,
      feature_start: format(booking?.selectedSlot.start, "dd/MM/yyyy"),
      feature_stop: format(booking?.selectedSlot.end, "dd/MM/yyyy"),
      ordered_by: sessionStorage["userCode"]
        ? sessionStorage["userCode"]
        : sessionStorage["agencyCode"],
      feature_location:
        products[booking?.product].code === "super"
          ? "everywhere"
          : props.location,
      auth_status: booking?.auth_status
        ? booking?.auth_status
        : sessionStorage["userCode"]
        ? "pending"
        : "approved",
    };
    Postman.post(url, post)
      .then((res) => {
        if (res.data.rowsAffected === 1) {
          fetchRefresh();
        } else throw Error("Could not save your booking?.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeBooking = () => {
    let url = `${ip}/dash?action=change`;
    let post = {
      action: "change",
      feature_type: products[booking?.product].code,
      feature_start: format(booking?.selectedSlot.start, "dd/MM/yyyy HH:mm:ss"),
      feature_stop: format(booking?.selectedSlot.end, "dd/MM/yyyy HH:mm:ss"),
      feature_location:
        products[booking?.product].code === "super"
          ? "everywhere"
          : props.location,
      auth_status: booking?.auth_status
        ? booking?.auth_status === "decline"
          ? "declined"
          : booking?.auth_status === "cancel"
          ? "cancelled"
          : sessionStorage["userCode"]
          ? "pending"
          : "approved"
        : booking?.auth_status,
      row_id: booking?.bookedSlot[0].row_id,
      listing_id: props.listingID,
    };
    //console.log(booking?.auth_status)
    Postman.post(url, post)
      .then((res) => {
        if (res.data.rowsAffected === 1) {
          fetchRefresh();
        } else throw Error("Could not save your booking?.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleApproval = (e) => {
    setBooking({
      ...booking,
      auth_status: e.target.value,
    });
  };

  const hasBooking = () => {
    return isFuture(booking?.bookedSlot[0]?.start);
  };

  const isPending = () => {
    return booking?.bookedSlot[0]?.status === "pending";
  };

  const hasActiveBooking = () => {
    return (
      isPast(booking?.bookedSlot[0]?.start) &&
      booking?.bookedSlot[0]?.status === "approved"
    );
  };

  const isBookingChange = () => {
    if (booking?.bookedSlot[0]?.status.includes(booking?.auth_status)) {
      return true;
    }
    if (booking?.auth_status) {
      return !booking?.auth_status;
    } else
      return (
        isSameDay(booking?.selectedSlot.start, booking?.selectedSlot.end) ||
        (booking?.bookedSlot[0]?.start &&
          isSameDay(
            booking?.selectedSlot.start,
            booking?.bookedSlot[0].start
          ) &&
          booking?.bookedSlot[0]?.end &&
          isSameDay(booking?.selectedSlot.end, booking?.bookedSlot[0].end) &&
          products[booking?.product].code === booking?.bookedSlot[0].code)
      );
  };

  const bookButtonText = () => {
    if (isPending()) return `${products[booking?.product]?.name} Pending`;
    if (hasActiveBooking()) return `${products[booking?.product]?.name} Active`;
    if (hasBooking()) return `${products[booking?.product]?.name} Booked`;
    return "BOOK SLOT";
  };

  const bookButtonColor = () => {
    if (isPending()) return orange;
    if (hasActiveBooking()) return red;
    if (hasBooking()) return green;
    return blue;
  };

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(bookButtonColor()[500]),
      backgroundColor: bookButtonColor()[500],
      "&:hover": {
        backgroundColor: bookButtonColor()[700],
      },
    },
  }))(Button);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <ColorButton
        variant="contained"
        color={"primary"}
        onClick={() => {
          openUI();
        }}
      >
        {bookButtonText()}
      </ColorButton>
      <Dialog
        className={classes.modal}
        open={booking?.open}
        fullScreen={fullScreen}
        disableBackdropClick={true}
        aria-labelledby=""
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {(!hasActiveBooking() && ( //Check if book range in progress...
          <>
            <DialogContent>
              <DialogContentText className={classes.textLarger}>
                {props.fullAddress}
              </DialogContentText>

              {!!!booking?.auth_status && (
                <DialogContentText>
                  {(booking.product > -1 && "Next available slot:") ||
                    "You have a choice:"}
                </DialogContentText>
              )}

              <DialogTitle
                id="dialogHeading"
                className={classes.removeTop + " " + classes.removeBottom}
                style={{
                  color:
                    booking?.product > -1
                      ? products[booking?.product].color[900]
                      : red[900],
                }}
              >
                {" "}
                {(booking.product > -1 &&
                  `${format(
                    booking?.selectedSlot.start,
                    "EEEE, dd MMMM"
                  )} - ${format(
                    booking?.selectedSlot.end,
                    "EEEE, dd MMMM"
                  )}`) ||
                  "Please make a selection below."}
              </DialogTitle>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    booking.product > -1
                      ? !hasBooking()
                        ? `Book a ${products[booking?.product]?.name} for ${(
                            products[booking?.product]?.price * factor()
                          )
                            .toString()
                            .wasNow()}`
                        : isBookingChange()
                        ? `You've booked a ${
                            products[booking?.product]?.name
                          } for ${(
                            products[booking?.product]?.discounted * factor()
                          )
                            .toString()
                            .toZARaddVAT()}`
                        : `Changing to ${
                            products[booking?.product]?.name
                          } for ${(products[booking?.product]?.price * factor())
                            .toString()
                            .wasNow()}`
                      : "",
                }}
              ></div>

              {booking.product > -1 && <br />}
              {booking.product > -1 &&
                `for ${duration[booking?.duration - 1]?.item}`}
              {!!!booking?.auth_status && (
                <>
                  <FormControl
                    className={classes.formControl + " " + classes.modal}
                    fullWidth={false}
                  >
                    <RadioGroup
                      row
                      id="hero-select-helper"
                      value={booking?.product}
                      onChange={handleHeroSelect}
                      className={classes.addPaddingTop}
                    >
                      {products.map((el, i) => {
                        return (
                          <FormControlLabel
                            key={i}
                            value={i}
                            control={
                              <Radio
                                color={i === 0 ? "secondary" : "primary"}
                              />
                            }
                            label={el?.name}
                            labelPlacement="bottom"
                          />
                        );
                      })}
                    </RadioGroup>
                    <FormHelperText>
                      {booking.product > -1 &&
                        `${products[booking?.product]?.description}`}
                    </FormHelperText>
                  </FormControl>
                  <FormControl
                    className={classes.formControl}
                    fullWidth={false}
                  >
                    {/* <Select
                      labelId="duration-select-helper-label"
                      id="duration-select-helper"
                      value={booking?.duration}
                      onChange={handleDurationSelect}
                    >
                      {duration.map((el, i) => {
                        return (
                          <MenuItem key={i} value={el.weeks}>
                            {el.item}
                          </MenuItem>
                        );
                      })}
                    </Select> */}
                    <FormHelperText className={classes.modal}>
                      {booking.product > -1 &&
                        `${duration[booking?.duration - 1]?.description}`}
                    </FormHelperText>
                  </FormControl>
                  {booking?.hasEmpties && booking.product > -1 && (
                    <>
                      <Typography
                        variant="h6"
                        color="initial"
                        className={
                          classes.textControl +
                          " " +
                          classes.luckyMessageHeading +
                          " " +
                          classes.addPaddingTop
                        }
                      >
                        You are in luck!
                      </Typography>
                      <Typography
                        variant="body1"
                        color="initial"
                        className={
                          classes.textControl + " " + classes.luckyMessage
                        }
                      >
                        {`A ${
                          products[booking?.product]?.name
                        } slot is available right
                    now. As a bonus, your ad will start running immediately.`}
                      </Typography>
                    </>
                  )}
                </>
              )}
              {booking?.bookedSlot[0]?.status === "pending" &&
                !sessionStorage["userCode"] && (
                  <>
                    {!booking?.approvable || (
                        <>
                          <FormLabel
                            component="legend"
                            className={classes.attentionHeading}
                          >
                            YOUR ATTENTION IS REQUIRED
                          </FormLabel>
                          <RadioGroup
                            aria-label="approval"
                            name="approval1"
                            value={
                              booking?.auth_status ? booking?.auth_status : null
                            }
                            onChange={handleApproval}
                          >
                            <FormControlLabel
                              value="approve"
                              control={<Radio />}
                              label="Approve"
                            />
                            <FormControlLabel
                              value="decline"
                              control={<Radio />}
                              label="Decline"
                            />
                            <FormControlLabel
                              value="cancel"
                              control={<Radio />}
                              label="Cancel Booking"
                            />
                          </RadioGroup>
                        </>
                      ) || (
                        <>
                          <hr />
                          <FormLabel
                            component="legend"
                            className={classes.luckyMessageHeading}
                          >
                            SHIP HAS SAILED
                          </FormLabel>
                          <hr />
                        </>
                      )}
                  </>
                )}
            </DialogContent>
          </>
        )) || (
          <DialogContent>
            <DialogTitle id="dialogHeading">
              {`Your ${products[booking?.product]?.name} listing is active.`}
              <br />
            </DialogTitle>
            <DialogContentText>{/* {props.fullAddress} */}</DialogContentText>
            <DialogContentText>
              {`Booking for this listing is suspended while your ${
                products[booking?.product]?.name
              } is out saving buyers from falling in love with other homes.`}
            </DialogContentText>
            <DialogContentText>
              {`Please return on ${format(
                addDays(booking?.selectedSlot.end, 0),
                "EEEE, dd MMMM"
              )} if you wish to book another slot.`}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={() => {
              openUI();
            }}
            color="secondary"
          >
            Close
          </Button>
          <Button
            onClick={booking?.auth_status ? changeBooking : handleConfirmOpen}
            color="primary"
            variant={"contained"}
            disabled={booking.product === -1 || isBookingChange()}
          >
            {booking?.bookedSlot.length > 0
              ? booking?.auth_status
                ? booking?.bookedSlot[0].status.includes(booking?.auth_status)
                  ? booking?.bookedSlot[0].status
                  : booking?.auth_status
                : "Change"
              : "Book"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={booking?.confirm}
      >
        <DialogTitle
          id="confirmation-dialog-title"
          style={{ textTransform: "uppercase" }}
        >
          Please confirm your booking
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`You are about to book a "${products[booking?.product]?.name}" 
            advertising slot, running from ${format(
              booking?.selectedSlot.start,
              "EEEE, dd MMMM"
            )}
            to ${format(booking?.selectedSlot.end, "EEEE, dd MMMM")}.`}
          </DialogContentText>
          <DialogContentText>Booking details:</DialogContentText>
          <Grid container>
            <Grid item xs={6}>
              <DialogContentText className={classes.gridHeading}>
                PRODUCT
              </DialogContentText>
            </Grid>
            <Grid item xs={6}>
              <DialogContentText>
                {`${products[booking?.product]?.name}`}
              </DialogContentText>
            </Grid>
            <Grid item xs={6}>
              <DialogContentText className={classes.gridHeading}>
                DURATION
              </DialogContentText>
            </Grid>
            <Grid item xs={6}>
              <DialogContentText>
                {`${duration[booking?.duration - 1]?.item}.`}
              </DialogContentText>
            </Grid>
            <Grid item xs={6}>
              <DialogContentText className={classes.gridHeading}>
                STARTS
              </DialogContentText>
            </Grid>
            <Grid item xs={6}>
              <DialogContentText>
                {`${format(booking?.selectedSlot.start, "EEEE, dd MMMM")}`}
              </DialogContentText>
            </Grid>
            <Grid item xs={6}>
              <DialogContentText className={classes.gridHeading}>
                ENDS
              </DialogContentText>
            </Grid>
            <Grid item xs={6}>
              <DialogContentText>
                {`${format(booking?.selectedSlot.end, "EEEE, dd MMMM")}`}
              </DialogContentText>
            </Grid>
            <Grid item xs={6}>
              <DialogContentText className={classes.gridHeading}>
                COST
              </DialogContentText>
            </Grid>
            <Grid item xs={6}>
              <DialogContentText>
                {`${(products[booking?.product]?.discounted * factor())
                  .toString()
                  .toZARaddVAT()}`}
              </DialogContentText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleConfirmClose} color="primary">
            Not sure
          </Button>
          <Button
            onClick={
              booking?.bookedSlot.length > 0 ? changeBooking : saveBooking
            }
            variant={"contained"}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export const Booking = React.memo(_Booking);
