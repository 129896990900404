import { FormControlLabel, Switch } from "@material-ui/core";
import Postman from "axios";
import React, { useState, useEffect } from "react";
import { ip } from "../bin/dev";
import { makeStyles } from "@material-ui/core/styles";

export default (props) => {
  const [state, setState] = useState({ advertise: props.advertise });

  useEffect(() => {
    setState({ advertise: props.advertise });
  }, [props.advertise]);

  const useStyles = makeStyles((theme) => ({
    gray: {
      color: "gray",
      marginLeft: 16,
    },
  }));
  const classes = useStyles();
  const handleChange = (event) => {
    let checked = event.target.checked;
    let post = {
      method: "post",
      url: `${ip}/dash?action=advertise`,
      data: { status: checked, listingID: props.listingID },
    };
    Postman(post).then((res) => {
      props.flipToggle(checked);
    });
  };

  return (
    <FormControlLabel
      control={
        <Switch
          id={props.listingID + "-ad"}
          checked={state.advertise === "true" ? true : false}
          onChange={handleChange}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      label={props.advertise === "true" ? "Live" : "Not advertised"}
      className={classes.gray}
    />
  );
};
