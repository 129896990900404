import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import Postman from "axios";
import Spotlight from "../Components/Spotlight";
import { ip } from "../bin/dev";
import { useParams } from "react-router-dom";

//export default (props) => {
function QueSpotlight(props) {
  const [listings, loadListings] = useState();
  let { home } = useParams();
  useEffect(() => {
    let post = {
        listingID: home
      };
    Postman.post(
      `${ip}/listings?q=single`, post
    ).then((result) => {
      loadListings({
        data: result.data,
      });
    });
  }, [home]);

  const PAGE_DATA = () => {
    return listings;
  };

  return [
    listings?.data
      ? listings.data.map((listing, i) => (
          <Spotlight
            key={listing.REF}
            id={listing.REF}
            display={"stage"}
            DATA={listing}
            PAGE_DATA={PAGE_DATA}
            hideThumb={!!props.hideThumb}
            FOUND={listings.data.length}
            showSimilar={true}
            makeLove={props.makeLove}
          />
        ))
      : null,
  ];
}

export const Stage = React.memo(QueSpotlight);
