import React, { useState, useEffect } from "react";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { makeStyles } from "@material-ui/core/styles";
import { Log } from "./Log";

const findLove = (home) => {
  //Make love if it does not exist
  if (!localStorage.getItem("love")) {
    localStorage.setItem("love", "");
  }
  let love = localStorage.getItem("love");
  love = love.split(",");
  let iLove = love.findIndex((el) => el === home);
  return iLove > -1;
};

export default (props) => {
  const RemoveLinkFromAgent = (home) => {
    if (localStorage["linkedHomes"] && localStorage["linkedAgents"]) {
      let homesLinked = localStorage["linkedHomes"].split(",");
      let agentsLinked = localStorage["linkedAgents"].split(",");
      let xIndex = homesLinked.findIndex((x) => x === home);
      if (xIndex > -1) {
        homesLinked.splice(xIndex, 1);
        agentsLinked.splice(xIndex, 1);
        localStorage["linkedHomes"] = homesLinked;
        localStorage["linkedAgents"] = agentsLinked;
      }
    }
  };
  const loveHandler = (home, setLove) => {
    if (props.LinkHomeToAgent) {
      props.LinkHomeToAgent(home, props.altAgent);
    }
    let love = localStorage.getItem("love");
    love = love.split(",");
    let iLove = love.findIndex((el) => el === home);
    if (findLove(home)) {
      love.splice(iLove, 1);
      RemoveLinkFromAgent(home);
    } else {
      love.push(home);
    }
    localStorage.setItem("love", love);
    setLove({ isLoved: findLove(home), newLove: iLove === -1 });
    let lovers = localStorage["love"].split(",").length > 1;
    props.makeLove(lovers);
    if (props.removeOnClick) {
      props.removeOnClick(home);
      //RemoveLinkFromAgent(home);
    }
    if (document.querySelectorAll(".lovable").length === 0) {
      document.querySelector(
        "#loveless"
      ).innerHTML = `It's where the heart used to be.</br>
      Find more homes to love <a href = "${process.env.PUBLIC_URL}/">here</a>`;
    }
  };
  const useStyles = makeStyles((theme) => ({
    default: {
      color: props.single ? "white" : "grey",
      position: props.single ? "unset" : "absolute",
      right: props.single ? "unset" : 8,
      top: props.single ? "unset" : 4,
      zIndex: 9,
    },
    loved: {
      color: "crimson",
      position: props.single ? "unset" : "absolute",
      right: props.single ? "unset" : 8,
      top: props.single ? "unset" : 4,
      zIndex: 9,
    },
  }));
  const classes = useStyles();
  const [love, setLove] = useState();
  useEffect(() => {
    setLove({ isLoved: findLove(props.home), newLove: false });
    return () => {};
  }, [props.home]);

  function logLove() {
    return (
      <Log
        listing_id={props.home}
        conversion_event={"loved"}
        conversion_meta={props.display}
      />
    );
  }

  return (
    <>
      {love?.newLove ? logLove() : false}
      <FavoriteIcon
        id={props.home + "-love"}
        onClick={(event) => {
          event.stopPropagation();
          if (event.target.closest("svg").attributes.style) {
            event.target.closest("svg").attributes.style.value = "";
          }
          loveHandler(props.home, setLove);
        }}
        className={
          love?.isLoved
            ? classes.loved + " hartjies"
            : props.beat
            ? classes.default + " hartjies scale-up-center"
            : classes.default + " hartjies"
        }
      />
    </>
  );
};
