import React from "react";
import "../bin/common";
import { Log } from "./Log";
import Icon from "./Icon";
import Grid from "@material-ui/core/Grid";

export default (props) => {
  const iconList = [
    {
      qty: props.DATA.BEDROOMS,
      icon: "double-bed",
      alt: "Bedrooms",
      icononly: false,
    },
    {
      qty: props.DATA.I_ENSUIT,
      icon: "ensuite-solid",
      alt: "Ensuite bathrooms",
      icononly: false,
    },
    {
      qty: props.DATA.I_BATHS,
      icon: "bath-solid",
      alt: "Bathrooms",
      icononly: false,
    },
    {
      qty: props.DATA.I_LOUNNO,
      icon: "love-seat-solid",
      alt: "Lounge",
      icononly: false,
    },
    {
      qty: props.DATA.I_DIRONO,
      icon: "utensils-solid",
      alt: "Dining room",
      icononly: false,
    },
    {
      qty: props.DATA.I_STDYNO,
      icon: "study-solid",
      alt: "Study",
      icononly: false,
    },
    {
      qty: props.DATA.I_LAUNNO,
      icon: "laundry-solid",
      alt: "Laundry",
      icononly: false,
    },
    {
      qty: props.DATA.I_SCULNO,
      icon: "scullery-solid",
      alt: "Scullery",
      icononly: false,
    },
    {
      qty: props.DATA.I_PANNO,
      icon: "pantry-solid",
      alt: "Pantry",
      icononly: false,
    },
    {
      qty: props.DATA.I_HLBTNO,
      icon: "restroom-solid",
      alt: "Guest loo",
      icononly: false,
    },
    {
      qty: props.DATA.E_SERVNO,
      icon: "staff-solid",
      alt: "Staff quarters",
      icononly: false,
    },
    {
      qty: props.DATA.E_GARANO,
      icon: "garage-open",
      alt: "Garage",
      icononly: false,
    },
    {
      qty: props.DATA.E_CRPRTN,
      icon: "carport-solid",
      alt: "Carport",
      icononly: false,
    },
    {
      qty: props.DATA.E_POOLNO,
      icon: "swimmer-solid",
      alt: "Pool",
      icononly: false,
    },
    {
      qty: props.DATA.G_TOTAL,
      icon: "granny-flat-solid",
      alt: "Granny flat",
      icononly: false,
    },
    {
      qty: props.DATA.T_PETS,
      icon: "paw-solid",
      alt: "Pet friendly",
      icononly: true,
    },
  ];
  const buildImageURL = (sml, img = 0) => {
    try {
      return `https://www.rnslive.co.za/Photos/${
        props.DATA.REF
      }/${props.DATA.PHOTOS[img].replace(/_s/g, `_${sml}`)}`;
    } catch (error) {
      let imgURL = `https://www.rnslive.co.za/Photos/${props.DATA.REF}/${props.DATA.REF}_${sml}(${img}).jpg`;
      return imgURL;
    }
  };

  const LogoURL = () => {
    let logoURL = "https://www.rnslive.co.za/MemberPages/logos/";
    return `${logoURL}${props.id.substr(0, 4)}.png`;
  };

  if (props.DATA) {
    return (
      <div className="spotlight-container">
        <Log
          listing_id={props.id}
          conversion_event={"Display"}
          conversion_meta={props.display}
        />
        <div
          className="spotlight-header"
          style={{ backgroundImage: `url('${buildImageURL("l")}')` }}
        >
          <div
            className="spotlight-overlay"
            style={{ backgroundImage: `url('${buildImageURL("s", 1)}')` }}
          ></div>
          <div
            className="spotlight-overlay"
            style={{ backgroundImage: `url('${buildImageURL("s", 2)}')` }}
          ></div>
          <div
            className="spotlight-overlay"
            style={{ backgroundImage: `url('${buildImageURL("s", 3)}')` }}
          ></div>
          <div className="spotlight-gradient"></div>
        </div>
        <div className="spotlight-price">
          {props.DATA.PRICE.toString().toZAR()}
        </div>
        <img
          src={`https://www.rnslive.co.za/img/Agent_Images/${props.DATA.AGENTID}_w200.jpg`}
          alt=""
          className="spotlight-agent"
        />
        <div className="spotlight-heading trimText">
          {props.DATA.SUBURB.toProperCase()}
          <br />
          <strong>{props.DATA.TOWN.toProperCase()}</strong>
        </div>
        <div className="spotlight-icons">
          <Grid container spacing={2}>
            {iconList.map((icon, i) => {
              return (
                <Icon
                  key={i}
                  icon={icon.icon}
                  alt={icon.alt}
                  qty={icon.qty}
                  icononly={icon.icononly}
                />
              );
            })}
          </Grid>
        </div>
        <div className="spotlight-info">
          <img alt={props.DATA.LISTINGAGENCY} src={LogoURL()} />
          <p className="trimText">
            {props.DATA.HF_TITLE !== null
              ? `"${props.DATA.HF_TITLE}"`
              : props.DATA.BEDROOMS > 0
              ? `${props.DATA.STYLE} ${props.DATA.BEDROOMS} bedroom ${props.DATA.CLASS}`
              : `${props.DATA.STYLE} ${props.DATA.CLASS}`}
          </p>
          <h4>Find it on homefinder.co.za - It's where the heart is...</h4>
        </div>
      </div>
    );
  }
  return null;
};
