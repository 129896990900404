import { Motion } from "@capacitor/motion";
import { Share } from "@capacitor/share";
let accelHandler;
let watcher;

const _Shake2Share = (props) => {
  let sensitivity = 20;

  // Position variables
  let x1 = 0,
    y1 = 0,
    z1 = 0,
    x2 = 0,
    y2 = 0,
    z2 = 0;

  if (props.isSelected()) {
    console.log("Selected.");
    (async () => {
      if (accelHandler) {
        //console.log("Removing previous handler");
        accelHandler.remove();
      }
      if (watcher) {
        clearInterval(watcher);
      }
      accelHandler = await Motion.addListener("accel", (event) => {
        x1 = event.accelerationIncludingGravity.x;
        y1 = event.accelerationIncludingGravity.y;
        z1 = event.accelerationIncludingGravity.z;
      });
      watcher = setInterval(async function () {
        let change = Math.abs(x1 - x2 + y1 - y2 + z1 - z2);
        if (change > sensitivity) {
          //console.log("It's a shake.");
          //console.log(props.message, props.link);
          await Share.share({
            title: "Found on HomeFinder!",
            text: props.message,
            url: props.link,
            dialogTitle: "Share with family and friends...",
          });
        }
        // Update new position
        x2 = x1;
        y2 = y1;
        z2 = z1;
      }, 200);
    })();
  }

  return "SHAKE OR TAP TO SHARE!";
};

const _SocialShare = (props) => {
  Share.share({
    title: "Found on HomeFinder!",
    text: props.message,
    url: props.link,
    dialogTitle: "Share with family and friends...",
  });
};

export const SocialShare = _SocialShare;
export const Shake2Share = _Shake2Share;
