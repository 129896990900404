import React from "react";
import Grid from "@material-ui/core/Grid";
import { Heroes } from "../Components/Heroes";
import { Search } from "../Components/Search";
//import Ads from "../Components/Ads";

function _Search(props) {
  return (
    <>
      <Search
        fetch={props.fetch}
        scrollToResults={props.scrollToResults}
        search={props.search}
        StartOver={props.StartOver}
        towns={props.towns}
      />
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        style={{ marginBottom: "1rem" }}
      >
        <Heroes
          search={props.search}
          display="hero"
          makeLove={props.makeLove}
          selected={props.selected}
          setSelected={props.setSelected}
        />
      </Grid>
    </>
  );
}
export const SearchUI = React.memo(_Search);
