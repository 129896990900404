import React, { useEffect, useState } from "react";
import Postman from "axios";
import { devMode, ip, testSubject } from "../bin/dev";
import {
  makeStyles,
  Typography,
  Switch,
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  MenuItem,
  Grid,
  Button,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { ListingsByRef } from "../Components/ListingsByRef";
import { SearchBar } from "../Components/SearchBar";
import { Section } from "../UI/Section";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  container: {
    textAlign: "left",
  },
  dimmedSummary: {
    color: theme.palette.grey[500],
    display: "none",
  },
  importantChanges: {
    color: theme.palette.info.dark,
  },
  formControlItem: {
    textAlign: "center",
  },
  alignItemRight: {
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  formControlWidth: {
    width: "80%",
    textAlign: "left",
  },
  heading: {
    fontSize: "large",
    fontWeight: "bold",
    color: "#646464",
  },
}));
let shadowState = {};
export default (props) => {
  if (sessionStorage.getItem("authToken")) {
    const [state, setState] = useState({
      PACKAGE: "T1",
      NONRNS: false,
      OOB: false,
      AUTH_BOOKINGS: true,
      isExpanded: "panel3",
      buttonText: "Update Subscription",
      buttonIsDisabled: false,
      showEditButton: true,
      //listingCount: 0,
    });
    const [counter, setCounter] = useState(0);
    const [selected, setSelected] = useState(false);
    const agencyCode = devMode.isDevMode
      ? devMode.devAgencyCode
      : sessionStorage.getItem("agencyCode");
    useEffect(() => {
      const request = {
        method: "post",
        url: `${ip}/dash?action=load`,
        data: {
          agencyCode,
        },
      };
      Postman(request).then((response) => {
        //console.log(response.data);
        shadowState = {
          ...response.data,
          NONRNS: !!(response.data.NONRNS === "true"),
        };
        setState({
          ...state,
          ...response.data,
          NONRNS: !!(response.data.NONRNS === "true"),
          OOB: !!(response.data.OOB === "true"),
          AUTH_BOOKINGS: !!(response.data.AUTH_BOOKINGS === "true"),
        });
      });
      // eslint-disable-next-line
    }, [agencyCode]);

    let greetName = "";
    if (sessionStorage.getItem("userName")) {
      greetName = sessionStorage.getItem("userName");
      greetName =
        greetName.indexOf(" ") > 0
          ? greetName.substr(0, greetName.indexOf(" "))
          : greetName;
    }

    const Criteria = (searchString = null) => {
      let criteria = {
        advertisingSuspended: true, //include non-advertised listing in the result set.
      };

      let agencyCode = sessionStorage["agencyCode"]
        ? sessionStorage["agencyCode"]
        : null;

      if(agencyCode === "R000") {
        agencyCode = "%";
      }

      if (testSubject) {
        agencyCode = testSubject.aid;
      }

      let userCode = sessionStorage["userCode"]
        ? sessionStorage["userCode"]
        : null;

      if(userCode === "167476"){
        userCode = null;
        agencyCode = "%";
      }

      //Enable for testing user level access
      // if (testSubject) {
      //   userCode = testSubject.uid;
      // }

      if (agencyCode) {
        criteria.agencyCode = agencyCode;
        criteria.action = "agency";
      }

      if (userCode) {
        criteria.userCode = userCode;
        criteria.action = "agent";
      }

      if (searchString) {
        criteria.searchString = searchString;
      }
      return criteria;
    };

    const SearchString = (searchString = null) => {
      setState({ ...state, searchString });
    };

    const ReportCount = (listingCount = 0) => {
      setCounter(listingCount);
    };

    const handleExpand = (panel) => (event, isExpanded) => {
      let x = isExpanded ? panel : false;
      setState({ ...state, isExpanded: x });
    };

    const handleChange = (event) => {
      let c = event.target.name;
      if (c === "OOB" || c === "AUTH_BOOKINGS") {
        shadowState = {
          ...shadowState,
          [event.target.name]: event.target.checked,
          heading: "Subscription Preview:",
          isNew: true,
        };
      }
      setState({
        ...state,
        [event.target.name]: event.target.checked,
        buttonIsDisabled: false,
        showButton: true,
        buttonText: "Update Subscription",
        iheading: "Subscription Preview:",
        isNew: true,
      });
    };

    const handleSelect = (event) => {
      setState({
        ...state,
        PACKAGE: event.target.value,
        buttonIsDisabled: false,
        buttonText: "Update Subscription",
      });
    };

    const classes = useStyles();

    const buildMenuItems = () => {
      let packageList = [];
      for (const key in tiers) {
        if (tiers.hasOwnProperty(key)) {
          const element = tiers[key];
          packageList.push(
            <MenuItem key={key} value={key}>
              {element.Name}
            </MenuItem>
          );
        }
      }
      return packageList;
    };

    const calculateLevy = (isNew) => {
      let cost = 0;
      let PACKAGE = isNew ? state.PACKAGE : shadowState.PACKAGE;
      let NONRNS = isNew ? state.NONRNS : shadowState.NONRNS;

      if (PACKAGE) {
        cost = tiers[PACKAGE].RNS;
      }
      if (NONRNS) {
        cost += tiers[PACKAGE].NON;
      }
      return cost;
    };

    const recommendation = () => {
      if (state.projectedMax) {
        for (const key in tiers) {
          if (tiers.hasOwnProperty(key)) {
            const element = tiers[key];
            if (element.MAX > state.projectedMax) {
              if (element.Name === tiers[state.PACKAGE].Name) {
                return "Recommended package selected!";
              } else return `We recommended: ${element.Name}`;
            }
          }
        }
      } else return "The choice is yours.";
    };

    const newSelection = (props) => {
      if (!(calculateLevy(false) === calculateLevy(true))) {
        return subDetails({
          ...state,
          heading: "Subscription Preview:",
          isNew: true,
        });
      }
    };

    const saveSettings = () => {
      let agencyCode = state.AGENCYCODE;
      let portalPackage = state.PACKAGE;
      let nonRNS = state.NONRNS.toString();
      let oob = state.OOB.toString();
      let auth_bookings = state.AUTH_BOOKINGS.toString();
      const request = {
        method: "post",
        url: `${ip}/dash?action=save`,
        data: {
          agencyCode,
          package: portalPackage,
          nonRNS,
          oob,
          auth_bookings,
        },
      };
      Postman(request)
        .then(() => {
          shadowState = { ...state };
        })
        .then((res) => {
          setState({
            ...state,
            showEditButton: true,
            buttonIsDisabled: true,
            showButton: false,
            buttonText: "Subscription Updated",
            isNew: false,
          });
        });
    };

    const summary = (props) => {
      return (
        <>
          <Typography
            variant={"body1"}
            className={classes.container}
          >{`${sessionStorage.agencyName} listing activity`}</Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                {state.totalListings ? (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Annual listing volume
                    </TableCell>
                    <TableCell align="right">{state.totalListings}</TableCell>
                  </TableRow>
                ) : (
                  false
                )}
                {state.averageMonthly ? (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Monthly average
                    </TableCell>
                    <TableCell align="right">{state.averageMonthly}</TableCell>
                  </TableRow>
                ) : (
                  false
                )}
                {state.projectedMax ? (
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Projected maximum
                    </TableCell>
                    <TableCell align="right">{state.projectedMax}</TableCell>
                  </TableRow>
                ) : (
                  false
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    };

    const subDetails = (props) => {
      let cssclass = "";
      if (!(calculateLevy(false) === calculateLevy(true))) {
        cssclass = classes.dimmedSummary;
      }
      if (state.isNew) {
        cssclass = classes.importantChanges;
      }
      return (
        <div className={cssclass}>
          <h4>{props.heading}</h4>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row" className={cssclass}>
                    Package
                  </TableCell>
                  <TableCell align="center" className={cssclass}>
                    <strong>
                      {props?.PACKAGE
                        ? `${tiers[props?.PACKAGE].Name} `
                        : `${tiers["T1"].Name} `}
                    </strong>
                  </TableCell>
                  <TableCell align="right" className={cssclass}>
                    {props?.PACKAGE
                      ? tiers[props?.PACKAGE].Description
                      : tiers["T1"].Description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" className={cssclass}>
                    Advertise all
                  </TableCell>
                  <TableCell align="center" className={cssclass}>
                    <strong>{props?.NONRNS ? "Yes." : "No."}</strong>
                  </TableCell>
                  <TableCell align="right" className={cssclass}>
                    {props?.NONRNS ? "RNS, Sole & Open." : "Only RNS Listings."}
                  </TableCell>
                </TableRow>
                {props?.PACKAGE !== "T4" ? (
                  <TableRow>
                    <TableCell component="th" scope="row" className={cssclass}>
                      Out-of-Bundle
                    </TableCell>
                    <TableCell align="center" className={cssclass}>
                      <strong>{props?.OOB ? "Yes." : "No."}</strong>
                    </TableCell>
                    <TableCell align="right" className={cssclass}>
                        {props?.OOB
                          ? "Advertise all"
                          : `Stick to the limit of ${
                              tiers[state.PACKAGE].MAX - 1
                            }`}
                    </TableCell>
                  </TableRow>
                ) : (
                  false
                )}
                <TableRow>
                  <TableCell component="th" scope="row" className={cssclass}>
                    Authorise Heroes
                  </TableCell>
                  <TableCell align="center" className={cssclass}>
                    <strong>{props?.AUTH_BOOKINGS ? "Yes." : "No."}</strong>
                  </TableCell>
                  <TableCell align="right" className={cssclass}>
                      {props?.AUTH_BOOKINGS
                        ? `Send me SMSs`
                        : `Agents book freely`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row" className={cssclass}>
                    Levy
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={cssclass}
                  ></TableCell>
                  <TableCell align="right" className={cssclass}>
                    <strong>{`R ${calculateLevy(props.isNew)}`}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      );
    };

    const updateButton = (show) => {
      if (!(calculateLevy(false) === calculateLevy(true)) || show) {
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.alignItemRight}>
              <Button
                disabled={state.buttonIsDisabled}
                variant="contained"
                color="primary"
                size={"large"}
                style={{ marginTop: 10 }}
                onClick={saveSettings}
              >
                {state.buttonText}
              </Button>
            </Grid>
          </Grid>
        );
      }
    };

    const editButton = (bool) => {
      const editSettings = () => {
        setState({ ...state, showEditButton: !state.showEditButton });
      };
      if (bool) {
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.alignItemRight}>
              <Button
                variant="outlined"
                color="primary"
                size={"large"}
                style={{ marginTop: 10 }}
                onClick={editSettings}
              >
                Edit Subscription
              </Button>
            </Grid>
          </Grid>
        );
      }
    };

    const editSubscription = (bool) => {
      if (bool) {
        return (
          <div>
            <hr />
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} className={classes.formControlItem}>
                <FormControl className={classes.formControlWidth}>
                  <FormLabel>{recommendation()}</FormLabel>
                  <Select
                    value={state.PACKAGE ? state.PACKAGE : "T1"}
                    onChange={handleSelect}
                  >
                    {buildMenuItems()}
                  </Select>
                  <FormHelperText>Choose your package</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.formControlItem}>
                <FormControl className={classes.formControlWidth}>
                  <FormLabel>{`Advertise all ${sessionStorage.agencyName} Listings`}</FormLabel>
                  <Switch
                    name="NONRNS"
                    checked={state.NONRNS}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <FormHelperText>
                    {state.NONRNS ? "Activated." : "Not currently active."}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.formControlItem}>
                <FormControl className={classes.formControlWidth}>
                  <FormLabel>{`Out-of-Bundle`}</FormLabel>
                  <Switch
                    name="OOB"
                    checked={state.PACKAGE === "T4" ? true : state.OOB}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    disabled={state.PACKAGE === "T4"}
                  />
                  <FormHelperText>
                    {state.OOB
                      ? `Advertise beyond package limit of ${
                          tiers[state.PACKAGE].MAX - 1
                        }  listings`
                      : `Do not advertise more than ${
                          tiers[state.PACKAGE].MAX - 1
                        }  listings`}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.formControlItem}>
                <FormControl className={classes.formControlWidth}>
                  <FormLabel>{`Authorise hero bookings`}</FormLabel>
                  <Switch
                    name="AUTH_BOOKINGS"
                    checked={state.AUTH_BOOKINGS}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <FormHelperText>
                    {state.AUTH_BOOKINGS
                      ? `Send authorisation requests to principal / manager`
                      : `Allow hero bookings without authorisation`}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        );
      }
    };

    return (
      <>
        <Typography
          id={"veryTop"}
          component="div"
          style={{ minHeight: "100vh" }}
        >
          <div style={{ textAlign: "right", paddingTop: 30 }}>
            <h4 style={{ margin: 0 }}>Welcome {greetName}</h4>
            <h3 style={{ padding: 0, margin: 0 }}>
              {sessionStorage.getItem("agencyName")}
            </h3>
          </div>
          <Section id={"divAgents"} heading={"PORTAL DASHBOARD"} />
          {!sessionStorage["userCode"] ? (
            <Accordion
              expanded={state.isExpanded === "panel1"}
              onChange={handleExpand("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Subscription Settings
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.root}>
                {editSubscription(!state.showEditButton)}
                {updateButton(
                  state.showButton ? state.showButton : !state.showEditButton
                )}
                {newSelection()}
                {subDetails({
                  ...shadowState,
                  //heading: "Current Subscription:",
                  isNew: false,
                })}
                {editButton(state.showEditButton)}
              </AccordionDetails>
            </Accordion>
          ) : (
            false
          )}

          <Accordion
            expanded={state.isExpanded === "panel2"}
            onChange={handleExpand("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                className={classes.heading}
              >{`Listing Overview`}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.root}>
              {summary()}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={state.isExpanded === "panel3"}
            onChange={handleExpand("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className={classes.heading}>
                Manage Portal Listings
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.root}>
              <SearchBar SearchString={SearchString} listingCount={counter} />
              <ListingsByRef
                display={"dash"}
                reportCount={ReportCount}
                criteria={Criteria(
                  state?.searchString ? state.searchString : null
                )}
                makeLove={props.makeLove}
                searchString={state?.searchString}
                setSelected={setSelected}
                selected={selected}
              />
            </AccordionDetails>
          </Accordion>
        </Typography>
      </>
    );
  } else {
    window.location = `${process.env.PUBLIC_URL}/auth`;
    return null;
  }
};

const tiers = {
  T1: {
    Name: "Starter",
    Description: "Up to 10 listings",
    MIN: -1,
    MAX: 11,
    RNS: 0,
    NON: 200,
  },
  T2: {
    Name: "Basic",
    Description: "Up to 20 listings",
    MIN: 10,
    MAX: 21,
    RNS: 150,
    NON: 350,
  },
  T3: {
    Name: "Pro",
    Description: "Up to 50 listings",
    MIN: 20,
    MAX: 51,
    RNS: 500,
    NON: 680,
  },
  T4: {
    Name: "Unlimited",
    Description: "Total freedom",
    MIN: 50,
    MAX: 1000001,
    RNS: 1050,
    NON: 1120,
  },
};
