import React, { useState, useEffect } from "react";
import Postman from "axios";
import { ip } from "../bin/dev";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  makeStyles,
  Link,
} from "@material-ui/core";
import { Log } from "./Log";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    textAlign: "left",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  contian: {
    backgroundSize: "contain",
    width: 135,
    height: 135,
    backgroundPosition: "left"
  },
}));

let _Agency = (props) => {
  const classes = useStyles();
  let LogoURL = `https://www.rnslive.co.za/MemberPages/logos/${props.agencyCode}.png`;
  const [state, setState] = useState(false);

  useEffect(() => {
    Postman.get(`${ip}/info?agency=${props.agencyCode}`).then((response) => {
      //   let testResponse = {
      //     COMPANYNAME: "Remax Dazzle",
      //     EMAIL: "nevbrits@mweb.co.za",
      //     CONTACTNUMBER: "0113949058",
      //     PRIMARYCONTACT: "Neville Brits",
      //     PRIMARYCONTACTNUMBER: "0836297833",
      //   };
      setState({
        ...response.data,
        hiddenContactNumber: `${response.data.CONTACTNUMBER.substr(0, 6)}...`,
        log: false,
      });
    });
  }, [props.agencyCode]);

  const revealContactNumber = () => {
    setState({ ...state, hiddenContactNumber: state.CONTACTNUMBER });
  };

  function log() {
    return (
      <Log
        listing_id={props.agencyCode}
        conversion_event={"contact"}
        conversion_meta={state.log}
      />
    );
  }
  return (
    <>
      <Card className={classes.root}>
        <Link href={`/agency/${props.agencyCode}`} title="View all company">
          <CardMedia
            className={classes.contian}
            title={
              !props.counter
                ? `Click View All ${state.COMPANYNAME} Listings`
                : state.COMPANYNAME
            }
            image={LogoURL}
          />
        </Link>
        <CardContent className={classes.content}>
          {props.counter && (
            <>
              <Typography variant="h4">{state.COMPANYNAME}</Typography>
              <Typography variant="h6">{`Listings: ${props.counter}`}</Typography>
              {"Contact# "}
              <Link
                href={`tel:${state.CONTACTNUMBER}`}
                onClick={revealContactNumber}
                color="inherit"
              >
                {state.log ? log() : false}
                {state.hiddenContactNumber}
              </Link>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export const AgencyDetails = _Agency;
