import React from "react";
//import { useHistory } from "react-router-dom";
import { Listing } from "../Components/Listing";

//export default (props) => {
function _Listings(props) {
  // const data = props.listings;
  // const PAGE_DATA = () => {
  //   console.log(data)
  //   return data;
  // };

  //const history = useHistory();

  // const selectedCallBack = (listing) => {
  //   history.push(`${process.env.PUBLIC_URL}/found/${listing}`);
  // };

  const listings = props.listings.data || props.listings;
  if (listings) {
    //console.log(listings)
    return listings.map((listing, i) => (
      <Listing
        key={listing.REF + "-" + i}
        id={listing.REF}
        display={props.display}
        showSimilar={props.showSimilar}
        DATA={listing}
        altAgent={props.altAgent}
        LinkHomeToAgent={props.LinkHomeToAgent}
        //SELECTED={selectedCallBack}
        //PAGE_DATA={PAGE_DATA}
        hideThumb={!!props.hideThumb}
        canClick={!!props.canClick}
        //FOUND={listings.length}
        //INDEX={i}
        expanded={props?.expanded}
        makeLove={props.makeLove}
        removeOnClick={props?.removeOnClick ? props.removeOnClick : false}
        setSelected={props.setSelected}
        selected={props.selected}
        isAvailable={
          typeof props.isAvailable === "undefined" ? true : props.isAvailable
        }
      />
    ));
  }
  return null;
} // Consider memo
export const Listings = React.memo(_Listings);
