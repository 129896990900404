import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Postman from "axios";
import { ip } from "../bin/dev";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const white = "whitesmoke";
const black = "#333333";

const useStyles = makeStyles((theme) => ({
  searchButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.secondary.contrastText,
    },
  },
  cancelButton: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.secondary.contrastText,
    },
  },
  leftButton: {
    textAlign: "right",
    paddingLeft: 0,
  },
  rightButton: {
    textAlign: "left",
    paddingRight: 0,
    borderColor: theme.palette.primary.light,
    color: theme.palette.secondary.contrastText,
  },
  homeFinder: {
    backgroundColor: "#034d9b",
  },
  formControl: {
    color: "",
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  label: {
    fontSize: "x-large",
    color: white + "!important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "large",
      color: black + "!important",
    },
    //color: theme.palette.primary.dark,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  fullWidth: {
    backgroundColor: "rgb(69, 152, 157)",
    background:
      "linear-gradient(128deg,rgba(69, 152, 157, 1) 0%,rgba(26, 56, 90, 1) 100%)",
    color: white + "!important",
    [theme.breakpoints.down("sm")]: {
      background: "unset",
      backgroundColor: "unset",
      color: theme.palette.grey[100] + "!important",
      fontSize: "large",
    },
    width: "100%",
    minHeight: "10vh",
    marginBottom: "0.3em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "left",
    fontSize: "xx-large",
  },
  icon: {
    fill: white,
    [theme.breakpoints.down("sm")]: {
      fill: black,
    },
  },
  underline: {
    marginTop: "20px !important",
    [theme.breakpoints.down("sm")]: {
      borderColor: black,
      marginTop: "16px !important",
    },
    "&:before": {
      borderColor: white,
      [theme.breakpoints.down("sm")]: {
        borderColor: black,
      },
    },
    "&:after": {
      borderColor: white,
      [theme.breakpoints.down("sm")]: {
        borderColor: black,
      },
    },
    
  },
  nasa: {
    margin: "0px!important",
  },
  rentalToggle: {
    color: white,
    width:"100%",
    fontSize: "1.2rem !important",
    [theme.breakpoints.down("sm")]: {
      color: black,
      fontSize: "1rem !important",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const defautCriteria = {
  town: [],
  type: [],
  price: [],
  attr: [],
  rentalSearch: false,
  count: true,
};

const cancelDropDown = (event) => {
  event.stopPropagation();
};

let criteria = defautCriteria;
let shaddowCriteria = { ...criteria, suburbs: {} };
let count = 0;

//export default (props) => {
function _Search(props) {
  const theme = useTheme();
  let sm = useMediaQuery(theme.breakpoints.down("sm"));
  let xs = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const search = props.search;

  count = search.count;

  const selectCriteriaState = {
    town: false,
    type: false,
    price: false,
    attr: false,
  };

  const [open, setOpen] = useState(selectCriteriaState);
  const [towns, setTowns] = useState([]);
  const [toggle, setToggle] = useState(false);

  criteria = { ...search.criteria, rentalSearch: toggle };

  useEffect(() => {
    Postman.get(`${ip}/towns/?rentals=false`)
    .then((response) => {
      setTowns(response.data);
    });
    // eslint-disable-next-line
  }, []);

  //Maintain shaddow of criteria yielding positive results.
  if (count > 0) {
    shaddowCriteria = criteria;
  }

  //Load last selection yielding results.
  const loadSelection = () => {
    console.log("Loading shadow selection...");
    props.fetch(
      { ...shaddowCriteria, count: true },
      false,
      { ...search, suburbs: {} },
      true
    );
  };

  const showResults = () => {
    criteria.count = false;
    criteria.datetime = new Date().getTime();
    props.fetch(
      { ...criteria, suburbs: {} },
      false,
      { ...search, suburbs: {} },
      true
    );
    props.scrollToResults();
    let elements = document.querySelectorAll(".slide-in-left");
    elements.forEach((element) => {
      element.classList.remove("slide-in-left");
      setTimeout(() => {
        element.classList.add("bounce-in-bottom");
      }, 500);
    });
  };

  const handleChange = (event, key) => {
    let town = event.target.value;
    criteria = {
      ...criteria,
      [key]: town,
      count: false,
    };
    handleOpen();
    criteria.datetime = new Date().getTime();
    props.fetch(
      { ...criteria, suburbs: {} },
      false,
      { ...search, suburbs: {} },
      true
    );
    document.querySelector("#searchActions")?.classList.remove("fade-out");
  };

  const handleDelete = (item, key) => {
    let x = criteria[key].filter((chip) => chip !== item);
    criteria = { ...criteria, [key]: x, count: true };
    props.fetch(
      { ...criteria, suburbs: {} },
      false,
      { ...search, suburbs: {} },
      true
    );
  };

  const clearSearch = () => {
    props.fetch({ ...defautCriteria }, false, { ...search, suburbs: {} }, true);
  };

  const handleOpen = (criteria) => {
    criteria
      ? setOpen({ ...open, [criteria]: true })
      : setOpen(selectCriteriaState);
  };

  const makeTownArray = () => {
    let T = towns.map((el) => {
      return `${el.TOWN}`;
    });
    return T;
  };

  const [filters, setFilters] = useState({
    town: [...makeTownArray()], //fetchTowns(),
    type: [
      "House",
      "Cluster",
      "Town House",
      "Apartment",
      "Small Holding",
      "Vacant Land",
    ],
    price: [
      "Less than R500k",
      "R500k - R750k",
      "R750k - R1m",
      "R1m - R1.25m",
      "R1.25m - R1.5m",
      "R1.5m - R1.75m",
      "R1.75m - R2m",
      "R2m - R2.25m",
      "R2.25m - R2.5m",
      "R2.5m - R2.75m",
      "R2.75m - R3m",
      "Above R3m",
    ],
    feature: [
      "1+ Bedroom",
      "2+ Bedrooms",
      "3+ Bedrooms",
      "1+ Bathroom",
      "2+ Bathrooms",
      "3+ Bathrooms",
      "Carport",
      "Dining room",
      "1+ Garage",
      "2+ Garages",
      "3+ Garages",
      "Granny flat",
      "Laundry",
      "Lounge",
      "Pantry",
      "Pool",
      "Scullery",
      "Staff Quarters",
      "Study",
    ],
  });


  const handleToLetForSale = (event)=>{
    setToggle(event.target.checked);
    criteria = {...criteria, rentalSearch: !toggle};
    console.log(criteria);
    Postman.get(`${ip}/towns/?rentals=${!toggle}`)
    .then((response) => {
      setTowns(response.data);
    })
    .then(()=>{
      setFilters({...filters, town: [...makeTownArray()]});
      props.fetch(
        { ...criteria, suburbs: {} },
        false,
        { ...search, suburbs: {} },
        true
      );
    });
    

    //selectCriteriaState.forSaleToRentToggle = !selectCriteriaState.forSaleToRentToggle;
    //console.log(selectCriteriaState.forSaleToRentToggle);
  }

  return (
    <>
      <Paper className={classes.fullWidth}>
        <Grid container spacing={2} className={classes.nasa}>
          <Grid item xs={12} sm={6} md={3} className="pos-relative">
          {xs && (<div className="flex-align-right pos-abs-top-right z-index-1 rental-toggle"><FormControlLabel classes={{label: classes.rentalToggle}} control={<Switch checked={toggle} onChange={handleToLetForSale} />} labelPlacement="start" label={toggle ? "To Let" : "For Sale"} /></div>)}
            <FormControl className={classes.formControl}>
              <InputLabel className={classes.label}>Town</InputLabel>
              <Select
                id="mutiple-town"
                className={classes.underline}
                multiple
                data={towns}
                value={criteria.town}
                onChange={(e) => {
                  handleChange(e, "town");
                }}
                open={open.town}
                onOpen={() => {
                  handleOpen("town");
                }}
                onClose={() => {
                  handleOpen();
                }}
                input={<Input id="select-multiple-chip-towns" />}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        value={value}
                        className={classes.chip}
                        onMouseDown={cancelDropDown}
                        onDelete={() => {
                          handleDelete(value, "town");
                        }}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {towns.map((el) => (
                  <MenuItem key={el.TOWN} value={el.TOWN}>
                    {el.TOWN} [{el.LISTINGS}]
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="pos-relative">
          {(sm && !xs) && (<div className="flex-align-right pos-abs-top-right z-index-1 rental-toggle"><FormControlLabel classes={{label: classes.rentalToggle}} control={<Switch checked={toggle} onChange={handleToLetForSale} />} labelPlacement="start" label={toggle ? "To Let" : "For Sale"} /></div>)}
            <FormControl className={classes.formControl}>
              <InputLabel className={classes.label}>Type</InputLabel>
              <Select
                id="mutiple-type"
                className={classes.underline}
                multiple
                value={criteria.type}
                onChange={(e) => {
                  handleChange(e, "type");
                }}
                open={open.type}
                onOpen={() => {
                  handleOpen("type");
                }}
                onClose={() => {
                  handleOpen();
                }}
                input={<Input id="select-multiple-chip-type" />}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                        onMouseDown={cancelDropDown}
                        onDelete={() => {
                          handleDelete(value, "type");
                        }}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {filters.type.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl}>
              <InputLabel className={classes.label}>Price</InputLabel>
              <Select
                id="mutiple-price"
                className={classes.underline}
                multiple
                value={criteria.price}
                onChange={(e) => {
                  handleChange(e, "price");
                }}
                open={open.price}
                onOpen={() => {
                  handleOpen("price");
                }}
                onClose={() => {
                  handleOpen();
                }}
                input={<Input id="select-multiple-chip-price" />}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                        onMouseDown={cancelDropDown}
                        onDelete={() => {
                          handleDelete(value, "price");
                        }}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {filters.price.map((price) => (
                  <MenuItem key={price} value={price}>
                    {price}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className="pos-relative">
          {sm || (<div className="flex-align-right pos-abs-top-right z-index-1 rental-toggle"><FormControlLabel classes={{label: classes.rentalToggle}} control={<Switch checked={toggle} onChange={handleToLetForSale} />} labelPlacement="start" label={toggle ? "To Let" : "For Sale"} /></div>)}
            <FormControl className={classes.formControl + " pos-relative"}>
              <InputLabel className={classes.label}>Features</InputLabel>
              <Select
                id="mutiple-features"
                className={classes.underline}
                multiple
                value={criteria.attr}
                onChange={(e) => {
                  handleChange(e, "attr");
                }}
                open={open.attr}
                onOpen={() => {
                  handleOpen("attr");
                }}
                onClose={() => {
                  handleOpen();
                }}
                input={<Input id="select-multiple-chip-features" />}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                        onMouseDown={cancelDropDown}
                        onDelete={() => {
                          handleDelete(value, "attr");
                        }}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {filters.feature.map((feature) => (
                  <MenuItem key={feature} value={feature}>
                    {feature}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {search.showActions ? (
            <>
              <Grid
                item
                xs={6}
                className={classes.leftButton}
                id="#searchActions"
              >
                <Button
                  fullWidth={false}
                  className={classes.searchButton}
                  variant="text"
                  onClick={
                    count > 0
                      ? () => props.scrollToResults() //showResults()
                      : () => loadSelection()
                  }
                >
                  <SearchIcon />
                  {typeof count === "undefined"
                    ? "Finding..."
                    : count === 0
                    ? `Nothing found. Find closest matching?`
                    : `View ${count === 50 ? "over" : ""} ${count} ${
                        count === 1 ? "home" : "homes"
                      }`}
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.rightButton}>
                <Button
                  className={classes.cancelButton}
                  fullWidth={false}
                  variant="contained"
                  onClick={() => {
                    let element = document.querySelector("#searchActions");
                    element?.classList.add("fade-out");
                    setTimeout(() => {
                      clearSearch();
                    }, 500);
                  }}
                >
                  <CloseIcon />
                  Start over
                </Button>
              </Grid>
            </>
          ) : (
            false
          )}
        </Grid>
      </Paper>
    </>
  );
}

export const Search = React.memo(_Search);
