import React, { useState, useEffect } from "react";
import { common } from "../bin/common";
import "../bin/common";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Container,
  Icon,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import Gallery from "../Components/Gallery";
import Agent from "./Agent";
import { AgencyDetails } from "./Agency";
import Postman from "axios";
import { Listings } from "../UI/Listings";
import { ip } from "../bin/dev";
import { Section } from "../UI/Section";
import { makeStyles } from "@material-ui/core/styles";
import { Log } from "./Log";
import { Capacitor } from "@capacitor/core";
import { Shake2Share, SocialShare } from "../Components/Shake";

// eslint-disable-next-line
// String.prototype.toProperCase = function () {
//   return this.replace(/\w\S*/g, function (txt) {
//     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//   });
// };

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  footRoot: {
    width: "100%",
    top: "auto",
    bottom: "-1px",
    color: "white!important",
    backgroundColor: theme.palette.secondary.dark,
  },
  back: {
    color: "white!important",
    position: "absolute",
    left: 0,
    zIndex: 1,
  },
  love: {
    color: "white!important",
  },
  forward: {
    color: "white!important",
    position: "absolute",
    right: 0,
    zIndex: 1,
  },
  pagerFeedback: {
    padding: "13px",
    fontSize: "x-large",
    lineHeight: "1",
    textAlign: "center",
  },
  paperBG: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  line: {
    border: "3px solid #09222c",
  },
  item: {
    backgroundColor: "inherit",
    // "&:nth-child(even)": {
    //   backgroundColor: "#09222c10",
    // },
    transition: "1s",
    "&:hover": {
      backgroundColor: "#09222c40",
      transition: "1s",
    },
  },
  similarFrame: {
    padding: 10,
    marginBottom: 25,
    borderRadius: 10,
    //backgroundColor: theme.palette.secondary.light,
  },
  similarFrameHeading: {
    color: theme.palette.primary.main,
    marginBottom: 10,
  },
  AppBar: {
    position: "fixed",
    top: 0,
    left: 0,
    //height: 50,
    backgroundColor: theme.palette.secondary.dark,
  },
  button: {
    height: 50,
    boxShadow: "none",
    borderRadius: "unset",
  },
  gridContainer: {
    textAlign: "left",
  },
  SearchIcon: {
    color: theme.palette.grey[100],
  },
  viewControlTop: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  viewControlBottom: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  shareOnWhatsApp: {
    textAlign: "center",
    backgroundColor: "#6be777",
    [theme.breakpoints.down("xs")]: {
      width: "100%", // none for desktops
    },
  },
  shareContainer: {
    textAlign: "center",
    // [theme.breakpoints.up("sm")]: {
    //   textAlign: "right",
    //   position: "relative",
    //   top: "54px",
    //   right: "10px",
    //   zIndex: 1,
    // },
  },
  imageIcon: {
    width: "93%",
  },
  iconRoot: {
    textAlign: "center",
    color: "white",
  },
  Logo: {
    maxWidth: 300,
    maxHeight: 75,
  },
  description: {
    whiteSpace: "break-spaces",
    padding: "10px",
    fontFamily: "inherit",
    color: "rgba(0, 0, 0, 0.54)",
  },
  vtButton: {
    position: "absolute",
    zIndex: "1",
    marginLeft: "34px",
    marginTop: "10px",
    textDecoration: "none",
    fontSize: "2rem",
    background: "darkturquoise",
    color: "white",
    textShadow: "2px 2px 4px black",
    padding: "0 10px",
    borderRadius: "13px 0",
  },
}));

// eslint-disable-next-line
export default (props) => {
  const [attr, setAttr] = useState();
  const [status, setStatus] = useState({ isAvailable: true, isHidden: false });
  // eslint-disable-next-line
  let action = "next";
  if (attr) {
    action = attr.Action;
  }

  const LinkedToAgent = (agentCode) => {
    if (
      window.location.pathname.indexOf("hearts") > 0 ||
      window.location.pathname.indexOf("found") > 0
    ) {
      if (localStorage["linkedHomes"] && localStorage["linkedAgents"]) {
        let homesLinked = localStorage["linkedHomes"].split(",");
        let agentsLinked = localStorage["linkedAgents"].split(",");
        if (homesLinked.length !== agentsLinked.length) {
          localStorage.removeItem("linkedHomes");
          localStorage.removeItem("linkedAgents");
        } else {
          let linkedHomeIndex = homesLinked.findIndex(
            (linkedHome) => linkedHome === props.REF
          );
          if (linkedHomeIndex > -1) {
            agentCode = agentsLinked[linkedHomeIndex];
          }
        }
      }
      if (props.altAgent) {
        return props.altAgent;
      }
      return agentCode;
    } else {
      return agentCode;
    }
  };

  const SimilarHeading = (count) => {
    let these = "these homes";
    if (count === 1) these = "this home";
    let heading = `You may also like ${these}...`;
    if (count < 1) heading = "";
    return heading;
  };

  useEffect(() => {
    let post = {
      listingID: props.REF,
    };
    let legacyPost = {
      listingID: { lid: props.REF },
    };
    let agencyCode = props.REF.substr(0, 4);
    let LogoURL = `https://www.rnslive.co.za/MemberPages/logos/${agencyCode}.png`;
    const doTheThing = () => {
      if (data) {
        let postSimilar = {
          criteria: {
            town: [data.TOWN],
            type: [data.CLASS],
            price: [common.priceRange(data.PRICE)],
            attr: ["RNS"],
          },
        };
        Postman.post(`${ip}/listings?q=search`, postSimilar).then((res) => {
          similarListings =
            props.showSimilar ||
            (!data.STATUS.startsWith("For Sale") &&
              !data.STATUS.startsWith("To Let"))
              ? res.data
              : null;
          let agent = LinkedToAgent(data.AGENTID);
          Postman.post(`${ip}/agent`, { agent }).then((response) => {
            let agent = response.data[0];
            if (agent) {
              LogoURL = `https://www.rnslive.co.za/MemberPages/logos/${agent.AGENCY_CODE}.png`;
            }
            //console.log(agent)
            Postman.post(
              "https://www.rnslive.co.za/wsRNS.asmx/Attributes",
              legacyPost
            ).then((response) => {
              let description = JSON.parse(response.data.d)[0];
              setAttr({
                agencyCode,
                Logo: LogoURL,
                Interior: description.Interior,
                Exterior: description.Exterior,
                //Pager: props.pageData ? page(action, props.REF) : [props.REF],
                Listing: [data],
                similar: similarListings,
                Agent: agent,
                Ready: true,
                Action: action,
              });
            });
          });
        });
      }
    };
    Postman.get(LogoURL).catch((err) => {
      console.log("Swopping out logo :)");
      LogoURL = `${process.env.PUBLIC_URL}/assets/home-finder-generic-logo.png`;
    });
    let similarListings;
    let data = props.data;
    if (!data) {
      Postman.post(`${ip}/listings?q=single`, post).then((response) => {
        data = response.data[0];
        if (
          !data ||
          (!data.STATUS.startsWith("For Sale") &&
            !data.STATUS.startsWith("To Let"))
        ) {
          setStatus({ isAvailable: false, isHidden: true });
        }

        doTheThing();
      });
    } else {
      doTheThing();
    }
    // eslint-disable-next-line
  }, [props.REF]);

  function log() {
    return (
      <Log
        listing_id={props.REF}
        conversion_event={"shared"}
        conversion_meta={"whatsapp"}
      />
    );
  }

  // const page = (action, ref) => {
  //   let pager = props.pageData();
  //   if (!pager?.pages) {
  //     pager.pages = [];
  //     for (const item of pager.data) {
  //       pager.pages.push(item.REF);
  //     }
  //   }
  //   let currentIndex = pager.pages.findIndex((el) => el === ref);
  //   let pageData = {};
  //   for (const p in pager.data) {
  //     if (pager.data[p].REF === ref) {
  //       pageData.data = pager.data[p];
  //       break;
  //     }
  //   }
  //   pageData.heading = `${currentIndex + 1} of ${
  //     Object.keys(pager.data).length
  //   }`;
  //   pageData.action = action;
  //   pageData.isFirst = currentIndex === 0;
  //   pageData.isLast = currentIndex === pager.pages.length - 1;
  //   pageData.index = currentIndex;
  //   pageData.pages = pager.pages;
  //   return pageData;
  // };
  const classes = useStyles();

  let Int = [];
  let Ext = [];
  let socialShare;
  let socialLink;
  if (status.isHidden) {
    return (
      <Paper
        style={{
          //width: window.innerWidth,
          //height: window.innerHeight - 32,
          //display: "table-cell",
          textAlign: "center",
        }}
      >
        <Typography variant="h2">We're sorry.</Typography>
        <Typography variant="body1">
          This home is no longer available.
        </Typography>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            setStatus({ ...status, isHidden: false });
          }}
        >
          View Anyway
        </Button>
        {attr && attr.similar && attr.similar.length > 0 && (
          <div className={classes.viewControlBottom}>
            <div className={classes.similarFrame}>
              <Typography variant="h6" className={classes.similarFrameHeading}>
                {SimilarHeading(attr.similar.length)}
              </Typography>
              <Listings
                display={"similar"}
                listings={{ data: [...attr.similar] }}
                canClick={true}
                makeLove={props.makeLove}
                altAgent={props.AGENTID}
                LinkHomeToAgent={props.LinkHomeToAgent}
              />
            </div>
          </div>
        )}
      </Paper>
    );
  }
  if (attr) {
    Int = attr.Interior;
    Ext = attr.Exterior;
    socialShare =
      attr.Listing[0].BEDROOMS > 0
        ? `${attr.Listing[0].STYLE} ${attr.Listing[0].BEDROOMS} bedroom ${
            attr.Listing[0].CLASS
          } in ${attr.Listing[0].SUBURB.toProperCase()} ${attr.Listing[0].TOWN.toProperCase()} for ${attr.Listing[0].PRICE.toString().toZAR()}`
        : `${attr.Listing[0].STYLE} ${
            attr.Listing[0].CLASS
          } in ${attr.Listing[0].SUBURB.toProperCase()} ${attr.Listing[0].TOWN.toProperCase()} for ${attr.Listing[0].PRICE.toString().toZAR()}`;
    socialLink = `https://www.homefinder.co.za${process.env.PUBLIC_URL}/found/${
      props.REF
    }${
      props.AGENTID
        ? "/" + props.AGENTID
        : props.altAgent
        ? "/" + props.altAgent
        : "/" + attr.Listing[0].AGENTID
    }`;
    return (
      <Container className={classes.container}>
        <Grid container className={classes.gridContainer} spacing={1}>
          <Grid item xs={12} md={6}>
            {" "}
            {attr ? (
              <Gallery
                REF={props.REF}
                VTOUR={attr.Listing[0].VTOUR}
                LISTINGAGENT={attr.Listing[0].AGENTID}
                MARKETINGAGENT={props.AGENTID}
              />
            ) : (
              false
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {attr ? (
              <>
                <Listings
                  display={"single"}
                  limit={1}
                  listings={{ data: attr.Listing }}
                  hideThumb={true}
                  canClick={false}
                  makeLove={props.makeLove}
                  altAgent={props.altAgent}
                  LinkHomeToAgent={props.LinkHomeToAgent}
                  isAvailable={status.isAvailable}
                />
                <Grid item xs={12}>
                  <AgencyDetails agencyCode={attr.Agent.AGENCY_CODE} />
                </Grid>
                <Agent
                  data={attr.Agent}
                  horizontal={true}
                  key={attr.Agent.AGENTID}
                  //agentID={attr.Agent.AGENTID}
                  altAgent={LinkedToAgent(props.AGENTID)}
                  listingImage={props.listingImage}
                  listingRef={props.REF}
                  //agent={attr.Agent..toProperCase()}
                  //agency={attr.Agent.AGENCY_NAME}
                  //phone={attr.Agent.CELL_NUMBER}
                  socialShare={socialShare}
                  socialLink={socialLink}
                />
              </>
            ) : (
              false
            )}
          </Grid>
          <Grid item xs={12} className={classes.shareContainer}>
            {Capacitor.getPlatform() === "web" && (
              <Button
                className={classes.shareOnWhatsApp}
                onClick={() => {
                  setAttr({ ...attr, logChat: true });
                }}
                variant="contained"
                color="primary"
                href={`https://wa.me/?text=${socialShare}%0A${socialLink}`}
                target="_blank"
                startIcon={
                  <Icon>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icons/wa-icon-white.svg`}
                      className={classes.imageIcon}
                      alt="WhatsApp Icon"
                    />
                  </Icon>
                }
              >
                SHARE ON WHATSAPP
              </Button>
            )}
            {(Capacitor.getPlatform() === "android" ||
              Capacitor.getPlatform() === "ios") && (
              <Button
                className={classes.shareOnWhatsApp}
                onClick={() => {
                  let ss = {
                    message: socialShare,
                    link: socialLink,
                    isSelected: props.isSelected,
                  };
                  SocialShare(ss);
                  setAttr({ ...attr, logChat: true });
                }}
                variant="contained"
                color="primary"
                startIcon={
                  <Icon>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icons/share.svg`}
                      className={classes.imageIcon}
                      alt="Share Icon"
                    />
                  </Icon>
                }
              >
                <Shake2Share
                  message={socialShare}
                  link={socialLink}
                  isSelected={props.isSelected}
                />
              </Button>
            )}
            {attr.logChat ? log() : false}
          </Grid>
          {attr.Listing[0].HF_DESCRIPTION && (
            <Grid item xs={12}>
              {attr.Listing[0].HF_TITLE ? (
                <Section heading={attr.Listing[0].HF_TITLE} />
              ) : null}
              <Paper className={classes.paperBG}>
                <pre className={classes.description}>
                  {attr.Listing[0].HF_DESCRIPTION}
                </pre>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            {Int ? (
              <div className={classes.paperBG}>
                <Section heading={"HOME INTERIOR"} />
                <List dense={true}>
                  {Int.map((item, i) => {
                    return (
                      <ListItem key={i} className={classes.item}>
                        <ListItemText
                          primary={Object.keys(item)[0]}
                          secondary={item[Object.keys(item)[0]].map(
                            (detail) => {
                              return `${detail}. `;
                            }
                          )}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            ) : (
              false
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {Ext ? (
              <div className={classes.paperBG}>
                <Section heading={"HOME EXTERIOR"} />
                <List dense={true}>
                  {Ext.map((item, i) => {
                    return (
                      <ListItem key={i} className={classes.item}>
                        <ListItemText
                          primary={Object.keys(item)[0]}
                          secondary={item[Object.keys(item)[0]].map(
                            (detail) => {
                              return `${detail}. `;
                            }
                          )}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            ) : (
              false
            )}
          </Grid>
          <Grid item xs={12}>
            {attr.similar && status.isHidden ? (
              attr.similar.length > 0 ? (
                <div className={classes.viewControlBottom}>
                  <div className={classes.similarFrame}>
                    <Typography
                      variant="h6"
                      className={classes.similarFrameHeading}
                    >
                      {SimilarHeading(attr.similar.length)}
                    </Typography>
                    <Listings
                      display={"search"}
                      listings={{ data: [...attr.similar] }}
                      canClick={true}
                    />
                  </div>
                </div>
              ) : (
                false
              )
            ) : (
              false
            )}
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Paper
      style={{
        //width: window.innerWidth,
        //height: window.innerHeight - 32,
        //display: "table-cell",
        textAlign: "center",
        verticalAlign: "middle",
      }}
    >
      <CircularProgress />
      <br />
      Loading...
    </Paper>
  );
};
