import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "./UI/Frame";
import Auth from "./routes/authenticate";
import Settings from "./routes/dashboard";
import { Start } from "./routes/index";
import Found from "./routes/found";
import { Stage } from "./routes/stage";
import Finance from "./routes/finance";
import Rentals from "./routes/rentals";
import Hearts from "./routes/hearts";
import Agent from "./routes/agent";
import { Agency } from "./routes/agency";
import { Calculate } from "./routes/calculate";
import News from "./routes/news";
import Privacy from "./routes/privacy";
import { makeStyles } from "@material-ui/core/styles";

const isStaged = () => {
  return window.location.pathname.indexOf("stage") > 0;
};
const useStyles = makeStyles((theme) => ({
  app: {
    //backgroundColor: theme.palette.background.default,
  },
  root: {
    paddingTop: 24,
    backgroundColor: theme.palette.grey[200],
    flexDirection: "column",
    color: "rgb(54, 54, 54)",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      minHeight: isStaged() ? "100vh" : "calc(100vh - 109px)",
      //marginTop: -14,
    },
    [theme.breakpoints.up("md")]: {
      minHeight: isStaged() ? "100vh" : "calc(100vh - 134px)",
      //marginTop: 32,
    },
    //display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
  },
}));

const _App = () => {
  const classes = useStyles();
  const [love, makeLove] = useState(false);
  //Make love if it does not exist
  if (!localStorage.getItem("love")) {
    localStorage.setItem("love", "");
  }

  let lovers = localStorage["love"].split(",").length > 1;
  useEffect(() => {
    if (lovers) {
      makeLove(true);
    }
  }, [lovers]);
  return (
    <div className={classes.app}>
      <CssBaseline />
      <Router>
        <Route path={`${process.env.PUBLIC_URL}/stage/:home/`}>
          <Stage makeLove={makeLove} />
        </Route>
        {!isStaged() && <Header love={love} />}
        {!isStaged() && (
          <Container className={classes.root + " App-container"}>
            <Switch>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                render={() => <Start makeLove={makeLove} />}
              />
              <Route path={`${process.env.PUBLIC_URL}/rentals`}>
                <Rentals makeLove={makeLove} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/hearts`}>
                <Hearts makeLove={makeLove} />
              </Route>
              <Route
                path={`${process.env.PUBLIC_URL}/agent/:agent/agency/:agency`}
              >
                <Agent makeLove={makeLove} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/agency/:agency`}>
                <Agency makeLove={makeLove} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/finance`}>
                <Finance />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/calculate`}>
                <Calculate />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/news`}>
                <News />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/privacy`}>
                <Privacy />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/found/:home/:agent`}>
                <Found makeLove={makeLove} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/found/:home/`}>
                <Found makeLove={makeLove} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/auth`}>
                <Auth />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/dashboard`}>
                <Settings makeLove={makeLove} />
              </Route>
            </Switch>
          </Container>
        )}
      </Router>
    </div>
  );
};

export const App = _App;
