import React from 'react';
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import {
  makeStyles,
  fade,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    textAlign: "left",
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
    //border: "1px solid grey",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    borderColor: "whitesmoke",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

//export default (props) => {
function _SearchBar(props) {
  const classes = useStyles();
  let isAgent = !!sessionStorage["userCode"];
  let agencyName = sessionStorage["agencyName"];
  let userName = sessionStorage["userName"];

  const scrollOnFocus = () => {
    document.querySelector("#veryTop").scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
  };

  let isWaiting = false;
  let searchString = "";
  let searchTimer = 1000;

  const handleSearch = (event) => {
    searchString = event.target.value;
    searchTimer += 200;
    if (!isWaiting) {
      isWaiting = true;
      setTimeout(() => {
        isWaiting = false;
        props.SearchString(searchString);
        console.log("Searching: ", searchString);
        //document.querySelector("#searchbox").select();
      }, searchTimer);
    }
  };
  return (
    <AppBar
      id={"searchcontainer"}
      position="static"
      color="primary"
      className={classes.searchRoot + " section-bg-reverse"}
    >
      <Toolbar className={classes.searchBar}>
        <Typography className={classes.title} variant="h6" noWrap>
          {isAgent
            ? `${userName}'s listings: ${props.listingCount}`
            : `${agencyName}: ${props.listingCount}`}
        </Typography>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            onFocus={scrollOnFocus}
            onChange={handleSearch}
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search", "id" : "searchbox" }}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
}
export const SearchBar = React.memo(_SearchBar);
