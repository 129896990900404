import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  mobile: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  desktop: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  loved: {
    color: "red",
    height: "16px",
    position: "relative",
    top: "3px",
  },
  menuButton: {
    color: "black",
  },
  anchors: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
    fontWeight: "bold",
  },
  heartAnchor: {
    textDecoration: "none",
    color: "crimson",
    fontWeight: "bold",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export default function Links(props) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState({
    open: false,
    element: null,
    path: window.location.pathname,
  });

  const handleClick = (event) => {
    setAnchorEl({
      ...anchorEl,
      open: !!event.currentTarget,
      element: event.currentTarget,
    });
  };

  const handleClose = (path) => {
    setAnchorEl({ open: false, element: null, path: path });
  };

  const [open, setOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  const SignOut = (Action) => {
    if (Action === "ForNow") sessionStorage.clear();
    if (Action === "Forever") {
      sessionStorage.clear();
      localStorage.clear();
    }
    handleDialogClose();
    window.location = `${process.env.PUBLIC_URL}/`;
  };

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleDialogClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <div>
      <Dialog
        onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
          You are about to sign out.
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Choose "For Now" if you plan on returning as the same user in future
            or "Forever" if you wish sign on as a different user or plan on
            abandoning your current device.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              SignOut("Forever");
            }}
            color="secondary"
          >
            Forever
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={() => {
              SignOut("ForNow");
            }}
            color="primary"
          >
            For Now
          </Button>
        </DialogActions>
      </Dialog>
      {/* Mobile First */}
      <Typography className={classes.mobile} edge="end">
        <IconButton
          edge="end"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={handleClick}
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl.element}
          keepMounted
          open={Boolean(anchorEl.open)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleClose("/");
            }}
          >
            <Link to={`${process.env.PUBLIC_URL}/`} className={classes.anchors}>
              Home
            </Link>
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              handleClose("/rentals");
            }}
          >
            <Link
              to={`${process.env.PUBLIC_URL}/rentals`}
              className={classes.anchors}
            >
              Rentals
            </Link>
          </MenuItem> */}
          {props.hasLove ? (
            <MenuItem
              onClick={() => {
                handleClose("/hearts");
              }}
            >
              <Link
                to={`${process.env.PUBLIC_URL}/hearts`}
                className={classes.anchors}
              >
                Favourited
                <FavoriteIcon className={classes.loved} />
              </Link>
            </MenuItem>
          ) : (
            false
          )}
          <MenuItem
            onClick={() => {
              handleClose("/calculate");
            }}
          >
            <Link
              to={`${process.env.PUBLIC_URL}/calculate`}
              className={classes.anchors}
            >
              Cost Calculator
            </Link>
          </MenuItem>
          
          {sessionStorage.getItem("authToken") ? (
            [
              <MenuItem
                key={"settings"}
                onClick={() => {
                  handleClose("/settings");
                }}
              >
                <Link
                  to={`${process.env.PUBLIC_URL}/dashboard`}
                  className={classes.anchors}
                >
                  Dashboard
                </Link>
              </MenuItem>,
              <MenuItem
                key={"cheers"}
                className={classes.anchors}
                onClick={() => {
                  handleDialogOpen();
                  //SignOut();
                  //handleClose("/");
                }}
              >
                Sign out
              </MenuItem>,
            ]
          ) : (
            <MenuItem
              onClick={() => {
                handleClose("/settings");
              }}
            >
              <Link
                to={`${process.env.PUBLIC_URL}/auth`}
                className={classes.anchors}
              >
                Sign in
              </Link>
            </MenuItem>
          )}
        </Menu>
      </Typography>
      {/* Desktop Second */}
      <Typography className={classes.desktop} edge="end">
        <Button
          onClick={() => {
            handleClose("/");
          }}
        >
          <Link to={`${process.env.PUBLIC_URL}/`} className={classes.anchors}>
            Home
          </Link>
        </Button>
        {/* <Button
          onClick={() => {
            handleClose("/rentals");
          }}
        >
          <Link
            to={`${process.env.PUBLIC_URL}/rentals`}
            className={classes.anchors}
          >
            Rentals
          </Link>
        </Button> */}
        {props.hasLove ? (
          <Button
            onClick={() => {
              handleClose("/hearts");
            }}
          >
            <Link
              to={`${process.env.PUBLIC_URL}/hearts`}
              className={classes.heartAnchor}
            >
              Favourited
            </Link>
          </Button>
        ) : (
          false
        )}
        <Button
          onClick={() => {
            handleClose("/calculate");
          }}
        >
          <Link
            to={`${process.env.PUBLIC_URL}/calculate`}
            className={classes.anchors}
          >
            Cost Calculator
          </Link>
        </Button>

        {/* {anchorEl ? (
          anchorEl.path !== "/" ? (
            <Button
              onClick={() => {
                handleClose("/");
              }}
            >
              <Link
                to={`${process.env.PUBLIC_URL}/`}
                className={classes.anchors}
              >
                Home
              </Link>
            </Button>
          ) : (
            false
          )
        ) : (
          false
        )} */}
        {sessionStorage.getItem("authToken") ? (
          <>
            <Button
              onClick={() => {
                handleClose("/settings");
              }}
            >
              <Link
                to={`${process.env.PUBLIC_URL}/dashboard`}
                className={classes.anchors}
              >
                Dashboard
              </Link>
            </Button>
            <Button
              onClick={() => {
                handleDialogOpen();
                //SignOut();
                //handleClose("/");
              }}
            >
              Sign out
            </Button>
          </>
        ) : (
          <Button
            onClick={() => {
              handleClose("/settings");
            }}
          >
            <Link
              to={`${process.env.PUBLIC_URL}/auth`}
              className={classes.anchors}
            >
              Sign in
            </Link>
          </Button>
        )}
      </Typography>
    </div>
  );
}
