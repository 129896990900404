import React, { useState, useEffect, useCallback } from "react";
import Postman from "axios";
import { Section } from "../UI/Section";
import { devMode, ip } from "../bin/dev";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import InfiniteScroll from "react-infinite-scroller";
import { Listings } from "../UI/Listings";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
  },
  frame: {
    width: "100%",
    overflow: "auto",
    minHeight: "85vh",
    border: "none",
  },
}));

export default (props) => {
  const classes = useStyles();
  let fired = React.useRef(false);

  const defaultState = {
    search: {
      count: 0,
      display: "rentals",
      showActions: false,
      criteria: {
        attr: [],
        count: false,
        price: [],
        town: [],
        type: [],
      },
      suburbs: {},
      sort: { sortPrice: true },
      heading: "Rentals on HomeFinder",
    },
    hasMore: true,
    data: [],
    offset: 0,
    updating: true,
    resetOffset: false,
  };

  const [state, setState] = useState(defaultState);

  const [selected, setSelected] = useState(false);

  const heading = React.useRef(state.search.heading);

  (() => {
    //Maintains the heading
    let count = state.search.count;
    if (state.search.display === "rentals") {
      switch (true) {
        case count === 1: {
          heading.current = `One rental home found!`;
          break;
        }
        case count > 49: {
          heading.current = `Rental homes found: ${count}`; // Removed + to indicate more...
          break;
        }
        case count > 1: {
          heading.current = `Rental homes found: ${count}`;
          break;
        }
        default:
          heading.current = `No matching rental homes found`;
      }
    } else if (state.search.display === "new") {
      heading.current = state.search.heading;
    }
  })();

  let hasCriteria = React.useRef(false);

  const loadListings = useCallback(
    (
      criteria = state.search.criteria,
      sort = state.search.sort.sortPrice,
      search = state.search,
      resetOffset = false,
      incrementBy = 0
    ) => {
      hasCriteria.current = false;
      for (const k in criteria) {
        if (criteria[k]?.length > 0) {
          hasCriteria.current = true;
          break;
        }
      }
      //let url = `${ip}/listings?q=${hasCriteria.current ? "search" : "new"}`;
      let url = `${ip}/listings?q=rentals`;
      let auth = sessionStorage["authToken"];
      let devURL = "";
      if (auth) {
        devURL = devMode.isDevMode
          ? `&user=${devMode.devUserCode}&auth=${auth}`
          : "";
      }
      url += devURL;
      let post = {
        ...search,
        //count: state.search.dataCount,
        display: "rentals",//hasCriteria.current ? "search" : "new",
        sort: { sortPrice: sort },
        criteria: { ...criteria, sortPrice: sort },
        suburbs: { ...search.suburbs },
        offset: state.offset,
      };
      Postman.post(url, post).then((response) => {
        let dataCount = response.data.length > 0 ? response.data[0].COUNT : 0;
        setState({
          ...state,
          search: {
            ...search,
            count: dataCount,
            display: "rentals",
            showActions: hasCriteria.current ? true : false,
            sort: { sortPrice: sort },
            criteria: { ...criteria, sortPrice: sort },
            suburbs: { ...search.suburbs },
            heading: hasCriteria.current
              ? heading.current
              : "To Let on HomeFinder",
          },
          hasMore: !(state.offset + 10 >= dataCount),
          data:
            state.offset === 0
              ? [...response.data]
              : [...state.data, ...response.data],
          updating: false,
          offset: resetOffset ? 0 : state.offset + incrementBy,
        });
        if (fired.current) {
          fired.current = false;
        }
      });
    },
    // eslint-disable-next-line
    [state.offset]
  );

  useEffect(() => {
    loadListings();
  }, [loadListings]);

  const loadMoreListings = () => {
    console.log(
      !state.hasMore ? "That's it. I'm finished!" : "Wait, there's more."
    );
    if (state.hasMore) {
      if (!fired.current) {
        fired.current = true;
        loadListings(
          state.search.criteria,
          state.search.sort.sortPrice,
          state.search,
          false,
          10
        );
      }
      //setState({ ...state, offset: state.offset + 10, updating: true });
    } //state.search.count > state.offset
  };

  const toggleSort = () => {
    loadListings(
      state.search.criteria,
      !state.search.sort.sortPrice,
      state.search,
      true
    );

    scrollToResults();
  };

  const filterSuburbs = (suburbs) => {
    loadListings(
      state.search.criteria,
      state.search.sort.sortPrice,
      {
        ...state.search,
        suburbs,
      },
      true
    );
  };

  const scrollToResults = () => {
    setTimeout(() => {
      document.getElementById("divListings").scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    }, 500);
  };

  return (
    <Container className={classes.container}>
      <Section
        id={"divListings"}
        heading={heading.current}
        sortPrice={state.search.showActions ? toggleSort : false}
        showActions={state.search.showActions}
        sorted={
          !!state.search.sort.sortPrice ? !!state.search.sort.sortPrice : false
        }
        //filterSuburbs={state.search.count > 0 ? filterSuburbs : false}
        count={state.search.count}
        filterSuburbs={filterSuburbs}
        criteria={state.search.criteria}
        scrollToResults={scrollToResults}
      />
      <InfiniteScroll
        threshold={100}
        initialLoad={false}
        pageStart={0}
        loadMore={loadMoreListings}
        hasMore={state.hasMore}
        loader={
          <div className={classes.loader} key={0}>
            <div className="stage">
              <div className="dot-floating"></div>
            </div>
          </div>
        }
      >
        <Listings
          display={state.search.display}
          showSimilar={false}
          listings={state.data}
          makeLove={props.makeLove}
          canClick={true}
          setSelected={setSelected}
          selected={selected}
        />
      </InfiniteScroll>
    </Container>
  );
};
