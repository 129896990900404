import React, { useState, useEffect } from "react";
import {
  //Container,
  TextField,
  Typography,
  Grid,
  Button,
  FormControl,
  FormHelperText,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Postman from "axios";
import { ip } from "../bin/dev";

const useStyles = makeStyles((theme) => ({
  headerOffset: {
    [theme.breakpoints.down("sm")]: {
      //marginTop: -110,
    },
    [theme.breakpoints.up("md")]: {
      //marginTop: -135,
    },
    width: "100%",
  },
  space: {
    marginBottom: 10,
  },
  button: { width: "100%", maxWidth: 150 },
  R: { textAlign: "right" },
  L: { textAlign: "left" },
  C: { textAlign: "center" },
}));

export default () => {
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + " " + match[2] + " " + match[3];
    }
    return cleaned;
  }
  const handleChange = (event) => {
    if (!state.pinGenerated) {
      event.target.value = formatPhoneNumber(event.target.value);
    }
  };

  const defaultState = {
    subTitle: "Let's get you singed in!",
    message: "Start by entering your cell phone number below.",
    isError: false,
    errorMessage: null,
  };
  const [state, setState] = useState(defaultState);

  const countDigits = (event)=>{
    let digits = state.pinGenerated ? 4 : 12;
    if(event.target.value.length > digits){
      return false;
    }
    return true;
  }
  const isValid = (input) => {
    let digits = state.pinGenerated ? 4 : 12;
    if (input.length === 0) {
      setState({
        ...state,
        isError: true,
        errorMessage: "I got nothing!",
      });
      return false;
    }

    if (input.length < digits) {
      setState({
        ...state,
        isError: true,
        errorMessage: "That doesn't look right",
      });
      return false;
    }

    if (input.length === digits) {
      setState({
        ...defaultState,
        ...state,
      });
      return true;
    }
  };

  const handleClear = () => {
    state.pinGenerated
      ? (document.getElementById("pinNumber").value = "")
      : (document.getElementById("cellNumber").value = "");
    setState(defaultState);
  };

  const handleAuthentication = () => {
    if (localStorage.getItem("authToken")) {
      let request = {
        method: "post",
        url: `${ip}/otp?action=authenticate`,
        data: {
          token: localStorage.getItem("authToken"),
          userCode: localStorage.getItem("userCode"),
        },
      };
      //console.log(request);
      Postman(request).then((res) => {
        if (res.data[0].status === "authorised") {
          let data = res.data[0];
          sessionStorage.setItem(
            "authToken",
            localStorage.getItem("authToken")
          );
          sessionStorage.setItem("agencyCode", data.AGENCYCODE);
          sessionStorage.setItem("agencyName", data.AGENCY);
          sessionStorage.setItem("userName", data.NAME);
          if (data.USERCODE) {
            sessionStorage.setItem("userCode", data.USERCODE);
          }
          window.location = `${process.env.PUBLIC_URL}/dashboard`;
          return;
        } else {
          sessionStorage.clear();
          localStorage.removeItem("authToken");
          window.location = `${process.env.PUBLIC_URL}/auth`;
          return;
        }
      });
      return;
    }
    let input = state.pinGenerated
      ? document.getElementById("pinNumber").value
      : document.getElementById("cellNumber").value;
    if (isValid(input)) {
      let authURL = state.pinGenerated
        ? `${ip}/otp?action=validate`
        : `${ip}/otp?action=generate`;

      let pin = state.pinGenerated ? { otp: input } : {};
      let request = {
        method: "post",
        url: authURL,
        data: {
          ...pin,
          cellNumber: state.pinGenerated
            ? state.cellNumber
            : input.split(" ").join(""),
          authMode: "VIP",
        },
      };
      Postman(request)
        .then((res) => {
          if (res.data.status === "success") {
            document.getElementById("cellNumber").value = "";
            setState({
              subTitle: "Check your phone!",
              message: "The OTP number was sent to your phone.",
              isError: false,
              errorMessage: "Enter your One-Time-Pin",
              pinGenerated: true,
              cellNumber: input.split(" ").join(""),
            });
            localStorage.setItem("agencyCode", res.data.agencyCode);
            if (res.data.userCode) {
              localStorage.setItem("userCode", res.data.userCode);
            }
          }
          if (res.data.status === "validated") {
            console.log(res.data);
            document.getElementById("pinNumber").value = "";
            setState({
              ...state,
              subTitle: "Success!",
              message: "It's where the heart is...",
              isError: false,
              errorMessage: "",
              pinGenerated: true,
              isValidated: true,
            });
            localStorage.setItem("authToken", res.data.token);
            window.location = `${process.env.PUBLIC_URL}/dashboard`;
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status === 403) {
            setState({
              ...state,
              message: `RNS does not have this number listed
                    as the shareholder primary contact.
                    Please make sure this number is correct or
                    contact RNS if you wish to update your details
                    `,
              isError: true,
              errorMessage: "Number not authorised.",
            });
          } else if (error.response.status === 401) {
            setState({
              ...state,
              message: `The pin number is incorrect. 
              Please check your SMS to ensure that you 
              have entered it correctly.`,
              isError: true,
              errorMessage: "OTP entered is not correct.",
            });
          } else if (error) {
            setState({
              ...state,
              message: error,
              isError: true,
              errorMessage: "Unknown Error",
            });
          } else {
            setState({
              ...state,
              message: "This is embarrising :(",
              isError: true,
              errorMessage: "Unknown Error",
            });
          }
        });
    }
  };

  const handleErrorColor = (isError) => {
    return isError ? "error" : "initial";
  };

  const classes = useStyles();

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      handleAuthentication();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.headerOffset}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" color="initial">
            Authentication:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="initial">
            {state.subTitle}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            color={handleErrorColor(state.isError)}
            style={{ maxWidth: 480, margin: "0 auto" }}
          >
            {state.message}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.space}>
          <form autoComplete="off">
            <FormControl>
              {state.pinGenerated ? (
                <TextField
                  type="tel"
                  error={state.isError}
                  id="pinNumber"
                  onKeyUp={countDigits}
                  onChange={handleChange}
                  inputProps={{ style: { textAlign: "center" }, maxLength: 4 }}
                  disabled={state.isValidated}
                />
              ) : (
                <TextField
                  type="tel"
                  error={state.isError}
                  id="cellNumber"
                  onKeyUp={countDigits}
                  onChange={handleChange}
                  inputProps={{ style: { textAlign: "center" }, maxLength: 12}}
                />
              )}
              <FormHelperText className={classes.C}>
                {state.errorMessage}
              </FormHelperText>
            </FormControl>
          </form>
        </Grid>
        <Grid item xs={6} md={12}>
          {state.pinGenerated ? (
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={handleAuthentication}
              disabled={state.isValidated}
            >
              Authenticate
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleAuthentication}
            >
              Send OTP
            </Button>
          )}
        </Grid>
        <Grid item xs={6} md={12}>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            onClick={handleClear}
            disabled={state.isValidated}
          >
            Start over
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
