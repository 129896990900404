import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Container,
  Fade,
  CircularProgress,
  Popover,
  Paper,
} from "@material-ui/core";
import Postman from "axios";
import { Listings } from "../UI/Listings";
import { ip } from "../bin/dev";
import AppBar from "@material-ui/core/AppBar";
import SearchIcon from "@material-ui/icons/Search";
//import Love from "./Love";
import { makeStyles } from "@material-ui/core/styles";

// eslint-disable-next-line
String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  footRoot: {
    width: "100%",
    top: "auto",
    bottom: "-1px",
    color: "white!important",
    backgroundColor: theme.palette.secondary.dark,
  },
  back: {
    color: "white!important",
    position: "absolute",
    left: 0,
    zIndex: 1,
  },
  love: {
    color: "white!important",
  },
  forward: {
    color: "white!important",
    position: "absolute",
    right: 0,
    zIndex: 1,
  },
  pagerFeedback: {
    padding: "13px",
    fontSize: "x-large",
    lineHeight: "1",
    textAlign: "center",
  },
  paperBG: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  line: {
    border: "3px solid #09222c",
  },
  item: {
    backgroundColor: "inherit",
    // "&:nth-child(even)": {
    //   backgroundColor: "#09222c10",
    // },
    transition: "1s",
    "&:hover": {
      backgroundColor: "#09222c40",
      transition: "1s",
    },
  },
  similarFrame: {
    padding: 10,
    marginBottom: 25,
    borderRadius: 10,
    backgroundColor: "#00a5b159",
  },
  similarFrameHeading: {
    color: theme.palette.primary.main,
    marginBottom: 10,
  },
  AppBar: {
    position: "fixed",
    top: 0,
    left: 0,
    //height: 50,
    backgroundColor: theme.palette.secondary.dark,
  },
  button: {
    height: 50,
    borderColor: "whitesmoke",
    color: "whitesmoke"

  },
  Offset: {
    marginTop: 74,
    textAlign: "left",
  },
  SearchIcon: {
    color: theme.palette.grey[100],
  },
  viewControlTop: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  viewControlBottom: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  shareOnWhatsApp: {
    textAlign: "center",
    backgroundColor: "#6be777",
    [theme.breakpoints.down("xs")]: {
      width: "100%", // none for desktops
    },
  },
  shareContainer: {
    textAlign: "center",
    // [theme.breakpoints.up("sm")]: {
    //   textAlign: "right",
    //   position: "relative",
    //   top: "54px",
    //   right: "10px",
    //   zIndex: 1,
    // },
  },
  imageIcon: {
    width: "93%",
  },
  iconRoot: {
    textAlign: "center",
    color: "white",
  },
  Logo: {
    maxWidth: 300,
    maxHeight: 75,
  },
}));
// eslint-disable-next-line
export default (props) => {
  const [attr, setAttr] = useState();
  // eslint-disable-next-line
  let action = "next";
  let listingAgent = props.data.LISTINGAGENT;
  if (attr) {
    action = attr.Action;
  }

  useEffect(() => {
    let post = {
      listingID: {
        lid: props.REF,
      },
    };

    let LogoURL = `https://www.rnslive.co.za/MemberPages/logos/${props.REF.substr(
      0,
      4
    )}.png`;
    const doTheThing = () => {
      let postSimilar = {
        criteria: {
          agencyCode: data.AGENCYCODE,
          userCode: data.AGENTID
          // town: [data.TOWN],
          // type: [data.CLASS],
          // price: [common.priceRange(data.PRICE)],
          // attr: [],
        },
      };
      Postman.post(`${ip}/listings?q=agent`, postSimilar).then(
        (res) => {
          similarListings = props.showSimilar ? res.data : null;
          Postman.post(`${ip}/agent`, { agent: data.AGENTID }).then(
            (response) => {
              let agent = response.data[0];
              Postman.post(
                "https://www.rnslive.co.za/wsRNS.asmx/Attributes",
                post
              ).then((response) => {
                let description = JSON.parse(response.data.d)[0];
                setAttr({
                  Logo: LogoURL,
                  Interior: description.Interior,
                  Exterior: description.Exterior,
                  //Pager: props.pageData ? page(action, props.REF) : [props.REF],
                  Listing: [data],
                  similar: similarListings,
                  Agent: agent,
                  Ready: true,
                  Action: action,
                });
              });
            }
          );
        }
      );
    };
    Postman.get(LogoURL).catch((err) => {
      console.log("Swopping out logo :)");
      LogoURL = `${process.env.PUBLIC_URL}/assets/home-finder-generic-logo.png`;
    });
    let similarListings;
    let data = props.data;
    if (!data) {
      Postman.post(`${ip}/listings?q=single`, post).then((response) => {
        data = response.data[0];
        doTheThing();
      });
    } else {
      doTheThing();
    }
    // eslint-disable-next-line
  }, [props.REF]);

  const classes = useStyles();

  if (attr) {
    return (
      <Container className={classes.container}>
        {props.pageData ? (
          <AppBar className={classes.AppBar + " section-bg-reverse"} position="static">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button
                  className={classes.button}
                  variant="outlined"
                  color="secondary"
                  fullWidth={true}
                  startIcon={<SearchIcon />}
                  onClick={() => {
                    props.setSelected(false);
                    let arrLoved = localStorage.getItem("love").split(",");
                    function loved(home) {
                      let iLove = arrLoved.findIndex((el) => el === home);
                      return iLove > -1;
                    }
                    setTimeout(() => {
                      for (const item of document.querySelectorAll(
                        ".hartjies"
                      )) {
                        if (loved(item.id.substr(0, 10))) {
                          item.setAttribute("style", "color:red");
                        } else {
                          item.setAttribute("style", "color:grey");
                        }
                      }
                    }, 10);
                  }}
                >
                  BACK
                </Button>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "center" }}>
              <Button
                  className={classes.button}
                  variant="outlined"
                  color="secondary"
                  fullWidth={true}
                  onClick={() => {
                    document.querySelector("#similar").scrollIntoView({
                      block: "start",
                      inline: "nearest",
                      behavior: "smooth",
                    });
                  }}
                >
                  VIEW MORE
                </Button>
              </Grid>
            </Grid>
          </AppBar>
        ) : (
          false
        )}
        <Fade
          in={attr.Ready}
          style={{ transformOrigin: "0 0 0" }}
          {...(attr.Ready ? { timeout: 300 } : {})}
        >
          <Grid container spacing={1}className={classes.Offset} >
            {<Grid item xs={12}>
              <Listings
                display= {"single"}
                listings={attr ? { data: attr.Listing } : null}
                hideThumb={true}
                advertise={props.advertise}
                expanded={true}
                makeLove={props.makeLove}
                selected={props.selected}
                setSelected={props.setSelected}
              />
            </Grid>}
            <Grid item xs={12}>
              {attr.similar ? (
                attr.similar.length > 0 ? (
                  <div id={"similar"} className={classes.viewControlBottom}>
                    <div className={classes.similarFrame}>
                      <Typography
                        variant="h6"
                        className={classes.similarFrameHeading}
                      >
                        More homes offered by {listingAgent}
                      </Typography>
                      <Listings
                        display={"hero-value"}
                        listings={{ data: [...attr.similar] }}
                        canClick={true}
                        makeLove={props.makeLove}
                        selected={props.selected}
                        setSelected={props.setSelected}
                      />
                    </div>
                  </div>
                ) : (
                  false
                )
              ) : (
                false
              )}
            </Grid>
          </Grid>
        </Fade>
      </Container>
    );
  }
  return (
    <Popover
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      open={true}
      anchorEl={document.querySelector("body")}
    >
      <Paper
        style={{
          width: window.innerWidth,
          height: window.innerHeight - 32,
          display: "table-cell",
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        <CircularProgress />
        <br />
        Loading...
      </Paper>
    </Popover>
  );
};
