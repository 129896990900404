import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
  },
  frame: {
    width: "100%",
    overflow: "auto",
    minHeight: "85vh",
    border: "none",
  },
}));

export default (params) => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Typography component="div" style={{ minHeight: "100vh" }}>
        <div style={{ textAlign: "center", maxWidth: 980, margin: "0 auto" }}>
          <iframe title="ooba Bond Indicator" className={classes.frame} src="https://indicator.ooba.co.za/?var1=R8S&var2=0&var3=RNS-OBI&var4=HomeFinder">
            Iframes not supported
          </iframe>
        <a href="https://indicator.ooba.co.za/?var1=R8S&var2=0&var3=RNS-OBI&var4=HomeFinder" target="_blank" rel="noopener noreferrer">
          Click or tap here</a> to access this tool directly...
        </div>
      </Typography>
    </Container>
  );
};
