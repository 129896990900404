import React, { useState, useEffect } from "react";
import { Grid, Paper } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { ip } from "../bin/dev";
import Postman from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: "center",
    marginBottom: "8px",
  },
  ad: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 90,
    maxWidth: 360,
    margin: "0 auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
}));

let url = "https://www.homefinder.co.za/ads/hf-";

export default (props) => {
  const classes = useStyles();
  let display = "HOME";
  const [ads, setAds] = useState({});
  useEffect(() => {
    Postman.post(`${ip}/ads`).then((res) => {
      setAds([...res.data]);
    });
  }, []);

  if (props.search.criteria.town.length > 0) {
    display = props.search.criteria.town[props.search.criteria.town.length - 1];
  }
  if (display) {
    display = display.toUpperCase();
  }
  if (ads.length > 0) {
    return (
      <Paper className={"section-bg-reverse"} elevation={1}>
        {" "}
        <Grid container spacing={2}>
          {ads.map((ad, i) => {
            let isPin = ad.PIN.findIndex((el) => el === display) > -1;
            if (isPin) {
              return (
                <Grid item xs={12} sm={4} key={`pin-${i}`}>
                  {/* eslint-disable-next-line */}
                  <a href={ads[i].URL} target="_blank">
                    <div
                      className={classes.ad}
                      style={{
                        backgroundImage: `url(${url}${ads[i].BANNER})`,
                      }}
                    ></div>
                  </a>
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      </Paper>
    );
  }
  return null;
};
