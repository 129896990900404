import React, { useState, useEffect } from "react";
import { ListingsByRef } from "../Components/ListingsByRef";
import Agent from "../Components/Agent";
import { useParams } from "react-router-dom";
import Postman from "axios";
import { ip } from "../bin/dev";

export default (props) => {
  let { agent, agency } = useParams();
  let agencyCode = agency;
  let userCode = agent;

  const [state, setState] = useState();

  useEffect(() => {
    Postman.post(`${ip}/agent`, {
      agent: userCode,
    }).then((response) => {
      setState({ data: response.data[0] });
    });
  }, [userCode]);

  let criteria = {
    agencyCode,
    userCode,
    action: "agent",
  };

  if (state) {
    return (
      <>
        <Agent
          data={state.data}
          horizontal={true}
          key={userCode}
          aid={userCode}
          image={`https://www.rnslive.co.za/img/Agent_Images/${userCode}_w200.jpg`}
          //listingImage={props.listingImage}
          //listingRef={props.REF}
          agent={`${state.data.FIRST_NAME} ${state.data.LAST_NAME}`}
          agency={state.data.AGENCY_NAME}
          phone={state.data.CELL_NUMBER}
          //socialShare={socialShare}
          //socialLink={socialLink}
        />
        <ListingsByRef 
        criteria={criteria} 
        makeLove={props.makeLove} 
        display={"agent"} //Needed for logging events
        />
      </>
    );
  }
  return null;
};
