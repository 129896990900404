import React from "react";
import { ip, devMode } from "../bin/dev";
import Postman from "axios";

function _Log(props) {
  let url = `${ip}/log`;
  let post = {
    action: "Log",
    listing_id: props.listing_id,
    conversion_event: props.conversion_event,
    conversion_meta: props.conversion_meta,
  };
  
  if(!devMode.isDevMode){
    if (props.conversion_meta !== "single" || props.conversion_event === "loved") {
      Postman.post(url, post).then(() => {});
    }
  }

  if(props.conversion_event === "View"){
    window.gtag_report_conversion("9pmjCM71wokCEOTlyNMD");
  }
  if(props.conversion_event === "loved"){
    window.gtag_report_conversion("0woLCMmAxIkCEOTlyNMD");
  }
  if(props.conversion_meta === "phone"){
    window.gtag_report_conversion("p4kpCKr764kCEOTlyNMD");
  }
  if(props.conversion_meta === "mail"){
    window.gtag_report_conversion("BDajCIygxIkCEOTlyNMD");
  }
  if(props.conversion_meta === "chat"){
    window.gtag_report_conversion("kKLnCKXX7YkCEOTlyNMD");
  }
  if(props.conversion_meta === "whatsapp"){
    window.gtag_report_conversion("E--fCLPChIACEOTlyNMD");
  }
  
  return true;
}
export const Log = React.memo(_Log);
