import { useState, useEffect, useCallback, useRef } from "react";
import { Section } from "../UI/Section";
import { SearchUI } from "../UI/Search";
import InfiniteScroll from "react-infinite-scroller";
import { Listings } from "../UI/Listings";
//import Agents from "../UI/FeatureAgents";
//import Grid from "@material-ui/core/Grid";
import Postman from "axios";
import { devMode, ip } from "../bin/dev";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../UI/Footer";
import Ads from "../Components/Ads";
import { Capacitor } from "@capacitor/core";

const useStyles = makeStyles((theme) => ({
  loader: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const _Start = (props) => {
  const classes = useStyles();
  let fired = useRef(false); //Variable used to prevent loadMoreListings from being too eager.

  const defaultState = {
    search: {
      count: 0,
      display: "new",
      showActions: false,
      criteria: {
        town: [],
        type: [],
        price: [],
        attr: [],
        rentalSearch: false,
        count: false,
      },
      suburbs: {},
      sort: { sortPrice: true },
      heading: "Today on HomeFinder",
    },
    hasMore: true,
    data: [],
    offset: 0,
    resetOffset: false,
  };

  const [state, setState] = useState(defaultState);

  const [selected, setSelected] = useState(false);

  const heading = useRef(state.search.heading);

  (() => {
    //Maintains the heading
    let count = state.search.count;
    if (state.search.display === "search") {
      switch (true) {
        case count === 1: {
          heading.current = `One home found!`;
          break;
        }
        case count > 49: {
          heading.current = `Homes found: ${count}`; // Removed + to indicate more...
          break;
        }
        case count > 1: {
          heading.current = `Homes found: ${count}`;
          break;
        }
        default:
          heading.current = `No matching homes found`;
      }
    } else if (state.search.display === "new") {
      heading.current = state.search.heading;
    }
  })();

  let hasCriteria = useRef(false);

  const loadListings = useCallback(
    (
      criteria = state.search.criteria,
      sort = state.search.sort.sortPrice,
      search = state.search,
      resetOffset = false,
      incrementBy = 10
    ) => {
      hasCriteria.current = false;
      for (const k in criteria) {
        if (criteria[k]?.length > 0) {
          hasCriteria.current = true;
          break;
        }
      }
      let url = `${ip}/listings?q=${hasCriteria.current ? "search" : "new"}`;
      let auth = sessionStorage["authToken"];
      let devURL = "";
      if (auth) {
        devURL = devMode.isDevMode
          ? `&user=${devMode.devUserCode}&auth=${auth}`
          : "";
      }
      url += devURL;
      let request = {
        ...search,
        display: hasCriteria.current ? "search" : "new",
        sort: { sortPrice: sort },
        criteria: { ...criteria, sortPrice: sort },
        suburbs: { ...search.suburbs },
        offset: resetOffset ? 0 : state.offset,
      };
      Postman.post(url, request).then((response) => {
        let dataCount = response.data.length > 0 ? response.data[0].COUNT : 0;
        setState({
          ...state,
          search: {
            ...search,
            count: dataCount,
            display: hasCriteria.current ? "search" : "new",
            showActions: hasCriteria.current ? true : false,
            sort: { sortPrice: sort },
            criteria: { ...criteria, sortPrice: sort },
            suburbs: { ...search.suburbs },
            heading: hasCriteria.current
              ? heading.current
              : "Today on HomeFinder",
          },
          hasMore: request.offset + incrementBy <= dataCount,
          data:
            request.offset === 0
              ? [...response.data]
              : [...state.data, ...response.data],
          offset: resetOffset ? 0 : request.offset + incrementBy,
        });
        if (fired.current) {
          fired.current = false;
        }
      });
    },
    // eslint-disable-next-line
    [state.offset]
  );

  useEffect(() => {
    loadListings();
    // eslint-disable-next-line
  }, []);


  const loadMoreListings = () => {
    // console.log(
    //   !state.hasMore ? "That's it. I'm finished!" : "Wait, there's more."
    // );
    if (state.hasMore) {
      if (!fired.current) {
        fired.current = true;
        loadListings(
          state.search.criteria,
          state.search.sort.sortPrice,
          state.search,
          false,
          10
        );
      }
    }
  };

  const StartOver = () => {
    loadListings(
      defaultState.search.criteria,
      defaultState.search.sort.sortPrice,
      defaultState.search,
      true,
      10
    );
  };

  const toggleSort = () => {
    loadListings(
      state.search.criteria,
      !state.search.sort.sortPrice,
      state.search,
      true
    );

    scrollToResults();
  };

  const filterSuburbs = (suburbs) => {
    loadListings(
      state.search.criteria,
      state.search.sort.sortPrice,
      {
        ...state.search,
        suburbs,
      },
      true
    );
  };

  const scrollToResults = () => {
    setTimeout(() => {
      document.getElementById("resultset").scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    }, 500);
  };

  return (
    <>
      {heading.current.startsWith("Today") || (
        <Section
          id={"divListings"}
          heading={heading.current}
          sortPrice={state.search.showActions ? toggleSort : false}
          showActions={state.search.showActions}
          sorted={
            !!state.search.sort.sortPrice
              ? !!state.search.sort.sortPrice
              : false
          }
          //filterSuburbs={state.search.count > 0 ? filterSuburbs : false}
          count={state.search.count}
          filterSuburbs={filterSuburbs}
          criteria={state.search.criteria}
          scrollToResults={scrollToResults}
        />
      )}
      <SearchUI
        fetch={loadListings}
        scrollToResults={scrollToResults}
        search={state.search}
        makeLove={props.makeLove}
        StartOver={StartOver}
        selected={selected}
        setSelected={setSelected}
        towns={["Boksburg","Benoni"]}
      />

      {Capacitor.getPlatform() === "android" ||
        Capacitor.getPlatform() === "ios" || <Ads search={state.search} />}
      {heading.current.startsWith("Today") && (
        <Section
          id={"divListings"}
          heading={heading.current}
          sortPrice={state.search.showActions ? toggleSort : false}
          showActions={state.search.showActions}
          sorted={
            !!state.search.sort.sortPrice
              ? !!state.search.sort.sortPrice
              : false
          }
          //filterSuburbs={state.search.count > 0 ? filterSuburbs : false}
          count={state.search.count}
          filterSuburbs={filterSuburbs}
          criteria={state.search.criteria}
          scrollToResults={scrollToResults}
        />
      )}
      <InfiniteScroll
        id="resultset"
        threshold={250}
        initialLoad={false}
        pageStart={0}
        loadMore={loadMoreListings}
        hasMore={state.hasMore}
        loader={
          <div className={classes.loader} key={0}>
            <div className="stage">
              <div className="dot-floating"></div>
            </div>
          </div>
        }
      >
        <Listings
          display={state.search.display}
          showSimilar={false}
          listings={state.data}
          makeLove={props.makeLove}
          canClick={true}
          setSelected={setSelected}
          selected={selected}
        />
      </InfiniteScroll>

      {/* <Section id={"divAgents"} heading={"Top Listing Agents"} />
      <Grid container>
        <Agents criteria={state.search.criteria} />
      </Grid> */}
      <Footer />
    </>
  );
};

export const Start = _Start;
