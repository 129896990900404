import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Social from "./Social";
import Icon from "@material-ui/core/Icon";
import EmailIcon from "@material-ui/icons/Email";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Postman from "axios";
import { Log } from "./Log";
import "../bin/common";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 140,
    margin: "0 auto",
    marginBottom: 10,
    marginTop: 10,
  },
  rootAlt: {
    display: "flex",
    marginBottom: 10,
    textAlign: "left",
    marginTop: 10,
  },
  media: {
    width: 140,
    height: 200,
    cursor: "pointer",
  },
  mediaAlt: {
    width: 175,
    cursor: "pointer",
    //height: 200,
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  shareOnWhatsApp: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    [theme.breakpoints.up("md")]: {
      display: "block", // none for desktops
    },
  },
  content: {
    flex: "1 0 auto",
  },
  imageIcon: {
    height: "100%",
  },
  iconRoot: {
    textAlign: "center",
  },
}));

function Cards(props) {
  let data = props.data;
  let agentID = data ? data.AID : props.agentID;
  let phone = data ? data.CELL_NUMBER : props.phone;
  let email = data ? data.EMAIL_ADDRESS : null;
  let agent = data
    ? `${data.FIRST_NAME} ${data.LAST_NAME}`.toProperCase()
    : props.agent;
  let agency = data ? data.AGENCY_NAME : props.agency;
  let agentImage = `https://www.rnslive.co.za/img/Agent_Images/${agentID}_w200.jpg`;

  const classes = useStyles();

  const [contact, setContact] = useState({
    agentImage,
    display: phone ? `${phone.substr(0, 3)}...` : false,
    link: "#",
    phone: phone ? `27${phone.substr(1, phone.length)}` : false,
    socialOpen: false,
    email,
    isMale: data ? (data.TITLE === "Mr." ? true : false) : false,
  });
  useEffect(() => {
    Postman.get(agentImage).catch((err) => {
      //console.log("Swopping 404 User Image :)")
      setContact({
        ...contact,
        agentImage: `${process.env.PUBLIC_URL}/assets/home-finder-jane-doe.png`,
      });
    });
    // eslint-disable-next-line
  }, [props.aid]);

  function log() {
    return (
      <Log
        listing_id={props.listingRef}
        conversion_event={"contact"}
        conversion_meta={contact?.log}
      />
    );
  }

  const showPhone = (e) => {
    //Record action here.
    setContact({
      ...contact,
      display: phone,
      link: `tel:+${contact.phone}`,
      log: "phone",
    });
  };

  const handleClose = () => {
    setContact({ ...contact, socialOpen: false });
  };

  const handleOpen = (mode) => {
    setContact({ ...contact, socialOpen: true, mode });
  };

  const handleClick = () => {
    let url = `${process.env.PUBLIC_URL}/agent/${agentID}/agency/${
      data ? data.AGENCY_CODE : props.agencyCode
    }`;
    window.location = url;
  };

  if (props.horizontal) {
    return (
      <>
        <Card className={classes.rootAlt}>
          <div style={{ width: "100%" }}>
            <CardContent className={classes.content}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                className="featureAgent"
              >
                {agent}
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                component="p"
                className="featureAgent"
              >
                {agency}
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="p"
                className="featureAgent"
              >
                {/* eslint-disable-next-line */}
                Phone:{" "}
                <Link href={contact.link} onClick={showPhone}>
                  {contact.display}
                  {contact.log === "phone" ? log() : false}
                </Link>
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="p"
                className="featureAgent"
              >
                <Button
                  variant="text"
                  color="default"
                  onClick={() => {
                    handleOpen("chat");
                  }}
                  startIcon={
                    <Icon className={classes.iconRoot}>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/icons/wa-icon.svg`}
                        className={classes.imageIcon}
                        alt="WhatsApp Icon"
                      />
                    </Icon>
                  }
                >
                  {`Chat with ${agent.substr(0, agent.indexOf(" ", 0))}`}
                </Button>
                {data && (
                  <Button
                    variant="text"
                    color="default"
                    onClick={() => {
                      handleOpen("mail");
                    }}
                    startIcon={<EmailIcon />}
                  >
                    {`Send ${contact.isMale ? "him" : "her"} an Email`}
                  </Button>
                )}
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="p"
                className="featureAgent"
              ></Typography>
            </CardContent>
          </div>
          <CardMedia
            className={classes.mediaAlt}
            image={contact?.agentImage}
            title={agent}
            onClick={handleClick}
          />
        </Card>
        <Social
          socialOpen={contact.socialOpen}
          handleClose={handleClose}
          agent={agent}
          agentData={data}
          altAgent={props.altAgent}
          socialShare={props.socialShare}
          socialLink={props.socialLink}
          listingImage={props.listingImage}
          listingRef={props.listingRef}
          phone={contact.phone}
          email={contact.email}
          mode={contact.mode}
        />
      </>
    );
  } else {
    return (
      <Card className={classes.root}>
        <CardMedia
          className={classes.media}
          image={contact?.agentImage}
          title={agent}
          onClick={handleClick}
        />
        <CardContent className="featureAgent">
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            className="featureAgent"
          >
            {agent}
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            className="featureAgent"
          >
            {agency}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

export default Cards;
