import React from "react";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";

export default (props) => {
  const useStyles = makeStyles((theme) => ({
    icon: {
      height: 24,
      filter: "invert(50%)",
      cursor: "pointer",
    },
    conversions: {
      cursor: "pointer",
      zIndex:2,
    },
  }));
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  const handleTooltipOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
    if (event.type === "click" && props.fetchConversions) {
      props.fetchConversions();
    }
  };
  const classes = useStyles();
  if (props.qty > 0 || props.qty === "T") {
    return (
      <>
        {!!props?.display || (
          <Grid item>
            <Tooltip
              title={props.alt}
              arrow
              onClose={handleTooltipClose}
              open={open}
              TransitionComponent={Zoom}
            >
              <Badge
                badgeContent={props.qty === "T" ? 0 : props.qty}
                color={"primary"}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                onClick={handleTooltipOpen}
                onMouseOver={handleTooltipOpen}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/${props.icon}.svg`}
                  alt={props.alt}
                  className={classes.icon}
                />
              </Badge>
            </Tooltip>
          </Grid>
        )}
        {!!props?.display && (
          <Tooltip
            title={props.alt}
            arrow
            onClose={handleTooltipClose}
            open={open}
            TransitionComponent={Zoom}
          >
            <Badge
              badgeContent={props.qty === "T" ? 0 : props.qty}
              color={!!props.display ? "error" : "primary"}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              onClick={handleTooltipOpen}
              onMouseOver={handleTooltipOpen}
              className={classes.conversions}
              max={9999}
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/${props.icon}.svg`}
                alt={props.alt}
                className={classes.icon}
              />
            </Badge>
          </Tooltip>
        )}
      </>
    );
  }
  return null;
};
