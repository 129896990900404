import React from "react";
import Details from "../Components/Details";
import Footer from "../UI/Footer";
import { useParams } from "react-router-dom";

export default (props) => {
  let { home } = useParams();
  let { agent } = useParams();
  const LinkHomeToAgent = (homeLink, agentLink) => {
    if (localStorage["linkedHomes"] && localStorage["linkedAgents"]) {
      clearLinks(); //Fix the confusion
      // let homesLinked = localStorage["linkedHomes"].split(",");
      // let agentsLinked = localStorage["linkedAgents"].split(",");

      // if (homesLinked.length !== agentsLinked.length) {
      //   clearLinks();
      // } else {
      //   let linkedHomeIndex = homesLinked.findIndex(
      //     (linkedHome) => linkedHome === homeLink
      //   );
      //   if (linkedHomeIndex > -1) {
      //    return agentsLinked[linkedHomeIndex];
      //   } else {
      //     homesLinked.push(homeLink);
      //     localStorage.setItem("linkedHomes", homesLinked);
      //     agentsLinked.push(agentLink);
      //     localStorage.setItem("linkedAgents", agentsLinked);
      //     return agentLink;
      //   }
      // }
    } else {
      clearLinks();
    }
    function clearLinks() {
      localStorage.removeItem("linkedHomes");
      localStorage.removeItem("linkedAgents");
      if (homeLink && agentLink) {
        localStorage.setItem("linkedHomes", homeLink);
        localStorage.setItem("linkedAgents", agentLink);
      }
    }
  };
  if (home && agent) {
    LinkHomeToAgent(home, agent);
  }
  return (
    <div>
      <h3
        style={{
          textAlign: "right",
          marginTop: 21,
          paddingTop: 10,
          fontFamily: "monospace",
        }}
      >
        REF# {home}
      </h3>
      <Details REF={home} AGENTID={agent} makeLove={props.makeLove} LinkHomeToAgent={LinkHomeToAgent} />
      <Footer />
    </div>
  );
};
