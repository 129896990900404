
import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
  },
}));

export default () => {
  const classes = useStyles();
  return (
    <Container maxWidth={"sm"} className={classes.container}>
      <Typography component="div" style={{textAlign:"justify"}}>
<h1>Privacy Policy</h1>
<p>
    As part of our ongoing commitment to
        protect your personal information and ensure that you have access to trusted, professional Real Estate marketing
        services, we would like to assure you that data privacy is our top priority.
</p>
<p>
    Please note that we may share your
        personal information within Residential Network Systems (PTY) Ltd Group from time to time, as well as with third
        parties that act on our behalf.
</p>
<p>
    We at Residential Network Systems (PTY)
        Ltd Group recognize the importance of protecting the privacy of certain information or data collected about our
        users, Estate Agents and clients / customers. 
</p>
<p>
    This Privacy Policy discloses what
        information we gather, how we use it and how to correct or change it.
</p>
<p>
    <strong>Collection of
            Information</strong>
</p>
<p>
    Residential Network Systems (PTY) Ltd
        Group only collects personal information that is necessary for business purposes. We collect personal
        information by requesting it from you. Generally this occurs when you register on one of our websites, request
        information from us or participate in activities on our websites. At these times, you give contact information
        which may include name, address and e-mail address. When you purchase or utilise products and/or services from
        us, we will note, for example, your contact information, the type of services or products purchased, and the
        date of purchase. When other information such as, gender, date of birth, number of children, and product and/or
        service preferences, is associated with personal information, this also becomes personal information for the
        purposes of this Privacy Policy.
</p>
<p>
    <strong>Use of Internet Protocol
            (&quot;IP&quot;) Addresses</strong>
</p>
<p>
    An IP address is a unique number that
        allows your computer to be identified by web servers. This in turn allows us to identify and gather general
        information and data about you, such as the web pages you view. Residential Network Systems (PTY) Ltd Group
        collects IP addresses for the purposes of system administration, to report information to our business partners,
        and in order to track and audit the use of our own websites. When users request pages from Residential Network
        Systems (PTY) Ltd Group&apos;s websites, our servers log the user&apos;s IP addresses.
</p>
<p>
    <strong>Uses of Personal Information</strong>
</p>
<p>
    As a general rule, Residential Network
        Systems (PTY) Ltd Group does not, and will not, sell or rent your personal information. We will use and disclose
        your personal information to others as stated in this Privacy Policy. We may use Personal Information in many
        ways, including sending you information on products and/or services and promotions. We may share your Personal
        Information with third parties so that these third parties can send you offers and or promotional materials. We
        will always seek the consent of Members, additional users or their guests before disclosing data relating to
        them for the making of offers by third parties, by informing Members, additional users or their guests of an
        intention to process their data for such purposes and offering them a possibility to opt out of such use of
        their data, in a form or document returned to each of them or alternatively by notifying each of them, by
        telephone, fax or e-mail.<br/> <br/> We may use Personal Information to complete transactions requested
        by you on our web sites for example, or to send you communications either about your membership with us or about
        features of our websites, including future changes to this Privacy Policy. We may use Personal Information to
        monitor site traffic, to personalise the site and to send emails. We also use Your Personal Information for
        internal quality assurance purposes.<br/> <br/> Usually, Residential Network Systems (PTY) Ltd Group and
        its business partners use your information to operate the websites and to deliver services. Residential Network
        Systems (PTY) Ltd Group also uses your information to inform you about other products and/or services available
        from Residential Network Systems (PTY) Ltd Group from time to time.
</p>
<p>
    <strong>Sharing and disclosing information</strong>
</p>
<p>
    The following describes some of the ways
        that your Personal Information may be disclosed. This is not an exhaustive list but serves by way of
        illustration only.<br/> <br/> Business Partners: Residential Network Systems (PTY) Ltd Group provides
        Personal Information in order to facilitate a booking request or to generate leads to destination operators in
        connection with their offers. Many promotions also offer opportunities to request additional information from
        business partners. By requesting such information, you give Residential Network Systems (PTY) Ltd Group
        permission to transfer your Personal Information to the business partner for related
        use.<br/> <br/> Third Party and Aggregate Data: From time to time, you may be offered the opportunity to
        receive materials or special offers from third parties. If you receive information from these third parties,
        Residential Network Systems (PTY) Ltd Group will share your name, e-mail address and other Personal Information
        with such third parties.<br/> <br/> Other: Residential Network Systems (PTY) Ltd Group may disclose
        Personal Information if required to do so by law, court order or as requested by a governmental or law
        enforcement authority or in special cases when we have reason to believe that disclosing this Personal
        Information is otherwise necessary or advisable.<br/> <br/> Controlling the use of your personal
        information: If a user wishes to update, correct or otherwise change Personal Information or objects to any
        usage provided herein for any reason, he/she may e-mail us at: clientcare@clubleisure.co.za
</p>
<p>
    <strong>Linking to a third party web
            site</strong>
</p>
<p>
    You should be aware that when you are on
        Residential Network Systems (PTY) Ltd Group websites you could hyperlink or be directed to other websites that
        are beyond our control and/or outside our service. For example, if you &quot;click&quot; on a banner
        advertisement or a Residential Network Systems (PTY) Ltd Group search result, the &quot;click&quot; may transfer
        you off the Residential Network Systems (PTY) Ltd Group website. Once you link to a third party web site and
        leave our website, your interaction with these third party web sites will no longer be governed by our Terms and
        Conditions or Privacy Policy. Accordingly, your use of these third party sites is at your own risk and is
        subject to the terms of use and policies set forth at such sites. Residential Network Systems (PTY) Ltd
        Group&apos;s inclusion of hyperlinks to these third party web sites do not imply any endorsement of, or
        responsibility or liability for, the material on such web sites or any association with their owners or
        operators.
</p>
<p>
    <strong>Securing your Personal
            Information</strong>
</p>
<p>
    The security of all information
        associated with our users is an important concern to us. We exercise reasonable care in providing secure
        transmission of your information from your computer to our servers. Unfortunately, given that we do not control
        transmissions or traffic over the Internet or through third party communication service providers, we cannot
        guarantee or warrant the security of such transmission of your Personal Information. Your use of the Internet
        and our website, including your transmission of Information to us, is at your own risk.
</p>
<p>
    <strong>Your acceptance of these
            terms</strong>
</p>
<p>
    By using Our websites, you signify your
        agreement with Residential Network Systems (PTY) Ltd Group&apos;s Privacy Policy. IF YOU DO NOT AGREE WITH THIS
        POLICY, PLEASE DO NOT USE OUR WEB SITES. Residential Network Systems (PTY) Ltd Group reserves the right, in its
        sole discretion, to modify, alter or otherwise update this Privacy Policy at any time without prior notice. Such
        revised policy shall be effective immediately upon posting, and shall govern your rights and our obligations
        with respect to the use, disclosure and protection of your Personal Information. You should check this Privacy
        Policy periodically for, and read carefully, any such revised policy if you are concerned about how your
        Personal Information will be used and before continuing your use of this website. Your continued use of
        Residential Network Systems (PTY) Ltd Group website following the posting of changes to these terms will mean
        you accept those changes.
</p>
<p>
<h1>POPI POLICY</h1>
</p>
<p>
<h2>Introduction</h2>
</p>
<p>
    The Protection of Personal Information
        Act, (POPIA), has been circulated to regulate, together with international standards, the processing of personal
        information by public and private bodies in a responsible manner when collecting, processing, storing and
        sharing another entity&rsquo;s personal information by holding them accountable should they abuse or compromise
        personal information in any way.
</p>
<p>
    <strong>Purpose</strong>
</p>
<p>
    RNS (PTY) Ltd Group is very committed to
        compliance and adherence to the POPI Act and safeguarding of the personal information that we are responsible
        for. This policy describes the manner in which the RNS (PTY) Ltd Group, will fulfil the legal requirements with
        the Protection of Personal Information Act, No 4 of 2013 and how and why we collect, store, use, and share or
        otherwise process your personal information. It also explains your rights in relation to your personal
        information and how to contact us if you have a question or complaint.
</p>
<p>
    <strong>Examples of Personal Identifiable
            Information</strong>
</p>
<p>
    Definition of Personal Information as
        stated in the POPI Act:<br/> &ldquo;personal information means information relating to an identifiable,
        living, natural person, and where it is applicable, an identifiable, existing juristic person, including, but
        not limited to:
</p>
<ol>
    <li>
        information relating to the race,
            gender, sex, pregnancy, marital status, national, ethnic or social origin, colour, sexual orientation, age,
            physical or mental health, well-being, disability, religion, conscience, belief, culture, language and birth
            of the person;
    </li>
    <li>
        information relating to the education
            or the medical, financial, criminal or employment history of the person;
    </li>
    <li>
        any identifying number, symbol,
            e-mail address, physical address, telephone number, location information, online identifier or other
            particular assignment to the person;
    </li>
    <li>
        the biometric information of the
            person;
    </li>
    <li>
        the personal opinions, views or
            preferences of the person;
    </li>
    <li>
        correspondence sent by the person
            that is implicitly or explicitly of a private or confidential nature or further correspondence that would
            reveal the contents of the original correspondence;
    </li>
    <li>
        the views or opinions of another
            individual about the person; and
    </li>
    <li>
        the name of the person if it appears
            with other personal information relating to the person or if the disclosure of the name itself would reveal
            information about the person;&rdquo;
    </li>
</ol>
<p>
    Further to this, Section 26 defines
        Special Personal Information as:<br/> 1. Religious or philosophical beliefs; <br/> 2. race or ethnic
        origin; <br/> 3. trade union membership; <br/> 4. political persuasion;<br/> 5. Criminal and
        disciplinary proceedings that the individual may be involved in; <br/> 6. health or sex life; and /or
        <br/> 7. Biometric information. 
</p>
<p>
    <strong> Key
            Definitions</strong>
</p>
<p>
    <em>POPIA -</em> means the Protection of Personal
        Information Act 4 of 2013, as amended from time to time<br/> <em>Data subject -</em> a person to whom personal
        information relates.<br/> <em>Direct marketing</em> - sending a data subject an electronic communication about
        goods and services that you are promoting or offering to supply in the ordinary course of business,<br/>
        <em>Processing</em> - any operation or activity concerning personal information, whether or not by automatic
        means, concerning personal information, including:
</p>
<ul>
    <li>
        the collection, receipt, recording,
            organisation, collation, storage, updating or modification, retrieval, alteration, consultation or
            use;
    </li>
    <li>
        dissemination by means of
            transmission, distribution or making available in any other form; or 
    </li>
    <li>
        merging, linking, as well as
            restriction, degradation, erasure, or destruction of information.
    </li>
</ul>
<p>
    <em>Record </em>- any recorded information, regardless of
        when it came into existence:
</p>
<ul>
    <li>
        Writing on any material;
    </li>
    <li>
        Information produced, recorded or
            stored by means of any tape-recorder, computer equipment, whether hardware or software or both, or other
            device, and any material subsequently derived from information so produced, recorded or stored;
    </li>
    <li>
        Label, marking or other writing that
            identifies or describes anything of which it forms part, or to which it is attached by any means;
    </li>
    <li>
        Book, map, plan, graph or
            drawing;
    </li>
    <li>
        Photograph, film, negative, tape or
            other device in which one or more visual images are embodied so as to be capable, with or without the aid of
            some other equipment, of being reproduced.
    </li>
</ul>
<p>
    <em>Responsible par</em>ty - a public or private body or any other
        person which determines the purpose of and means for processing personal information.<br/> <em>Operato</em>r - is
        someone who processes the Personal information on behalf of the responsible party
</p>
<p>
    <em>Consent</em> - any voluntary, specific and
        informed expression of will in terms of which permission is given for the processing of personal
        information.
</p>
<p>
    <strong>SCOPE OF THE
            POLICY</strong><br/> This policy applies to all RNS
        (PTY) Ltd Group employees, Directors, Contractors, sub-contractors, agents, guests and customers. The provisions
        of the Policy are applicable to both on and off-site processing of personal information.
</p>
<p>
    <strong>COLLECTION and PROCESSING OF
            PERSONAL INFORMATION</strong><br/> Personal Information must be
        collected directly from the Data Subject, unless:
</p>
<ul>
    <li>
        Personal Information is contained in
            a public record;
    </li>
    <li>
        Personal Information has been
            deliberately made public by the Data Subject;
    </li>
    <li>
        Personal Information is collected
            from another source with the Data Subject&rsquo;s consent;
    </li>
    <li>
        Collection of Personal Information
            from another source would not prejudice the Data Subject;
    </li>
    <li>
        Collection of Personal Information
            from another source is necessary to maintain, comply with or exercise any law or legal right;
    </li>
    <li>
        Collection from the Data Subject
            would prejudice the lawful purpose of collection;
    </li>
    <li>
        Collection from the Data Subject is
            not reasonably practicable.
    </li>
</ul>
<p>
    RNS (PTY) Ltd Group only collects
        information required for a specific purpose and applies the strictest security measures to protect your
        information. RNS (PTY) Ltd Group does not process the personal information of a child (under 18) unless the
        processing:
</p>
<ul>
    <li>
        Is carried out with the consent of
            the legal guardian
    </li>
    <li>
        Is necessary to establish, exercise
            or defence of a right or obligation in law
    </li>
    <li>
        Is necessary for historical,
            statistical or research purposes
    </li>
    <li>
        Is information that is deliberately
            been made public by the child with the consent of the guardian
    </li>
</ul>
<p>
    In accordance with section 10 of the POPI
        Act, RNS (PTY) Ltd Group will process personal information may only for the purpose for which it is processed,
        it is adequate, relevant and not excessive.<br/> Section 11 of the POPI Act states that personal information
        can only be processed under the following conditions:
</p>
<ul>
    <li>
        with the consent of the &ldquo;data
            subject&rdquo;; or
    </li>
    <li>
        if it is necessary for the conclusion
            or performance of a contract to which the &ldquo;data subject&rdquo; is a party; or
    </li>
    <li>
        it is required by law; or
    </li>
    <li>
        it protects a legitimate interest of
            the &ldquo;data subject&rdquo;; or
    </li>
    <li>
        it is necessary to pursue your
            legitimate interests or the interest of a third party to whom the information is supplied.
    </li>
</ul>
<p>
    <strong>USE OF PERSONAL
            INFORMATION</strong>
</p>
<ul>
    <li>
        Confirming and verifying an
            individual&rsquo;s identity
    </li>
    <li>
        Making a reservation or exchange with
            RNS (PTY) Ltd Group and/or its affiliates and associated companies
    </li>
    <li>
        Checking into RNS (PTY) Ltd Group and
            affiliate resorts and companies
    </li>
    <li>
        RNS (PTY) Ltd Group and affiliates
            and associated companies agreements including Shareblocks, Sectional Titles,
    </li>
    <li>
        For credit checks, assessment and
            credit management
    </li>
    <li>
        For purposes of claims history
    </li>
    <li>
        For the detection and prevention of
            fraud, crime, money laundering or other malpractice
    </li>
    <li>
        Conducting market or customer
            satisfaction research
    </li>
    <li>
        For audit and record keeping
            purposes
    </li>
    <li>
        In connection with legal
            proceedings
    </li>
    <li>
        Inform an individual of
            services.
    </li>
    <li>
        Staff administration, assessing,
            processing and entering into employee agreements.
    </li>
    <li>
        Training and Assessments
    </li>
    <li>
        Keeping of accounts and
            records
    </li>
    <li>
        Complying with legal and regulatory
            requirements
    </li>
    <li>
        Providing advertising, marketing and
            media services to clients including customer relationship management, content creation, web development,
            production services, animation, competitions, direct marketing, lead generation, digital media
    </li>
    <li>
        Covid results
    </li>
</ul>
<p>
    In the event that RNS (PTY) Ltd Group,
        its affiliates and associated companies, seeks to use the information for another purpose which is different to
        the purpose for which the information was collected initially, then RNS (PTY) Ltd Group, its affiliates and
        associated companies, will contact the data subject to obtain their consent for further processing.
</p>
<p>
    <strong>8 CONDITIONS OF LAWFUL PROCESSING
            - </strong>POPIA sets
        out eight conditions that businesses must comply with when processing the personal information of data subjects.
        These 8 conditions are the foundational principles of POPIA that, when complied with, ensure that a data
        subject&rsquo;s personal information is being processed lawfully.
</p>
<p>
    <strong>ACCOUNTABILITY</strong><br/> RNS (PTY) Ltd Group, its
        affiliates and associated companies are responsible for administering and overseeing the implementation of this
        Policy and any applicable supporting guidelines and procedures. This includes all and any information collected
        directly from a data subject and from any other source or authorised third parties.
</p>
<p>
    <strong>PROCESSING
            LIMITATION</strong><br/> Contact information is adequate,
        relevant and not excessive.<br/> RNS (PTY) Ltd Group, its affiliates and associated companies, may only
        process Personal Information if one of the following grounds of lawful processing exists:
</p>
<ul>
    <li>
        The Data Subject consents to the
            processing;
    </li>
    <li>
        Processing is necessary for the
            conclusion or performance of a contract with the Data Subject;
    </li>
    <li>
        Processing complies with a legal
            responsibility imposed on RNS (PTY) Ltd Group, its affiliates and companies;
    </li>
    <li>
        Processing protects a legitimate
            interest of the Data Subject;
    </li>
    <li>
        Processing is necessary for pursuance
            of a legitimate interest of RNS (PTY) Ltd Group, its affiliates and associated companies, or a third party
            to whom the information is supplied;
    </li>
</ul>
<p>
    Special Personal Information
        includes:
</p>
<ul>
    <li>
        Religious, philosophical, or
            political beliefs;
    </li>
    <li>
        Race or ethnic origin;
    </li>
    <li>
        Trade union membership;
    </li>
    <li>
        Health or sex life;
    </li>
    <li>
        Biometric information (including
            blood type, fingerprints, DNA, retinal scanning, voice recognition, photographs);
    </li>
    <li>
        Criminal behaviour;
    </li>
    <li>
        Information concerning a
            child.
    </li>
</ul>
<p>
    RNS (PTY) Ltd Group, its affiliates and
        associated companies may only process Special Personal Information under the following circumstances:
</p>
<ul>
    <li>
        The Data Subject has consented to
            such processing;
    </li>
    <li>
        The Special Personal Information was
            deliberately made public by the Data Subject;
    </li>
    <li>
        Processing is necessary for the
            establishment of a right or defence in law;
    </li>
    <li>
        Processing is for historical,
            statistical, or research reasons
    </li>
    <li>
        If processing of race or ethnic
            origin is in order to comply with affirmative action laws
    </li>
</ul>
<p>
    <strong>PURPOSE
            SPECIFICATION</strong><br/> RNS (PTY) Ltd Group, its
        affiliates and associated companies will process personal information only for specific, explicitly defined, and
        legitimate reasons. We ensure that information that is no longer needed is destroyed within requirements of
        SAICA document retention guidelines.<br/> RNS (PTY) Ltd Group, its affiliates and associated companies will
        inform data subjects of these reasons prior to collecting or recording the data subject&rsquo;s personal
        information. Refer to Use of Personal Information above.
</p>
<p>
    <strong>FURTHER PROCESS
            LIMITATION</strong><br/> New processing activity must be
        compatible with original purpose of processing. Where this secondary purpose is not compatible with the original
        purpose, RNS (PTY) Ltd Group, its affiliates and associated companies will first obtain additional consent from
        the data subject.
</p>
<p>
    <strong>INFORMATION
            QUALITY</strong><br/> RNS (PTY) Ltd Group, its
        affiliates and associated companies will take reasonable steps to ensure that all personal information collected
        is complete, accurate and not misleading.<br/> Where personal information is collected or received from
        third parties, RNS (PTY) Ltd Group, its affiliates and associated companies will take reasonable steps to
        confirm that the information is correct by verifying the accuracy of the information directly with the data
        subject or by way of independent sources. RNS (PTY) Ltd Group, its affiliates and associated companies
        periodically review and verify Data Subject records to ensure that the Personal Information is still valid and
        correct.
</p>
<p>
    <strong>OPENNESS</strong><br/> RNS (PTY) Ltd Group, its
        affiliates and associated companies values transparency and will only process information in a fair and
        transparent manner. We maintain the documentation of all processing operations under our responsibility and
        takes reasonably practical steps to ensure that the data subject is aware of, amongst others:
</p>
<ol>
    <li>
        the information being collected and
            the purpose for the collection;
    </li>
    <li>
        the identity of the responsible
            party; and
    </li>
    <li>
        any consequences of not supplying the
            information.
    </li>
</ol>
<p>
    RNS (PTY) Ltd Group, its affiliates and
        associated companies aims to have agreements in place with all contractors, suppliers and third party service
        providers to ensure a mutual understanding with regard to the protection of the client&rsquo;s personal
        information and compliance to POPIA.<br/> For the purpose of continuous improvement all contractors,
        suppliers, and other third-party service providers will be required to sign a service level agreement
        guaranteeing their commitment to the Protection of Personal Information<br/> Employee contracts have been
        updated to include relevant consent clauses for the use and storage of employee information. Addendums have been
        updated for existing employees.
</p>
<p>
    <strong>SECURITY
            SAFEGUARDS</strong><br/> Security controls have been
        implemented in order to minimise the risk of loss, unauthorised access, disclosure, interference, modification
        or destruction. We are committed to ensuring that information is only used for legitimate purposes with consent
        and only by authorised employees.<br/> RNS (PTY) Ltd Group, its affiliates and associated companies will
        ensure the integrity and confidentiality of all Personal Information in its possession, by taking reasonable
        steps to identify all reasonably foreseeable risks to information security and establish and maintain
        appropriate safeguards against such risks. We continuously monitor risks and threats and update procedures to
        mitigate existing and new threats.<br/> <strong>Written records</strong> - Personal Information records are
        stored in waterproof and fireproof safes or in locked cabinets under access control. When in use Personal
        Information records should not be left unattended in areas where non-staff members may access them. Personal
        Information which is no longer required is disposed of by shredding. Any loss or theft of, or unauthorised
        access to, Personal Information must be immediately reported to the Information Officer.<br/> <strong>Electronic
            Records</strong> - All electronically held Personal Information is saved in a secure database.<br/> As
        far possible we endeavour to limit saving of Personal Information on individual computers, laptops or hand-held
        devices. All computers, laptops and hand-held devices is access protected with a password, fingerprint or with
        the password being of reasonable complexity and changed frequently.<br/> Employees are required to lock
        their computers or laptops when leaving their workspace for any length of time and to log off at the end of the
        day.<br/> Electronical Personal Information which is no longer required must be deleted from the individual
        laptop or computer and the relevant database. The employee must ensure that the information has been completely
        deleted and is not recoverable.<br/> Any loss or theft of computers, laptops or other devices which may
        contain Personal Information must be immediately reported to their Line Manager, who shall notify the IT
        department, who shall take all necessary steps to remotely delete the information, if possible.
</p>
<p>
    <strong>DATA SUBJECT
            PARTICIPATION</strong><br/> The data subject is entitled
        to
</p>
<ul>
    <li>
        ask what personal information we hold
            about you;
    </li>
    <li>
        request access to the personal
            information that we hold about you;
    </li>
    <li>
        how that information was collected
            and to whom their personal information has been disclosed;
    </li>
    <li>
        ask us to update, correct or delete
            any out-of-date or incorrect personal information we hold about you;
    </li>
    <li>
        unsubscribe from any direct marketing
            communications we may send you; or
    </li>
    <li>
        object to the processing of your
            personal information.
    </li>
</ul>
<p>
    To do this, simply contact us at the
        numbers/addresses as provided below and specify what information you require. We will need a copy of your
        ID document to confirm your identity before providing details of your personal information.<br/> Please note
        that any such access request may be subject to a payment of a legally allowable fee. All such requests
        must be submitted in writing to the Information Officer at <a
            href="mailto:info@rnsonline.co.za">info@rnsonline.co.za</a>
</p>
<p>
    <strong>DISCLOSURE OF PERSONAL
            INFORMATION</strong><br/> We may disclose your personal
        information to our service providers who are involved in the delivery of products or services to you. We take
        reasonable steps to protect the confidentiality and security of your personal information when it is disclosed
        to a third party and seek to ensure the third-party deals with your information in accordance with our
        instructions, applicable privacy laws, and only for the purpose for which it is disclosed. We may also disclose
        your information where we have a duty or a right to disclose in terms of law and where we believe it is
        necessary to protect our rights.
</p>
<p>
    <strong>Transfer of Personal Information
            out of South Africa</strong><br/> The Act controls the transfer of
        personal information from South Africa to foreign countries and prohibits this unless: (section 71)
</p>
<ul>
    <li>
        the person receiving the information
            is subject to similar laws;
    </li>
    <li>
        the subject has agreed to the
            transfer of information;
    </li>
    <li>
        such transfer is part of the
            performance of a contract which the subject is a party; or
    </li>
    <li>
        transfer is for the benefit of the
            subject and it is not reasonably practicable to obtain their consent and that such consent would be likely
            to be given. (section 72)
    </li>
</ul>
<p>
    All employees have a duty of
        confidentiality in relation to RNS (PTY) Ltd Group, its affiliates and associated companies and
        clients.<br/> Employees and other persons acting on behalf of RNS (PTY) Ltd Group, its affiliates and
        associated companies and clients are required to treat personal information as a confidential business asset and
        to respect the privacy of data subjects.<br/> Our clients&rsquo; right to confidentiality is protected in
        the Constitution and in terms of the Law. Information may be given to a 3rd party if the client has consented in
        writing to that person receiving the information.<br/> RNS (PTY) Ltd Group, its affiliates and associated
        companies views any contravention of this policy very seriously and employees who are guilty of contravening the
        policy will be subject to disciplinary procedures, which may lead to the dismissal of any guilty party
</p>
<p>
    <strong>DIRECT
            MARKETING</strong><br/> All Direct Marketing
        communications shall contain the Group&rsquo;s, and/or the Company&rsquo;s details, and an address or method for
        the customer to opt-out of receiving further marketing communication.<br/> <strong>Existing Customers
            - </strong>Direct Marketing by electronic means to existing customers is only permitted:
</p>
<ul>
    <li>
        If the customer&rsquo;s details were
            obtained in the context of a sale or service; and
    </li>
    <li>
        For the purpose of marketing the same
            or similar products;
    </li>
</ul>
<p>
    The customer is given the opportunity to
        opt-out of receiving direct marketing on each occasion of direct marketing.<br/> <strong>Consent
            - </strong>RNS (PTY) Ltd Group, its affiliates and associated companies may send electronic Direct
        Marketing communication to Data Subjects who have consented to receiving it. RNS (PTY) Ltd Group, its affiliates
        and associated companies may approach a Data Subject for consent only once.
</p>
<p>
    <strong>RETENTION AND RESTRICTION OF
            RECORDS</strong><br/> POPI requires that records of
        personal information must not be kept any longer than is necessary for achieving the purpose for which the
        information was collected. There are some exceptions to this rule, where the information may be kept for
        longer:
</p>
<ul>
    <li>
        When required by law- Records may be
            retained for longer when the retention &ldquo;is required or authorised by law&rdquo; Section
            14(1)(a)
    </li>
    <li>
        Reasonably required- Records may be
            retained for longer when the organisation &ldquo;reasonably requires the record for lawful purposes related
            to its activities and functions&rdquo; Section 14(1)(b)
    </li>
    <li>
        Required by contract- As an example,
            your service contract with a customer might state that you are required to provide your customer with
            important safety updates regarding your product. In order to perform under the contract you would therefore
            need their contact information.
    </li>
    <li>
        Consent- Consent under POPI has to be
            specific, voluntary and informed.
    </li>
</ul>
<p>
    Section 14(2) &ndash; (7) have further
        exceptions relating to retention for research / statistical purposes, where the personal information was used in
        a decision about the data subject, restriction of records etc.
</p>
<p>
    <strong>POPI COMPLAINTS
            PROCEDURE</strong><br/> Data subjects have the right to
        complain in instances where any of their rights under POPIA have been infringed upon. The organisation takes all
        complaints very seriously and will address all POPI related complaints in accordance with the following
        procedure:<br/> POPI complaints must be submitted to RNS (PTY) Ltd Group, its affiliates and associated
        companies in writing. Where so required, the Information Officer will provide the data subject with a
        &ldquo;POPI Complaint Form&rdquo;.<br/> Where the complaint has been received by any person other than the
        Information Officer, that person will ensure that the full details of the complaint reach the Information
        Officer within 1 working day.<br/> The Information Officer will provide the complainant with a written
        acknowledgement of receipt of the complaint within 2 working days. (<strong>SLA)</strong><br/> The
        Information Officer will carefully consider the complaint and address the complainant&rsquo;s concerns in an
        amicable manner. In considering the complaint, the Information Officer will endeavour to resolve the complaint
        in a fair manner and in accordance with the principles outlined in POPIA.<br/> The Information Officer must
        also determine whether the complaint relates to an error or breach of confidentiality that has occurred and
        which may have a wider impact on RNS (PTY) Ltd Group, its affiliates and associated companies data
        subjects.<br/> Where the Information Officer has reason to believe that the personal information of data
        subjects has been accessed or acquired by an unauthorised person, the Information Officer will consult with the
        respective Board of Directors, where after the affected data subjects and the Information Regulator will be
        informed of this breach.<br/> The Information Officer will revert to the complainant with a proposed
        solution with the option of escalating the complaint to the organisation&rsquo;s governing body within 7 working
        days of receipt of the complaint. In all instances, RNS (PTY) Ltd Group, its affiliates and associated companies
        will provide reasons for any decisions taken and communicate any anticipated deviation from the specified
        timelines.<br/> The Information Officer&rsquo;s response to the data subject may comprise any of the
        following:<br/> A suggested remedy for the complaint,<br/> A dismissal of the complaint and the reasons
        as to why it was dismissed,<br/> An apology (if applicable) and any disciplinary action that has been taken
        against any employees involved.<br/> Where the data subject is not satisfied with the Information
        Officer&rsquo;s suggested remedies, the data subject has the right to complain to the Information
        Regulator.
</p>
<p>
    <strong>Information
            Regulator</strong><br/> Tel: 012 406 4818 or +27 (0) 10
        023 5207 <br/> Email: <a
            href="mailto:inforeg@justice.gov.za">inforeg@justice.gov.za</a>
</p>
<p>
    The Information Officer will review the
        complaints process to assess the effectiveness of the procedure on a periodic basis and to improve the procedure
        where necessary.
</p>
<p>
    <strong>GENERAL DESCRIPTION OF
            INFORMATION SECURITY MEASURES</strong><br/> RNS (PTY) Ltd Group, its
        affiliates and associated companies employs up to date technology to ensure the confidentiality, integrity and
        availability of the Personal Information under its care.<br/> Measures include:<br/> Active Directory,
        Firewalls and No-IP DDNS Firewalls<br/> Virus protection<br/> Software and update
        protocols<br/> Logical and physical access control<br/> Role based access control, network segmentation
        and physical access<br/> Secure setup of hardware and software making up the IT
        infrastructure<br/> Backups are maintained by the server administrators and internal server support. The
        servers are also covered by disaster recovery To Cloud<br/> Access control system for workstation access,
        client VPN access, internet access levels, Mimecast access, Office 365 access (Email, documents and Microsoft
        online services)<br/> All data transfers are encrypted at all points when data enters and leaves systems for
        processing.
</p>
<p>
    <strong>POLICY
            AMENDMENTS</strong><br/> Amendments to this Policy will
        take place on an ad hoc basis as and when required and will be updated on RNS (PTY) Ltd Group websites.
</p>
<p>
    <strong>CONTACT
            INFORMATION</strong><br/> Any questions relating to the
        POPI policy or the treatment of an individual&rsquo;s personal data may be addressed to the contact details
        below:<br/> Information officer: Mrs Maureen Stow<br/> Telephone number: 011 894
        6920<br/> Postal address: PO Box 1469, Benoni, 1500<br/> Physical address: 51 Lakefield Ave. Benoni
        1501<br/> Email address: <a
            href="mailto:info@rnsonline.co.za">info@rnsonline.co.za</a><br/> Website:
    <a href="http://www.rnsonline.co.za">www.rnsonline.co.za</a>
</p>
      </Typography>
    </Container>
  );
};
