import React, { useState } from "react";
import { ListingsByRef } from "../Components/ListingsByRef";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

export default function Hearts(props) {
  let loved = localStorage["love"].split(",").splice(1);
  let hearts = [];

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [state, setState] = useState({
    loved: localStorage["love"].split(",").splice(1).length,
    dataCount: 0,
    open: false,
  });

  const [selected, setSelected] = useState(false);

  loved.forEach((heart) => {
    hearts.push(heart);
  });
  let criteria = {
    hearts,
    action: "hearts",
  };

  const removeOnClick = (listingID) => {
    document.querySelector(`#${listingID}`).classList.add("slide-out-right");
    setTimeout(() => {
      document.querySelector(`#${listingID}`).remove();
      setState({
        ...state,
        loved: localStorage["love"].split(",").splice(1).length,
        dataCount: state.dataCount - 1,
      });
    }, 1000);
  };

  const s = (count) => {
    if (count > 1) return "s";
    return "";
  };
  const haveHas = (count) => {
    if (count > 1) return "have";
    return "has";
  };

  const openClearConfirmation = () => {
    setState({ ...state, open: true });
  };
  const closeClearConfirmation = () => {
    setState({ ...state, open: false });
  };

  const ReportCount = (dataCount = 0) => {
    setState({ ...state, dataCount });
  };

  const confirmClear = () => {
    hearts.forEach((heart) => {
      if (document.querySelector(`#${heart}`)) {
        document.querySelector(`#${heart}`).classList.add("slide-out-right");
      }
    });
    setTimeout(() => {
      localStorage["love"] = "";
      localStorage.removeItem("linkedHomes");
      localStorage.removeItem("linkedAgents");
      window.location.reload();
    }, 500);
  };
  return (
    <>
      {!state.loved || (
        <Typography variant="body1" color="initial" display={"block"}>
          {`You have found ${state.loved} home${s(
            state.loved
          )} on the RNS HomeFinder.`}
        </Typography>
      )}
      {state.dataCount === state.loved || (
        <Typography variant="body1" color="initial" display={"block"}>
          {`Since your last visit however, ${
            state.loved - state.dataCount
          } home${s(state.loved - state.dataCount)} ${haveHas(
            state.loved - state.dataCount
          )} become
                unavailable and are no longer being displayed.`}
        </Typography>
      )}
      {!state.loved || (
        <>
          <Button
            variant="outlined"
            color="secondary"
            onClick={openClearConfirmation}
          >
            CLEAR ALL
          </Button>
          <Dialog
            fullScreen={fullScreen}
            open={state.open}
            onClose={closeClearConfirmation}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Are you sure?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                This action will clear all the homes where your heart might be.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={closeClearConfirmation}
                color="primary"
              >
                No
              </Button>
              <Button onClick={confirmClear} color="primary" autoFocus>
                I'm sure
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {!state.loved && (
        <>
          <Typography variant="body1" color="initial">
            It's where the heart would be.
          </Typography>
          <Typography variant="body1" color="initial">
            <a href={`${process.env.PUBLIC_URL}/`}>Click here</a> to find more
            homes where your heart might be.
          </Typography>
        </>
      )}

      <ListingsByRef
        display={"loved"}
        criteria={criteria}
        makeLove={props.makeLove}
        removeOnClick={removeOnClick}
        reportCount={ReportCount}
        setSelected={setSelected}
        selected={selected}
      />
    </>
  );
}
