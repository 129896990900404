import React, { useState, useEffect } from "react";
import Postman from "axios";
import Gallery from "react-image-gallery";
// import { propTypes } from "react-addons-transition-group";
// import { PropTypes } from "react-addons-css-transition-group/node_modules/@types/react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  vtButtonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    height: 0,
  },
  vtButton: {
    position: "relative",
    zIndex: "1",
    textDecoration: "none",
    fontSize: "1.5rem",
    boxShadow: "-1px 3px 6px black",
    background: "darkturquoise",
    color: "white",
    textShadow: "2px 2px 4px black",
    padding: "3px 15px",
    borderRadius: "0 0 0 10px",
    "&:hover": {
      backgroundColor: "#13375a",
      color: "#a1fbff",
    },
  },
}));

export default function MakeGallery(props) {
  const classes = useStyles();
  const [photos, setPhotos] = useState([]);
  useEffect(() => {
    Postman.post("https://www.rnslive.co.za/wsRNS.asmx/imageList", {
      listingID: {
        lid: props.REF,
      },
    }).then((images) => {
      let arr = [];
      images = JSON.parse(images.data.d);

      for (let img in images) {
        arr.push(images[img]);
      }

      setPhotos(arr);
    });
  }, [props.REF]);

  const images = [];
  if (photos.length) {
    if (
      props.REF ===
      photos[0].substr(
        0,
        photos[0].split("").findIndex((c) => c === "_")
      )
    ) {
      return (
        photos.length
          ? photos.map((x) => {
              images.push({
                thumbnail: `https://www.rnslive.co.za/Photos/${props.REF}/${x}`,
                original: `https://www.rnslive.co.za/Photos/${
                  props.REF
                }/${x.replace(/_s/g, "_m")}`,
                fullscreen: `https://www.rnslive.co.za/Photos/${
                  props.REF
                }/${x.replace(/_s/g, "_l")}`,
              });
              return images;
            })
          : false,
        (
          <div>
            {props.LISTINGAGENT === props.MARKETINGAGNT ||
            !!!props.MARKETINGAGNT ? (
              props.VTOUR ? (
                <div className={classes.vtButtonContainer}>
                  <a
                    href={props.VTOUR}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.vtButton}
                  >
                    View Tour
                  </a>
                </div>
              ) : null
            ) : null}
            <Gallery items={images} thumbnailPosition="bottom" />
          </div>
        )
      );
    }
    return null;
  }
  return null;
}
