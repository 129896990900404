import React, { useEffect, useState } from "react";
import Postman from "axios";
import Hero from "./Hero";
import { ip } from "../bin/dev";

function CallHeroes(props) {
  const [listings, loadListings] = useState();
  useEffect(() => {
    Postman.post(
      `${ip}/listings?q=${props.display}`,
      props.search?.criteria?.town ? { town: props.search?.criteria?.town } : {}
    ).then((result) => {
      loadListings({
        data: result.data,
      });
    });
  }, [props.display, props.search.criteria.town]);

  const PAGE_DATA = () => {
    return listings;
  };

  const superHero = ()=>{
    return props.search.criteria.town.length > 0 ? "hero" : "super"
  }

  return [
    listings?.data
      ? listings.data.map((listing, i) => (
          <Hero
            key={listing.REF}
            id={listing.REF}
            display={superHero()}
            DATA={listing}
            PAGE_DATA={PAGE_DATA}
            hideThumb={!!props.hideThumb}
            FOUND={listings.data.length}
            showSimilar={true}
            makeLove={props.makeLove}
            selected={props.selected}
            setSelected={props.setSelected}
          />
        ))
      : null,
  ];
}

export const Heroes = React.memo(CallHeroes);
