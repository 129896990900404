import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Postman from "axios";
import { ip } from "../bin/dev";
import { Log } from "./Log";

const useStyles = makeStyles((theme) => ({
  formGrid: {
    textAlign: "center",
  },
  formButtons: {
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "80%",
    textAlign: "left",
  },
}));

const validationRules = {
  firstName: { isRequired: true },
  lastName: { isRequired: true },
  cellNumber: { isRequired: true, isNumber: true },
  emailAddress: { isRequired: true, isEmail: true },
  optionalMessage: { isRequired: false },
};

const fieldSetA = ["firstName", "lastName", "cellNumber"];
const fieldSetB = [
  "firstName",
  "lastName",
  "cellNumber",
  "emailAddress",
  "optionalMessage",
];

export default (props) => {
  const classes = useStyles();
  const [dialog, setDialog] = useState({
    firstName: localStorage.getItem("firstName")
      ? localStorage.getItem("firstName")
      : "",
    lastName: localStorage.getItem("lastName")
      ? localStorage.getItem("lastName")
      : "",
    cellNumber: localStorage.getItem("cellNumber")
      ? localStorage.getItem("cellNumber")
      : "",
    emailAddress: localStorage.getItem("emailAddress")
      ? localStorage.getItem("emailAddress")
      : "",
    loading: false,
    disabled: false,
    validation: {},
  });

  const validate = (fieldset) => {
    let validation = {};
    let isValid = true;

    fieldset.forEach((el) => {
      for (const rule in validationRules[el]) {
        if (rule === "isRequired") {
          if (validationRules[el]?.isRequired) {
            if (document.getElementById(el).value === "") {
              validation[el] = true;
              isValid = false;
            } else {
              validation[el] = false;
            }
          }
        }
        if (rule === "isNumber") {
          if (validationRules[el]?.isRequired) {
            if (validationRules[el]?.isNumber) {
              if (
                isNaN(parseInt(document.getElementById(el).value)) ||
                document.getElementById(el).value.length < 10
              ) {
                validation[el] = true;
                isValid = false;
              } else {
                validation[el] = false;
              }
            }
          } else {
            if (document.getElementById(el).value.length > 0) {
              if (validationRules[el]?.isNumber) {
                if (isNaN(parseInt(document.getElementById(el).value))) {
                  validation[el] = true;
                  isValid = false;
                } else {
                  validation[el] = false;
                }
              }
            }
          }
        }
        if (rule === "isEmail") {
          if (validationRules[el]?.isEmail) {
            let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            // if the email does not validate, set error to exist.
            if (!regex.test(document.getElementById(el).value)) {
              validation[el] = true;
              isValid = false;
            } else {
              validation[el] = false;
            }
          }
        }
      }
    });

    if (fieldset.length > 1) {
      for (const key in validation) {
        if (validation[key]) {
          isValid = false;
        }
      }
    }
    setDialog({
      ...dialog,
      validation: { ...dialog.validation, ...validation },
      isValid: isValid,
    });
    return isValid;
  };

  const handleInput = (event) => {
    let val = event.target?.value;
    if (event.target.id === "cellNumber") {
      let regex = /^\d+$/;
      if (!regex.test(val)) {
        val = val.substr(0, val.length - 1);
      }
      if (val.length > 11) {
        val = val.substr(0, 11);
      }
    }
    if (event.target.id !== "optionalMessage") {
      localStorage.setItem(event.target.id, val);
    }
    setDialog({
      ...dialog,
      [event.target.id]: val ? val : "",
    });
  };

  const startChat = () => {
    let greetAgent = props.agent.substr(0, props.agent.indexOf(" ", 0));
    let phone = props.phone; // "27832505353"; //props.phone;
    let from = `${document.getElementById("firstName").value} ${
      document.getElementById("lastName").value
    }`;
    let chatURL;
    if (props.socialShare) {
      let backLink = props.socialLink;
      // let backLink = props.altAgent
      // ? `${props.socialLink}/${props.altAgent}`
      // : props.socialLink;
      chatURL = `https://wa.me/${phone}?text=Hi%20${greetAgent},%0AI'm interested in this home I found on homefinder.co.za: ${props.socialShare}%0A${backLink}%0ARegards,%0A${from}`;
    } else
      chatURL = `https://wa.me/${phone}?text=Hi%20${greetAgent},%0AI found you on homefinder.co.za and would like to chat to you.`;
    if (props.mode === "chat") {
      setDialog({
        ...dialog,
        loading: true,
      });

      setTimeout(() => {
        setDialog({
          ...dialog,
          loading: false,
        });

        //if (!props.loveHandle(false)) props.loveHandle(true);
        props.handleClose();
        window.open(chatURL, "_blank");
      }, 1000);
    } else {
      setDialog({
        ...dialog,
        loading: true,
        disabled: true,
      });
      //props.handleClose();
      let emailAgentMessage = `
        <table width="640px" align="center">
        <tr
          height="150px"
          style="
            background-image: url('https://www.rnslive.co.za/Photos/${
              props.listingRef
            }/${props.listingImage}');
            background-position: center;
            background-size: cover;
          "
        >
          <td></td>
        </tr>
        <tr>
          <td
            style="
              padding: 10px;
              background-color: #ffffff;
              color: #555555;
              font-size: 14pt;
              font-family: sans-serif;
            "
          >
            <p>Hi ${greetAgent},</p>
            <p>
              You have a new lead from the 
              <a href="https://www.homefinder.co.za">RNS HomeFinder</a> portal.
            </p>
            <p>We are excited to share the following details with you:</p>
            <ul style="list-style: none;">
              <li>
                <strong>Client Name:</strong> ${dialog.firstName}
                ${dialog.lastName}
              </li>
              <li><strong>Phone#</strong> ${dialog.cellNumber}</li>
              <li>
                <strong>Email:</strong>
                <a href="mailto:${dialog.emailAddress}">${
        dialog.emailAddress
      }</a>
              </li>
              ${
                dialog.optionalMessage
                  ? `
              <li><strong>Message:</strong> ${dialog.optionalMessage}</li>
              `
                  : ""
              }
              <li><strong>Listing:</strong> ${props.socialShare}</li>
              <li><strong>Link:</strong> ${props.socialLink}</li>
            </ul>
            <p>
              Please do not respond to this automatically generated email.
            </p>
            <p>Thank you for your continued support!</p>
            <p>Kind Regards,</p>
            <p>RNS Home Finder</p>
          </td>
        </tr>
        <tr
          height="150px"
          style="
            background-image: url('https://www.homefinder.co.za/app/assets/email-footer-01.jpg');
            background-position: right;
            background-size: cover;
          "
        >
          <td></td>
        </tr>
      </table>
      `;
      let emailClientMessage = `
        <table width="640px" align="center">
        <tr
          height="150px"
          style="
            background-image: url('https://www.rnslive.co.za/Photos/${props.listingRef}/${props.listingImage}');
            background-position: center;
            background-size: cover;
          "
        >
          <td></td>
        </tr>
        <tr>
          <td
            style="
              padding: 10px;
              background-color: #ffffff;
              color: #555555;
              font-size: 14pt;
              font-family: sans-serif;
            "
          >
            <p>Hi ${dialog.firstName},</p>
            <p>An email was sent to the listing agent, ${props.agent}.</p>
            <p>
              Thank you for expressing your interest in this ${props.socialShare}, listed on the
              <a href="https://www.homefinder.co.za">homefinder.co.za</a> portal.
            </p>
            <p>Could this be be where the heart is...</p>
            <p><a href="${chatURL}">Click or tap to start</a> a WhatsApp chat with ${greetAgent} now.</p>
            <p>We include ${greetAgent}'s contact details here for your convenience.</p>
            <ul style="list-style: none;">
              <li>
                <strong>Company Name:</strong> ${props.agentData.AGENCY_NAME}
              </li>
              <li><strong>Company Phone#</strong> ${props.agentData.AGENCY_TELEPHONE}</li>
              <li><strong>Agent Name:</strong> ${props.agent}</li>
              <li><strong>Agent Cell#</strong> ${props.agentData.CELL_NUMBER}</li>
              <li><strong>Agent Email#</strong> <a href="mailto:${props.agentData.EMAIL_ADDRESS}">
              ${props.agentData.EMAIL_ADDRESS}</a>
              </li>
              <li><strong>Link:</strong> ${props.socialLink}</li>
            </ul>
            <p>
              Please do not respond to this automatically generated email.
            </p>
            <p>Happy house hunting!</p>
            <p>Kind Regards,</p>
            <p>The RNS Home Finder Team</p>
          </td>
        </tr>
        <tr
          height="150px"
          style="
            background-image: url('https://www.homefinder.co.za/app/assets/email-footer-01.jpg');
            background-position: right;
            background-size: cover;
          "
        >
          <td></td>
        </tr>
      </table>
      `;
      let postAgent = {
        emailTo: props.email,
        messageHTML: emailAgentMessage,
        subject: `New HomeFinder lead from ${dialog.firstName} ${dialog.lastName}`,
      };
      let postClient = {
        emailTo: dialog.emailAddress,
        messageHTML: emailClientMessage,
        subject: `Home Finder Confirmatation: REF# ${props.listingRef}`,
      };
      setTimeout(() => {
        Postman.post(`${ip}/mail`, postAgent).then((response) => {
          Postman.post(`${ip}/mail`, postClient).then((response) => {
            setDialog({
              ...dialog,
              loading: false,
              disabled: true,
              emailSent: "Email sent :)",
            });
          });
        });
        //if (!props.loveHandle(false)) props.loveHandle(true);
      }, 1000);
    }
  };

  function log() {
    return (
      <Log
        listing_id={props.listingRef}
        conversion_event={"contact"}
        conversion_meta={props.mode}
      />
    );
  }

  return (
    <Dialog
      open={props.socialOpen}
      onClose={props.handleClose}
      aria-labelledby=""
    >
      <DialogTitle id="start-chat">
        You are about to contact:
        <br />
        <strong>{props.agent}</strong>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Before we
          {props.mode === "chat"
            ? " start the chat on whatsapp"
            : " send the email"}
          , please supply us with some details about you.
        </DialogContentText>
        <form>
          <Grid container spacing={1} className={classes.formGrid}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  error={dialog.validation?.firstName}
                  id="firstName"
                  label="First Name"
                  value={dialog.firstName}
                  onChange={handleInput}
                  onBlur={() => {
                    validate(["firstName"]);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  error={dialog.validation?.lastName}
                  id="lastName"
                  label="Last Name"
                  value={dialog.lastName}
                  onChange={handleInput}
                  onBlur={() => {
                    validate(["lastName"]);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  error={dialog.validation?.cellNumber}
                  id="cellNumber"
                  label="Cell Number"
                  value={dialog.cellNumber}
                  onChange={handleInput}
                  onBlur={() => {
                    validate(["cellNumber"]);
                  }}
                />
              </FormControl>
            </Grid>
            {props.mode === "mail" ? (
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    error={dialog.validation?.emailAddress}
                    id="emailAddress"
                    label="Email Address"
                    value={dialog.emailAddress}
                    onChange={handleInput}
                    onBlur={() => {
                      validate(["emailAddress"]);
                    }}
                  />
                </FormControl>
              </Grid>
            ) : (
              false
            )}
            {props.mode === "mail" ? (
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField
                    error={dialog.validation?.optionalMessage}
                    id="optionalMessage"
                    label="Optional Message"
                    multiline
                    rowsMax={5}
                    value={dialog.optionalMessage}
                    onChange={handleInput}
                    onBlur={() => {
                      validate(["optionalMessage"]);
                    }}
                  />
                </FormControl>
              </Grid>
            ) : (
              false
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} className={classes.formGrid}>
          <Grid item xs={6}>
            <Button
              className={classes.formButtons}
              variant="contained"
              onClick={props.handleClose}
            >
              Close
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.formButtons}
              variant="contained"
              color="primary"
              onClick={
                props.mode === "chat"
                  ? () => {
                      validate(fieldSetA)
                        ? startChat()
                        : console.log("Message form incomplete");
                    }
                  : () => {
                      validate(fieldSetB)
                        ? startChat()
                        : console.log("Email form incomplete");
                    }
              }
              disabled={props.mode === "chat" ? false : dialog.disabled}
            >
              {!dialog.loading &&
                (props.mode === "chat"
                  ? "Start Chat"
                  : dialog.emailSent
                  ? dialog.emailSent
                  : "Send Email")}
              {dialog.loading && (
                <>
                  <CircularProgress color={"secondary"} size={24} />
                  {log()}
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
