import React from "react";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";

const getCurrentYear = () => {
  return format(new Date(), "yyyy");
};

export default () => {
  return (
    <>
      <hr />
      <Typography variant="body1" color="initial">
        RNS HomeFinder &copy; {getCurrentYear()}
      </Typography>
      <Typography variant="caption" color="initial">
      Made with &#10084;
      </Typography>
    </>
  );
};
