import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import MuiCardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import "../bin/common";
import Grid from "@material-ui/core/Grid";
import Icon from "./Icon";
import ListingDetails from "./Details";
import Slide from "@material-ui/core/Slide";
import Love from "./Love";
import { Calculate } from "../routes/calculate";
import AdvertisingToggle from "../UI/AdvertisingToggle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { Booking } from "../UI/Booking";
import { testSubject } from "../bin/dev";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Log } from "./Log";
import Conversions from "./Conversions";
import { red, orange } from "@material-ui/core/colors";

const _Listing = (props) => {
  //eslint-disable-next-line
  const scrollToListing = (listingContainer) => {
    if (listingContainer) {
      setTimeout(() => {
        document.getElementById("appHeader").style.visibility = "hidden";
        document.getElementById(listingContainer).scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        });
      }, 1000);
    }
  };

  const AccordionSummary = withStyles({
    root: { width: "100%" },
    content: {
      width: "100%",
      margin: 0,
      "&$expanded": {
        width: "100%",
        margin: 0, //"12px 0",
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const useStyles = makeStyles((theme) => ({
    flex: {
      //display: "flex",
      textAlign: "right",
      //marginTop: 20,
    },
    heroRoot: {
      display: "block",
      marginBottom: 15,
    },
    heroPrice: {
      fontSize: "large",
      color: "whitesmoke",
      fontWeight: "bold",
      textAlign: "end",
      padding: "5px 10px 5px 10px",
      borderRadius: "5px 0 0 5px",
      boxShadow: "3px 3px 5px black",
      position: "absolute",
      right: "0",
      top: "10px",
      zIndex: 1,
      backgroundColor: "crimson",
      textShadow: "3px 2px 4px black",
    },
    root: {
      width: "100%",
      margin: "0 auto",
      backgroundColor:
        props.expanded === "pnl-" + props.id ? "#14e3ed14" : "white",
    },
    content: {
      flex: "1 0 auto",
      textAlign: "left",
    },
    photo: {
      width: "20%",
      [theme.breakpoints.down("sm")]: {
        width: "30%",
      },
      height: "100%",
      display: "inline-block",
      float: "left",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      cursor: "pointer",
    },
    icon: {
      height: 24,
      color: "red",
    },
    qty: {
      backgroundColor: "black",
      color: "white",
      width: 16,
      height: 16,
      textAlign: "center",
      position: "relative",
      top: -7,
      left: -7,
      borderRadius: 8,
    },
    line: {
      border: "1px solid #09222c",
    },
    bottomLine: {
      border: "2px solid #09222c",
    },
    modal: {
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      // border: "2px solid #000",
      // boxShadow: theme.shadows[5],
      // padding: theme.spacing(2, 4, 3),
      // minHeight: "100vh",
      // maxHeight: "100vh",
      // overflow: "scroll",
    },
    large: {
      fontSize: "x-large",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    larger: {
      fontSize: "xx-large",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    closeIcon: {
      color: "grey",
      position: props.single ? "unset" : "absolute",
      right: props.single ? "unset" : 8,
      top: props.single ? "unset" : 4,
      zIndex: 9,
    },
    trimText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    ribbonSoldConditional: {
      backgroundColor: props?.DATA?.STATUS.includes("Final")
        ? red[900]
        : orange[900],
    },
  }));

  const isSelected = () => {
    if (props.DATA.REF === props.selected) return true;
    return false;
  };

  const styles = (theme) => ({
    root: {
      width: props.hideThumb ? "100%" : isSelected() ? "100%" : "70%",
      [theme.breakpoints.up("md")]: {
        width: props.hideThumb ? "100%" : isSelected() ? "100%" : "80%",
      },
    },
  });

  const CardActionArea = withStyles(styles)(MuiCardActionArea);

  const [state, setState] = useState({
    advertise: props.DATA.HF_ADVERTISE,
  });

  const flipToggle = (isChecked) => {
    setState({ advertise: isChecked.toString() });
  };

  const selectHandler = () => () => {
    if (isSelected()) props.setSelected(false);
    else {
      //console.log(props.DATA.REF);
      scrollToListing(props.DATA.REF);
      props.setSelected(props.DATA.REF);
    }
  };

  const buildImageURL = (sml) => {
    try {
      return `https://www.rnslive.co.za/Photos/${
        props.DATA.REF
      }/${props.DATA.PHOTOS[0].replace(/_s/g, `_${sml}`)}`;
    } catch (error) {
      let imgURL = `https://www.rnslive.co.za/Photos/${props.DATA.REF}/${props.DATA.REF}_${sml}(01).jpg`;
      return imgURL;
    }
  };

  const classes = useStyles();

  let agencyCode = sessionStorage["agencyCode"]
    ? sessionStorage["agencyCode"]
    : null;

  if (testSubject) {
    agencyCode = testSubject.aid;
  }

  if (props.DATA) {
    const iconList = [
      {
        qty: props.DATA.BEDROOMS,
        icon: "double-bed",
        alt: "Bedrooms",
        icononly: false,
      },
      {
        qty: props.DATA.I_ENSUIT,
        icon: "ensuite-solid",
        alt: "Ensuite bathrooms",
        icononly: false,
      },
      {
        qty: props.DATA.I_BATHS,
        icon: "bath-solid",
        alt: "Bathrooms",
        icononly: false,
      },
      {
        qty: props.DATA.I_LOUNNO,
        icon: "love-seat-solid",
        alt: "Lounge",
        icononly: false,
      },
      {
        qty: props.DATA.I_FARONO,
        icon: "family-room",
        alt: "Family room",
        icononly: false,
      },
      {
        qty: props.DATA.I_STDYNO,
        icon: "study-solid",
        alt: "Study",
        icononly: false,
      },
      {
        qty: props.DATA.I_LAUNNO,
        icon: "laundry-solid",
        alt: "Laundry",
        icononly: false,
      },
      {
        qty: props.DATA.I_SCULNO,
        icon: "scullery-solid",
        alt: "Scullery",
        icononly: false,
      },
      {
        qty: props.DATA.I_PANNO,
        icon: "pantry-solid",
        alt: "Pantry",
        icononly: false,
      },
      {
        qty: props.DATA.I_HLBTNO,
        icon: "restroom-solid",
        alt: "Guest loo",
        icononly: false,
      },
      {
        qty: props.DATA.E_SERVNO,
        icon: "staff-solid",
        alt: "Staff quarters",
        icononly: false,
      },
      {
        qty: props.DATA.E_GARANO,
        icon: "garage-open",
        alt: "Garage",
        icononly: false,
      },
      {
        qty: props.DATA.E_CRPRTN,
        icon: "carport-solid",
        alt: "Carport",
        icononly: false,
      },
      {
        qty: props.DATA.E_POOLNO,
        icon: "swimmer-solid",
        alt: "Pool",
        icononly: false,
      },
      {
        qty: props.DATA.G_TOTAL,
        icon: "granny-flat-solid",
        alt: "Granny flat",
        icononly: false,
      },
      {
        qty: props.DATA.T_PETS,
        icon: "paw-solid",
        alt: "Pet friendly",
        icononly: true,
      },
    ];

    function listingSummary() {
      return (
        <Card className={classes.root}>
          <>
            {window.location.pathname.indexOf("dash") > -1 || (
              <Log
                listing_id={props.id}
                conversion_event={!!isSelected() ? "View" : "Display"}
                conversion_meta={props.display}
              />
            )}
            {props.hideThumb || !!isSelected() || (
              <CardMedia
                className={classes.photo}
                //image={!!isSelected() ? buildImageURL("m") : buildImageURL("s")}
                image={buildImageURL("m")}
              />
            )}
            {!props.hideThumb && !!isSelected() && (
              <HighlightOffIcon className={classes.closeIcon} />
            )}
            {!!isSelected() || (
              <>
                <Love
                  display={props.display}
                  home={props.DATA.REF}
                  single={false}
                  makeLove={props.makeLove}
                  altAgent={props.altAgent}
                  LinkHomeToAgent={props.LinkHomeToAgent}
                  removeOnClick={
                    props?.removeOnClick ? props.removeOnClick : false
                  }
                  beat={props.hideThumb}
                />
                {props?.DATA?.STATUS.includes("Conditional") ? (
                  <div className="ribbon ribbon-top-right">
                    <span className={classes.ribbonSoldConditional}>
                      Conditional Sale
                    </span>
                  </div>
                ) : null}
                {props?.DATA?.STATUS.includes("Final") ? (
                  <div className="ribbon ribbon-top-right">
                    <span className={classes.ribbonSoldConditional}>Sold</span>
                  </div>
                ) : null}
              </>
            )}
          </>
          <CardActionArea>
            <CardContent className={classes.content}>
              <>
                {!!isSelected() || (
                  <>
                    {/* <Typography variant="body1" color="textSecondary">
                      REF: {props.DATA.REF}
                    </Typography> */}
                    {typeof props.isAvailable !== "undefined" &&
                      !props.isAvailable && (
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          onClick={(e) => {
                            e.stopPropagation();
                            window.location.reload();
                          }}
                        >
                          ARCHIVED: No longer advertised.
                        </Typography>
                      )}

                    {props.DATA.MANDATE === "RNS Mandate" && (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ color: "crimson" }}
                      >
                        Exclusive {props.DATA.MANDATE}
                      </Typography>
                    )}
                  </>
                )}
                {sessionStorage["agencyCode"] ? (
                  props.DATA.REF.substr(0, 4) === agencyCode ? (
                    props.DATA.FULL_ADDRESS ? (
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        className={classes.trimText + " blur"}
                      >
                        {props.DATA.FULL_ADDRESS.toProperCase()}
                      </Typography>
                    ) : (
                      false
                    )
                  ) : (
                    false
                  )
                ) : (
                  false
                )}
              </>
              {props.DATA.HF_TITLE && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={!!isSelected() ? classes.large : classes.trimText}
                  style={{ width: document.width }}
                >
                  {props.DATA.HF_TITLE}
                </Typography>
              )}
              <Typography
                variant="body2"
                color="textSecondary"
                className={!!isSelected() ? classes.large : classes.trimText}
                style={{ width: document.width }}
              >
                <strong>{props.DATA.TOWN.toProperCase()}</strong>
                &nbsp;| {props.DATA.SUBURB.toProperCase()}
              </Typography>
              <Typography
                component="h6"
                variant="h6"
                className={!!isSelected() ? classes.larger : ""}
              >
                {props.DATA.PRICE.toString().toZAR()}{" "}
                {props.DATA.MANDATE === "Rental Property" && " p/m"}
              </Typography>
              <>
                {!props.hideThumb || !!isSelected() || (
                  <>
                    {props.DATA.MANDATE !== "Rental Property" && !isSelected() && (
                      <Typography component="h6" variant="subtitle1">
                        <Calculate
                          autoPilot={true}
                          purchasePrice={props.DATA.PRICE}
                          loanAmount={props.DATA.PRICE}
                        />
                      </Typography>
                    )}
                  </>
                )}
                {!!isSelected() || (
                  <>
                    <Typography variant="subtitle2" color="textPrimary">
                      {props.DATA.BEDROOMS > 0
                        ? `${props.DATA.STYLE} ${props.DATA.BEDROOMS} bedroom ${props.DATA.CLASS}`
                        : `${props.DATA.STYLE} ${props.DATA.CLASS}`}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                      {`Photos: ${props.DATA.PHOTO_COUNT}`}
                    </Typography>
                    <Grid container spacing={2}>
                      {iconList.map((icon, i) => {
                        return (
                          <Icon
                            key={i}
                            icon={icon.icon}
                            alt={icon.alt}
                            qty={icon.qty}
                            icononly={icon.icononly}
                          />
                        );
                      })}
                    </Grid>
                  </>
                )}
              </>
            </CardContent>
          </CardActionArea>
        </Card>
      );
    }
    function listingExpanded() {
      return (
        <Grid item xs={12}>
          {!!isSelected() ? (
            <div>
              <Slide in={!!isSelected()}>
                <div className={classes.paper}>
                  <ListingDetails
                    listing={props}
                    REF={props.DATA.REF}
                    key={props.DATA.REF}
                    aid={props.DATA.AGENTID}
                    image={`https://www.rnslive.co.za/img/Agent_Images/${props.AGENTID}_w200.jpg`}
                    listingImage={props.DATA?.PHOTOS[0]?.replace(/_s/g, "_m")}
                    agent={props.DATA.LISTINGAGENT}
                    altAgent={props.altAgent}
                    LinkHomeToAgent={props.LinkHomeToAgent}
                    agency={props.DATA.LISTINGAGENCY}
                    agencyCode={props.DATA.REF.substr(0, 4)}
                    town={props.DATA.TOWN}
                    class={props.DATA.CLASS}
                    display={props.display}
                    data={props.DATA}
                    makeLove={props.makeLove}
                    isSelected={isSelected}
                  />
                </div>
              </Slide>
            </div>
          ) : (
            false
          )}
        </Grid>
      );
    }
    return (
      <div
        id={props.DATA.REF}
        className={"lovable"}
        style={{
          width: "100%",
          marginBottom: 10,
          overflow: "hidden",
          //height: isSelected() ? window.innerHeight : "unset",
        }}
      >
        {window.location.pathname.indexOf("dash") > -1 &&
          !!!isSelected() &&
          !!!state.hideThumb && (
            <div className={classes.flex}>
              <Conversions
                className={classes.conversions}
                listing_id={props.DATA.REF}
                fullAddress={props.DATA.FULL_ADDRESS.toProperCase()}
              />
              <AdvertisingToggle
                flipToggle={flipToggle}
                advertise={state.advertise}
                listingID={props.DATA.REF}
              />
              {state.advertise === "true" ? (
                <Booking
                  fullAddress={props.DATA.FULL_ADDRESS.toProperCase()}
                  listingID={props.DATA.REF}
                  location={props.DATA.TOWN}
                  maxDate={props.DATA.MAXDATE}
                />
              ) : null}
            </div>
          )}
        <Accordion
          expanded={isSelected()}
          onChange={
            props.canClick
              ? selectHandler()
              : () => {
                  return false;
                }
          }
          className={classes.root}
        >
          <AccordionSummary
            style={{ padding: 0, zIndex: 1 }}
            aria-controls={"pnl-" + props.selected + "-content"}
            id={"pnl-" + props.selected + "-header"}
          >
            {listingSummary()}
          </AccordionSummary>
          <AccordionDetails
            style={{
              margin: 0,
              overflow: "auto",
              height: isSelected() ? window.innerHeight - 160 : "unset",
            }}
          >
            {listingExpanded()}
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
  return null;
};

export const Listing = _Listing;
