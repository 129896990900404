import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SortIcon from "@material-ui/icons/Sort";
import FilterListIcon from "@material-ui/icons/FilterList";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Postman from "axios";
import { ip } from "../bin/dev";


const useStyles = makeStyles((theme) => ({
  root: {
    //padding: "1rem",
    backgroundColor: theme.palette.secondary.main,
    padding: 8,
    //marginTop: "1rem",
    //marginBottom: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.grey[100],
    width: "100%",
    textAlign: "left",
    fontSize: "xx-large",
    [theme.breakpoints.down("sm")]: {
      fontSize: "large",
    },
  },
  popSuburbs: {
    maxHeight: "20rem",
    overflow: "auto",
    display: "flow-root",
  },
  sorted: {
    transform: "rotate(180deg)",
    color: "white",
  },
  txt: {
    width: "100%",
    textAlign: "center",
    fontSize: "xx-large",
    [theme.breakpoints.down("sm")]: {
      fontSize: "x-large",
      textAlign: "center",
    },
  },
  altTxt: {
    width: "100%",
    textAlign: "left",
    fontSize: "xx-large",
    [theme.breakpoints.down("sm")]: {
      fontSize: "large",
      marginLeft: "10px",
    },
  },
  icon: {
    color: "white",
    //marginRight: 10,
  },
  button: {
    color: "white",
    textAlign: "center",
    padding: "3px 5px",
  },
  buttonContainer: {
    textAlign: "right",
  },
}));

//export default (props) => {
function _Section(props) {
  const [pop, setPop] = useState({
    open: false,
    anchor: null,
    selectedSuburbs: [],
  });

  let criteria = props?.criteria;
  let datetime = props?.criteria?.datetime;
  useEffect(() => {
    if (criteria) {
      Postman.post(`${ip}/listings?q=search&p=suburb`, {
        criteria,
      }).then((response) => {
        setPop({
          ...pop,
          selectedSuburbs: [],
          suburbs: response.data,
        });
      });
    }
    // eslint-disable-next-line
  }, [datetime]);

  const handleFilterListClick = (event) => {
    setPop({ ...pop, open: !pop.open, anchor: event.currentTarget });
  };
  const handleMultiSelectSuburb = (event) => {
    let suburbList = pop.selectedSuburbs;
    let selectedIndex = suburbList.indexOf(event.target.name, 0);
    if (selectedIndex > -1) {
      suburbList.splice(selectedIndex, 1);
    } else {
      suburbList.push(event.target.name);
    }
    props.filterSuburbs(buildSearchUpdateQuery(pop.selectedSuburbs));
    setPop({
      ...pop,
      open: false, //Autoclose suburb picklist
    });
    //props.scrollToResults();
  };

  const buildSearchUpdateQuery = (selection) => {
    let Towns = [];
    selection.forEach((el) => {
      let Suburb = JSON.parse(el);
      Towns.push(Suburb);
    });
    //Group the suburbs by town
    let suburbsGrouped = Towns.reduce((x, y) => {
      let key = Object.keys(y)[0];
      let value = y[key];
      x[key] = [...(x[key] || []), ...value];
      return x;
    }, {});
    return suburbsGrouped;
  };

  const handleClickAway = () => {
    setPop({
      ...pop,
      open: false,
    });
  };

  const classes = useStyles();
  return (
    <Grid container>
      <Popper
        open={pop.open}
        anchorEl={pop.anchor}
        placement={"bottom-end"}
        transition
        style={{ zIndex: 1 }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper>
            <FormGroup className={classes.popSuburbs}>
              {pop.suburbs
                ? pop.suburbs.map((el, i) => {
                    return (
                      <MenuItem key={i}>
                        <FormControlLabel
                          key={i}
                          label={`${el.SUBURB} [${el.LISTINGS}]`}
                          control={
                            <Checkbox
                              name={`{"${el.TOWN}":["${el.SUBURB}"]}`}
                              checked={
                                pop.selectedSuburbs.indexOf(
                                  `{"${el.TOWN}":["${el.SUBURB}"]}`,
                                  0
                                ) > -1
                                  ? true
                                  : false
                              }
                              onChange={handleMultiSelectSuburb}
                              color="primary"
                            />
                          }
                        />
                      </MenuItem>
                    );
                  })
                : false}
            </FormGroup>
          </Paper>
        </ClickAwayListener>
      </Popper>
      <Paper id={props.id} className={classes.root + " section-bg"}>
        <Grid
          item
          xs={
            !(props.showActions && pop?.suburbs?.length > 1)
              ? 12
              : 6
          }
          md={!(props.showActions && pop?.suburbs?.length > 1) ? 12 : 8}
          lg={!(props.showActions && pop?.suburbs?.length > 1) ? 12 : 10}
        >
          <Typography
            variant="h1"
            className={
              props.sortPrice && pop?.suburbs?.length > 1
                ? classes.altTxt
                : classes.txt
            }
          >
            {props.heading}
          </Typography>
        </Grid>
        {(props.showActions && pop?.suburbs?.length > 1) && (
          <Grid item xs={6} md={4} lg={2} className={classes.buttonContainer}>
            {props.sortPrice ? (
              <Button
                className={classes.button + " bounce-in-bottom"}
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                startIcon={<FilterListIcon className={classes.icon} />}
                onClick={handleFilterListClick}
              >
                Suburb
              </Button>
            ) : (
              false
            )} {props.sortPrice &&
              (props.count === 1 || (
                <Button
                  className={classes.button + " bounce-in-bottom"}
                  size={"small"}
                  variant={"outlined"}
                  color={"secondary"}
                  endIcon={
                    <SortIcon
                      className={props.sorted ? classes.sorted : classes.icon}
                    />
                  }
                  onClick={props.sortPrice}
                  disabled={props.heading.includes("You", 0)}
                >
                  Sort
                </Button>
              ))}
          </Grid>
        )}

      </Paper>
    </Grid>
  );
}

export const Section = React.memo(_Section);
