import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import MuiCardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import "../bin/common";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import HeroDetails from "./HeroDetails";
import Modal from "@material-ui/core/Modal";
import Slide from "@material-ui/core/Slide";
import Backdrop from "@material-ui/core/Backdrop";
import {Log} from "./Log";

const CardActionArea = withStyles({
  root: { width: "100%" },
})(MuiCardActionArea);

export default (props) => {
  let heroes = props.FOUND;
  const useStyles = makeStyles((theme) => ({
    flex: {
      //display: "flex",
      textAlign: "right",
      marginTop: 20,
    },
    heroRoot: {
      display: "block",
      //marginBottom: 15,
    },
    heroPrice: {
      fontSize: "large",
      color: "whitesmoke",
      fontWeight: "bold",
      textAlign: "end",
      padding: "5px 10px 5px 10px",
      borderRadius: "5px 0 0 5px",
      boxShadow: "3px 3px 5px black",
      position: "absolute",
      right: "0",
      top: "10px",
      zIndex: 1,
      backgroundColor: "crimson",
      textShadow: "3px 2px 4px black",
    },
    root: {
      width: "100%",
      margin: "0 auto",
      backgroundColor:
        props.expanded === "pnl-" + props.id ? "#14e3ed14" : "white",
    },
    content: {
      flex: "1 0 auto",
      textAlign: "left",
    },
    photo: {
      width: "20%",
      height: "100%",
      display: "inline-block",
      float: "left",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      cursor: "pointer",
    },
    icon: {
      height: 24,
      color: "red",
    },
    qty: {
      backgroundColor: "black",
      color: "white",
      width: 16,
      height: 16,
      textAlign: "center",
      position: "relative",
      top: -7,
      left: -7,
      borderRadius: 8,
    },
    line: {
      border: "1px solid #09222c",
    },
    bottomLine: {
      border: "2px solid #09222c",
    },
    modal: {
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      // border: "2px solid #000",
      // boxShadow: theme.shadows[5],
      // padding: theme.spacing(2, 4, 3),
      // minHeight: "100vh",
      // maxHeight: "100vh",
      // overflow: "scroll",
    },
    trimText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      backgroundColor: "gainsboro",
    },
  }));

  const buildImageURL = (sml) => {
    try {
      return `https://www.rnslive.co.za/Photos/${
        props.DATA.REF
      }/${props.DATA.PHOTOS[0].replace(/_s/g, `_${sml}`)}`;
    } catch (error) {
      let imgURL = `https://www.rnslive.co.za/Photos/${props.DATA.REF}/${props.DATA.REF}_${sml}(01).jpg`;
      return imgURL;
    }
  };

  const isSelected = () => {
    return props.DATA.REF === props.selected;
  }

  const classes = useStyles();
  //const selection = createRef();

  if (props.DATA) {
    return (
      <>
        <Log
          listing_id={props.id}
          conversion_event={isSelected() ? "View" : "Display"}
          conversion_meta={props.display}
        />
        <Grid
          item
          xs={12}
          md={heroes === 2 ? 5 : heroes === 1 ? 10 : 3}
          className={"swing-in-top-fwd"}
        >
          <Paper>
            <Card
              className={classes.heroRoot}
              onClick={() => {
                props.setSelected(props.DATA.REF);
              }}
            >
              <CardActionArea>
                <div className={classes.heroPrice}>
                  {props.DATA.PRICE.toString().toZAR()}
                </div>
                <div className="heroMedia">
                  <CardMedia
                    className="zoom"
                    image={buildImageURL("l")}
                    //title={`${props.DATA.STYLE} ${props.DATA.BEDROOMS} bedroom ${props.DATA.CLASS}`}
                  />
                </div>
                <CardMedia
                  className="heroAgent"
                  image={`https://www.rnslive.co.za/img/Agent_Images/${props.DATA.AGENTID}_w200.jpg`}
                  title={props.DATA.LISTINGAGENT}
                />
                <CardContent className={classes.trimText}>
                  <Typography
                    style={{ marginTop: 10 }}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    align="right"
                    className="trimText"
                  >
                    {props.DATA.LISTINGAGENCY}
                  </Typography>
                  <Typography variant="h6" align="right" className="trimText">
                    {props.DATA.SUBURB.toProperCase()}{" "}
                    <strong>{props.DATA.TOWN.toProperCase()}</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className="trimText"
                    align="right"
                  >
                    {props.DATA.BEDROOMS > 0
                      ? `${props.DATA.STYLE} ${props.DATA.BEDROOMS} bedroom ${props.DATA.CLASS}`
                      : `${props.DATA.STYLE} ${props.DATA.CLASS}`}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Paper>
          {isSelected() ? (
            <>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={isSelected()}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Slide
                  in={isSelected()}
                  direction="up"
                  mountOnEnter
                  unmountOnExit
                >
                  <div className={classes.paper}>
                    <HeroDetails
                      REF={props.DATA.REF}
                      key={props.DATA.REF}
                      listingImage={buildImageURL("m")}
                      showSimilar={props.showSimilar}
                      data={props.DATA}
                      pageData={props.PAGE_DATA}
                      makeLove={props.makeLove}
                      selected={props.selected}
                      setSelected={props.setSelected}
                    />
                  </div>
                </Slide>
              </Modal>
            </>
          ) : (
            false
          )}
        </Grid>
      </>
    );
  }
  return null;
};
