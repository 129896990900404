import React, { useState, useEffect } from "react";
import { ip } from "../bin/dev";
import Postman from "axios";
import Icon from "./Icon";
import Modal from "../UI/Modal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

export default (props) => {
  const [state, setState] = useState({ count: 0, open: false });
  let listing_id = props.listing_id;
  let url = `${ip}/log?action=count`;
  let post = {
    action: "count",
    listing_id,
  };

  useEffect(() => {
    Postman.post(url, post).then((response) => {
      let data = response.data;
      setState({ count: data.CONVERSIONS });
    });
    // eslint-disable-next-line
  }, []);

  const ResolveEvent = (event) => {
    switch (event) {
      case "Display | search":
        return "Displays on search result pages";

      case "Display | new":
        return "Displays on the landing page while being featured";

      case "Display | super":
        return "Displays as a Super Hero on the home page";

      case "Display | hero":
        return "Displays as a Hero on the search page";

      case "Display | agent":
        return "Displays browsing agent listings";

      case "Display | hero-value":
        return "Displays on Hero / Super Hero details";

      case "View | search":
        return "Views from search result pages";

      case "View | new":
        return "Views from being featured on the home page";

      case "shared | whatsapp":
        return "Shares on WhatsApp";

      case "View | super":
        return "Views as a Super Hero on the home page";

      case "Display | loved":
        return "Displays on favourited listings page";

      case "contact | phone":
        return "Requests to reveal phone number";

      case "View | hero":
        return "Views as a Hero on the search page";

      case "loved | single":
        return "Favorited on detail pages";

      case "loved | search":
        return "Favourited on search results";

      case "contact | chat":
        return "Chats with listing / marketing agent";

      case "View | agent":
        return "Views from browsing agent listings";

      case "View | hero-value":
        return "Views from Hero / Super Hero details";

      case "View | loved":
        return "Views from favourited listing page";

      case "contact | mail":
        return "Emailed listing / marketing agent";

      case "loved | dash":
        return "Favourited on portal dashboard";

      case "loved | new":
        return "Favourited on home page";

      case "loved | hero-value":
        return "Favourited other listings Displays on Hero / Super Hero details";

      case "loved | ":
        return "Favourited";

      default:
        return "Other views & displays";
    }
  };

  const AgregateEvents = (array) => {
    let myArray = [
      { key: "Display", what: "Total impressions:", value: 0 },
      { key: "View", what: "Total views:", value: 0 },
      { key: "shared", what: "Shared on WhatsApp:", value: 0 },
      { key: "contact", what: "Agents Contacted:", value: 0 },
      { key: "loved", what: "Added to favourites:", value: 0 },
    ];

    myArray.forEach((thing) => {
      array.forEach((el) => {
        if (el.CONVERSION_EVENT === thing.key) thing.value += el.EVENTS;
      });
    });
    return myArray;
  };

  return (
    <>
      <Icon
        icon="chart-line-solid"
        qty={state.count}
        alt="View Conversions"
        display="conversion"
        addPad="right"
        fetchConversions={() => {
          Postman.post(`${ip}/stats`, { listing_id: props.listing_id }).then(
            (response) => {
              setState({ ...state, open: true, events: response.data });
            }
          );
        }}
      />
      <Modal
        title={"Conversion Summary"}
        open={typeof state.open === "undefined" ? false : state.open}
        handleDialogClose={() => {
          setState({ ...state, open: false });
        }}
      >
        <Typography variant="h4" color="initial" className="center">
          {props.fullAddress}
        </Typography>
        {state.events &&
          AgregateEvents(state.events).map((event, id) => {
            if (event.value > 0) {
              return (
                <Grid container spacing={3} key={id} style={{ minWidth: 600 }}>
                  <Grid item xs={6} style={{ textAlign:"right" }}>
                    <Typography variant="body1" color="initial">
                      {event.what}
                      {/* {event.EVENTS} */}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      style={{ fontWeight: 600 }}
                      color="initial"
                    >
                      {event.value}
                      {/* {ResolveEvent(
                        event.CONVERSION_EVENT + " | " + event.CONVERSION_META
                      )} */}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }
            return null;
          })}
      </Modal>
    </>
  );
};
