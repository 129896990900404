//const _ip = "http://localhost:3002"; //Local Only
//const _ip = "http://13.246.66.211:3002"
const _ip = "https://api.homefinder.co.za:3002"

const _devMode = {
  isDevMode: false, //Set only this value to true to enable dev testing
  devUserCode: 115251,
  devAgencyCode: "G081"
}

//Brakpan - Lani - Group 101
// eslint-disable-next-line
const _testSubject0 = {
  aid: "G012",
  uid: "120539",
};
//Kempton - Christo - Eshcol
// eslint-disable-next-line
const _testSubject1 = {
  aid: "E024",
  uid: "116267",
};
//Benoni - Steven - Monarch
// eslint-disable-next-line
const _testSubject2 = {
  aid: "M035",
  uid: "152216",
};
//Boksburg - Laureen - Cle Properties
// eslint-disable-next-line
const _testSubject3 = {
  aid: "A115",
  uid: "119055",
};
//Kempton - Henno - Grinmark
const _testSubject4 = {
  aid: "G081",
  uid: "115938",	
};

export const devMode = _devMode;
export const testSubject = _devMode.isDevMode ? _testSubject4 : _devMode.isDevMode;
export const ip = _ip;
