import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "left",
  },
}));

export default (params) => {
  const classes = useStyles();
  return (
    <Container maxWidth={"sm"} className={classes.container}>
      <Typography
        component="div"
        style={{ height: "100vh" }}
      >
        <h1>News & other stuff</h1>
        <h2>Coming soon</h2>
        <p>
          Apart from the main purpose of this app, being a finder for homes, additional features will be
          implemented to add even more value.
        </p>
        <p>
          These features will be carefully constructed to ensure an engaging
          user experience.
        </p>
        <p>
          Be sure to check back soon for exciting new features.
        </p>    
      </Typography>
    </Container>
  );
};
