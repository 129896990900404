import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Postman from "axios";
import qs from "qs";
import "../bin/common";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { Section } from "../UI/Section";
import { ip } from "../bin/dev";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    textAlign: "center",
  },
  autoPilot: {
    //fontSize: "0.7rem",
    //backgroundColor: theme.palette.secondary.alphalight,
    //color: theme.palette.secondary.dark,
    //padding: 5,
    //borderRadius: 5,
    //minHeight: 42,
  },
  smallPrint: {
    marginTop: 30,
    fontSize: "0.6rem",
    textAlign: "left",
  },
  Error: {
    backgroundColor: theme.palette.secondary.alphalight,
    fontSize: "0.7rem",
    padding: 10,
    borderRadius: 5,
  },
  container: {
    textAlign: "center",
    overflow: "hidden",
    //minHeight: "calc(100vh - 250px)",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    textAlign: "left",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  calcResults: {
    padding: 10,
  },
}));

function _Calculate(props) {
  let defaults = {
    purchasePrice: props.purchasePrice > 0 ? props.purchasePrice : "",
    loanAmount: props.loanAmount > 0 ? props.loanAmount : "",
    years: props.years ? props.years : 20,
    interest: props.interest ? props.interest : 0,
    voluntaryAmount: props.voluntaryAmount > 0 ? props.voluntaryAmount : "",
    natureOfStatus: props.natureOfStatus ? props.natureOfStatus : "Freehold",
    sellerRegisteredForVAT: false,
    statusOfPurchase: "Natural Person",
    expandUI: true,
    loading: false,
  };
  const validationDefaults = {
    purchasePrice: true,
    loanAmount: true,
    years: true,
    interest: true,
    voluntaryAmount: true,
    natureOfStatus: true,
    sellerRegisteredForVAT: true,
    statusOfPurchase: true,
  };

  const [calc, setCalc] = useState(defaults);
  useEffect(() => {
    Postman.get(ip + "/info?q=rate").then((res) => {
      let interest = res.data.interestRate;
      setCalc({ ...defaults, interest });
    });
  }, []);

  const [valid, setValid] = useState(validationDefaults);

  const handleChecked = (event) => {
    setCalc({ ...calc, [event.target.name]: event.target.checked });
  };

  const resetCalc = () => {
    Postman.get(ip + "/info?q=rate").then((res) => {
      let interest = res.data.interestRate;
      setCalc({ ...defaults, interest });
      setValid({ ...validationDefaults });
    });
  };

  const validate = (event) => {
    let x = { ...valid };
    x[event.target.name] = event.target.value > 0;
    setValid(x);
  };

  let tempValidation = {};
  const isValid = (fields) => {
    fields.map((item) => {
      if (calc[item] === "") {
        tempValidation[item] = calc[item];
      }
      return true;
    });
    let x = { ...valid, ...tempValidation };
    setValid(x);
    for (const k in tempValidation) {
      if (!calc[k] > 0) {
        //console.log("Form is not valid. Holding back...");
        return false;
      }
    }
    //console.log("Form is valid. About to submit.");
    return true;
  };

  const doCalc = () => {
    if (!props.autoPilot) {
      if (!isValid(["purchasePrice", "loanAmount", "years", "interest"])) {
        return false;
      }
    }
    setCalc({ ...calc, loading: true }); //Loading...
    let interest = 0;
    let data = {}
    Postman.get(ip + "/info?q=rate").then((res) => {
      interest = res.data.interestRate;
      data = { ...calc, transfer: true, interest };
      Postman({
        method: "post",
        url: ip + "/calculate",
        data: qs.stringify(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      }).then((res) => {
        setCalc({ ...calc, expandUI: false, loading: false, data: res.data, interest });
      });
    });
  };

  const handleChange = (event) => {
    let updateValue = { [event.target.name]: event.target.value };
    if (event.target.name === "purchasePrice") {
      updateValue = { ...updateValue, loanAmount: event.target.value };
      document.getElementById("loan-amount").value = event.target.value;
    }
    setCalc({ ...calc, ...updateValue });
  };

  const classes = useStyles();

  const ActivateAutoPilot = () => {
    useEffect(() => {
      doCalc();
    }, []);
  };

  const togglePanel = () => {
    setCalc({ ...calc, expandUI: !calc.expandUI });
  };

  if (props.autoPilot) {
    if (calc) {
      ActivateAutoPilot();
    }
    if (calc.data) {
      return (
        <Typography component="div" className={classes.autoPilot}>
          <div>
            <strong>Costs: </strong>
            {calc?.data?.totalCosts
              ? ` ${calc.data.totalCosts} (Registration & Transfer)`
              : `Calculation result unavailable.`}
          </div>
          <div>
            <strong>Bond repayment:</strong>
            {` ${calc.data.BaseRepayment} (p/m at ${calc.interest}%)`}
          </div>
        </Typography>
      );
    }
    return (
      <Typography component="div" className={classes.autoPilot}>
        <div>
          <strong>Costs: </strong>
          just a moment...
        </div>
        <div>
          <strong>Bond repayment:</strong>
          just a moment...
        </div>
      </Typography>
    ); // doCalc();
  }
  return (
    <>
      <Section id={"divAgents"} heading={"HOME FINANCE CALCULATOR"} />
      <Container maxWidth={"md"} className={classes.container}>
        <Typography component="div" style={{ textAlign: "left" }}>
          <Accordion expanded={calc.expandUI}>
            <AccordionSummary
              onClick={togglePanel}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">
                {calc.expandUI ? "Calculate" : "Recalculate"} Bond & Transfer
                Costs:
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div">
                <form className={classes.root} noValidate autoComplete="off">
                  <TextField
                    error={
                      !!!valid["purchasePrice"]
                        ? !!!valid["purchasePrice"]
                        : !!!valid["purchasePrice"]
                    }
                    onBlur={validate}
                    id="purchase-amount"
                    name="purchasePrice"
                    label="Purchase price"
                    type="number"
                    value={calc.purchasePrice}
                    InputProps={{
                      inputProps: { step: 50000, min: 100000, max: 100000000 },
                      startAdornment: (
                        <InputAdornment position="start">R</InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                  />
                  <TextField
                    error={
                      !!!valid["loanAmount"]
                        ? !!!valid["loanAmount"]
                        : !!!valid["loanAmount"]
                    }
                    onBlur={validate}
                    id="loan-amount"
                    name="loanAmount"
                    label="Loan Amount"
                    type="number"
                    value={calc.loanAmount}
                    InputProps={{
                      inputProps: { step: 50000, min: 100000, max: 100000000 },
                      startAdornment: (
                        <InputAdornment position="start">R</InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                  />
                  <TextField
                    error={
                      !!!valid["years"] ? !!!valid["years"] : !!!valid["years"]
                    }
                    onBlur={validate}
                    id="years-to-pay"
                    name="years"
                    label="Period"
                    type="number"
                    value={calc.years}
                    InputProps={{
                      inputProps: { step: 5, min: 1, max: 30 },
                      endAdornment: (
                        <InputAdornment position="end">Years</InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                  />
                  <TextField
                    error={
                      !!!valid["interest"]
                        ? !!!valid["interest"]
                        : !!!valid["interest"]
                    }
                    onBlur={validate}
                    id="interest"
                    name="interest"
                    label="Interest"
                    type="number"
                    value={calc.interest}
                    InputProps={{
                      inputProps: { step: 1, min: 1, max: 12 },
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                  />
                  <TextField
                    id="voluntary-amount"
                    name="voluntaryAmount"
                    label="Additional voluntary amount"
                    value={calc.voluntaryAmount}
                    type="number"
                    InputProps={{
                      inputProps: { step: 500, min: 500, max: 10000 },
                      startAdornment: (
                        <InputAdornment position="start">R</InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                  />
                  <FormControl className={classes.formControl}>
                    <InputLabel id="nature-of-status-label">
                      Nature of Status
                    </InputLabel>
                    <Select
                      labelId="nature-of-status-label"
                      id="nature-of-status"
                      name="natureOfStatus"
                      value={calc.natureOfStatus}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Freehold"}>Freehold</MenuItem>
                      <MenuItem value={"Sectional"}>Sectional</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <InputLabel id="status-of-purchase-label">
                      Status of Purchase
                    </InputLabel>
                    <Select
                      labelId="status-of-purchase-label"
                      id="status-of-purchase"
                      name="statusOfPurchase"
                      value={calc.statusOfPurchase}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Natural Person"}>
                        Natural Person
                      </MenuItem>
                      <MenuItem value={"Company"}>Company</MenuItem>
                      <MenuItem value={"Closed Corporation"}>
                        Closed Corporation
                      </MenuItem>
                      <MenuItem value={"Trust"}>Trust</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={calc.sellerRegisteredForVAT}
                          onChange={handleChecked}
                          id="seller-vat-registered"
                          name="sellerRegisteredForVAT"
                        />
                      }
                      label="Seller VAT Registered"
                    />
                  </FormControl>
                  <hr style={{ border: "none" }} />
                  <Button variant="contained" onClick={resetCalc}>
                    Reset
                  </Button>
                  <Button variant="contained" color="primary" onClick={doCalc}>
                    {!calc.loading && "Calculate"}
                    {calc.loading && (
                      <CircularProgress color={"secondary"} size={24} />
                    )}
                  </Button>
                </form>
              </Typography>
            </AccordionDetails>
          </Accordion>
          {calc ? (
            calc.data ? (
              <Typography component="div" className={classes.calcResults}>
                <h2>Repayment Calculation:</h2>
                <hr />
                <div
                  dangerouslySetInnerHTML={{
                    __html: calc.data.Summary,
                  }}
                ></div>
                {calc.data.errorMessageList ? (
                  <Box className={classes.Error}>
                    We could not perform the bond registration & transfer fee
                    calculation for the purchase price specified. Anything less
                    than R 100 001 won't work.
                  </Box>
                ) : (
                  <div>
                    <h1>Total Cost: {calc.data.totalCosts}</h1>
                    <hr />
                    <h2>Bond Summary:</h2>
                    Bond costs consist of registration fees, a once off bank
                    initiation fee and other expenses which may include postage,
                    petties and FICA fees.
                    <br />
                    Registration: {calc.data.bondRegistrationCost} <br />
                    Initiation Fee: {calc.data.bondInitiationFee} <br />
                    Other: {calc.data.bondOtherCosts}
                    <h3>Total Bond Costs: {calc.data.totalBondCosts}</h3>
                    <hr />
                    <h2>Transfer Summary:</h2>
                    Transfer costs consist of an attorney conveyancing fee,
                    transfer duties and other expenses which may include
                    postage, petties and FICA fees.
                    <br />
                    Cost: {calc.data.transferCost} <br />
                    Duty: {calc.data.transferDuty} <br />
                    Other: {calc.data.transferOtherCosts}
                    <h3>
                      Total Transfer Costs: {calc.data.totalTransferCosts}
                    </h3>
                  </div>
                )}
              </Typography>
            ) : (
              false
            )
          ) : (
            false
          )}
        </Typography>
        <Typography
          variant="body2"
          color="initial"
          className={classes.smallPrint}
        >
          <strong>Small print</strong>
          <br />
          Great effort was made to ensure the accuracy of the HomeFinder cost
          and repayment calculator. Calculation results, however, are estimates
          only for indicative and educational purposes and may differ from what
          you might encounter in real life. RNS Home Finder and its subsidiaries
          may not be held liable for any loss or damages whatsoever arising from
          the use of this awesome calculator.
        </Typography>
      </Container>
    </>
  );
}
export const Calculate = React.memo(_Calculate);
