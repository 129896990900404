import React, { useState } from "react";
import { ListingsByRef } from "../Components/ListingsByRef";
import { AgencyDetails } from "../Components/Agency";
import { useParams } from "react-router-dom";

let _Agency = (props) => {
  let { agency } = useParams();
  const [counter, setCounter] = useState("...");
  const [selected, setSelected] = useState(false);

  const ReportCount = (listingCount = 0) => {
    setCounter(listingCount);
  };

  let criteria = {
    agencyCode: agency,
    action: "agency",
  };

  return (
    <>
      <AgencyDetails agencyCode={agency} counter={counter} />
      <ListingsByRef
        criteria={criteria}
        makeLove={props.makeLove}
        setSelected={setSelected}
        selected={selected}
        reportCount={ReportCount}
        display={"agency"} //Needed for logging events
      />
    </>
  );
};

export const Agency = _Agency;
