import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "./App.scss";
import * as serviceWorker from "./serviceWorker";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0e395d",
      light: "#409ba0",
    },
    secondary: {
      main: "#409ba0",
      dark: "#0e395d",
      light: "#14e3ed",
      alphalight: "#14e3ed36",
    },
    background: {
      default: "#0e395d",
    },
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <App />
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
