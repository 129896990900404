/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

String.prototype.toZAR = function () {
  return formatNumber(this, 0, " ", "", "R ", "", "", "");
};

String.prototype.toZARaddVAT = function () {
  return formatNumber(+this * 1.15, 0, " ", "", "R ", "", "", "");
};

String.prototype.strikeThrough = function () {
  return "<span style='text-decoration: line-through;'>" + this + "</span>";
}

String.prototype.wasNow = function(){
  let was = formatNumber(+this * 1.15, 0, " ", "", "R ", "", "", "");
  let now = formatNumber((+this /2) * 1.15, 0, " ", "", "R ", "", "", "");
  return "<span style='text-decoration: line-through; color: #F44336'>" + was + "</span> <span style='color: #4CAF50'>" + now + "</span>"
}

function formatNumber(num, dec, thou, pnt, curr1, curr2, n1, n2) {
  var x = Math.round(num * Math.pow(10, dec));
  if (x >= 0) n1 = n2 = "";
  var y = ("" + Math.abs(x)).split("");
  var z = y.length - dec;
  if (z < 0) z--;
  for (var i = z; i < 0; i++) y.unshift("0");
  if (z < 0) z = 1;
  y.splice(z, 0, pnt);
  if (y[0] === pnt) y.unshift("0");
  while (z > 3) {
    z -= 3;
    y.splice(z, 0, thou);
  }
  var r = curr1 + n1 + y.join("") + n2 + curr2;
  return r;
}

exports.common = {
  priceRange: (price)=>{
    let arrLookup = [
      { range: "Less than R500k", between: [0, 500000] },
      { range: "R500k - R750k", between: [500000, 750000] },
      { range: "R750k - R1m", between: [750000, 1000000] },
      { range: "R1m - R1.25m", between: [1000000, 1250000] },
      { range: "R1.25m - R1.5m", between: [1250000, 1500000] },
      { range: "R1.5m - R1.75m", between: [1500000, 1750000] },
      { range: "R1.75m - R2m", between: [1750000, 2000000] },
      { range: "R2m - R2.25m", between: [2000000, 2250000] },
      { range: "R2.25m - R2.5m", between: [2250000, 2500000] },
      { range: "R2.5m - R2.75m", between: [2500000, 2750000] },
      { range: "R2.75m - R3m", between: [2750000, 3000000] },
      { range: "Above R3m", between: [3000000, 9999999999] },
    ];
    for (const item of arrLookup) {
      if (price >= item.between[0] && price <= item.between[1]) {
        return item.range;
      }
    }
  }
}
